import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.scss']
})
export class ProspectComponent implements OnInit {
  innerHTMLContent = ` <jj-container controluiid="23104"> </jj-container>`;
  innerHTML = null;
  featureNotFound = null;
  featureId = 10;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer,
    private trackingService: TrackingService, 
    private packagefeatureService: PackagefeatureService   
  ) { }

  ngOnInit() {
    this.trackingService.trackPageView();
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);

    if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
      this.featureNotFound = true;
    } else {
      this.featureNotFound = false;
	}  
  }
}
