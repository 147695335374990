import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-projectpackages',
  templateUrl: './projectpackages.component.html',
  styleUrl: './projectpackages.component.scss'
})

export class ProjectpackagesComponent implements OnInit {
  packages = [];
  expandedItems = [];
  tab = 0;
  selectedPackage = null;
  count = 0

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public packagefeatureService: PackagefeatureService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService
  ) {}

  rows=[{id:1, name:'one'}, {id:2, name:'two'},{id:3, name:'three'}]

  buttons=[  {svg:'arrow-circle-left', label:'Something.Label.label', callback:this.exper1},
    {svg:'arrow-circle-right', label:'Something.Label.label', callback: this.exper2}]


  exper1(row:any){
       console.log('exper one', row)
     }

  exper2(row:any){
  console.log('exper two', row)
  }

  exper(){
    console.log('exper')
  }



  ngOnInit(): void {
    this.packages = JSON.parse(JSON.stringify(this.packagefeatureService.allPackages)).filter(item => item.settingJson.showInOverview == true);;
    console.warn(JSON.parse(JSON.stringify(this.packagefeatureService.allPackages)));
    this.packages.forEach(projectpackage => {
      if (projectpackage.id == this.applicationInfoService.projectInfo.packageId) {
        this.selectedPackage = projectpackage;
        this.tab = projectpackage.id;
      }
      projectpackage.featuresRelations.forEach(feature => {
        feature.defaultName = this.getFeatureName(feature.featureId);
      });
      projectpackage.featuresRelations.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
    });
    
  }






  selectPackageForProject(selectedPackage) {
    if (!this.applicationInfoService.isDeveloper) {
      return;
    }
    const everleadPackage = this.packages.find(everleadPackage => everleadPackage.id == selectedPackage);
    this.selectPackageToDisplay(everleadPackage);

    this.packagefeatureService.getProjectFeatures();
    let dataSourceParam = this.commonService.getModifyArrayBody({
      id: this.applicationInfoService.projectID,
      packageId: this.applicationInfoService.projectInfo.packageId
    }, []);

    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(260, [dataSourceParam])
    .then(()  => { 
      this.loaderService.display(false);
    })
    .catch(() => { 
      this.loaderService.display(false);
    });
  }

  getFeatureName(featureId) {
    const feature = this.packagefeatureService.getFeatureById(featureId);
    if (feature) {
      return feature.defaultName;
    } else {
      return '';
    }
  }

  packageExpanded(packageToCheck) {
    return this.commonService.checkIfItemIsInArray(this.expandedItems, packageToCheck.id);
  }

  toggleExpansion(packageToToggle) {
    if (this.commonService.checkIfItemIsInArray(this.expandedItems, packageToToggle.id)) {
      this.commonService.removeItemFromArray(this.expandedItems, packageToToggle.id);
    } else {
      this.expandedItems.push(packageToToggle.id);
    }
  }

  selectPackageToDisplay(everleadPackage) {
    this.tab = everleadPackage.id;
    this.selectedPackage = everleadPackage;
  }
}
