<div class="statusComponent" [ngClass]="{
    statusComponentIsOdd: isOdd,
    ellipsisText: ellipsis
}">
    <app-jj-icons type="circle-fill" [color]="getColor() ? getColor() : 'rgba(223, 223, 223, 1)'" 
        [width]=" width ? width : '8'" [height]="height ? height : '8'"></app-jj-icons>
    <span [tippy]="label | texttransform" *ngIf="label && !noLabelColor" [ngStyle]="{ 'color': getColor() ? getColor() : 'rgba(223, 223, 223, 1)' }">{{ label }}</span>
    <span [tippy]="label | texttransform" *ngIf="label && noLabelColor" class="noLabelColor ellipsisText">{{ label | texttransform }}</span>
    <span *ngIf="!label" [ngStyle]="{'color': getColor() ? getColor() : 'rgba(223, 223, 223, 1)' }">
        <ng-content></ng-content>
    </span>

</div>
