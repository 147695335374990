import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ConfirmationService } from 'primeng/api';
import { LoaderService } from 'app/shared/service/loader-service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService, JJEvent } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-echobot-interface',
  templateUrl: './echobot-interface.component.html',
  styleUrl: './echobot-interface.component.scss'
})

export class EchobotInterfaceComponent implements OnInit {
  @Input() resultEntity = 1;
  @Input() refId = null;
  @Input() isHosted = false;
  @Input() accountBrokerId = null;
  interfaceResults = null;
  loadingData = true;  
  previewDataArray = null;
  enrichmentPreviewValues = [];
  enrichmentPreviewTableValues = [];
  seletedInterfaceResultItem = null;

  accountDataExternalDatasourceId = null;
  previewExternalDatasourceId = null;
  enrichExternalDatasourceId = null;
  currentValues = null;
  interfaceResultItemIsFree = null;
  enrichmentData = null;
  responseData = null;
  enforceUpdate = false;
  userCreditsLeft = 0;
  hideContactsWithoutPhone = false;

  // contact
  apList = null;
  showAPList = true;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {    
    this.refId = this.applicationInfoService.miscSettings['enrichDataWithExternalInterfaceRefId']; 

    this.accountDataExternalDatasourceId = 979;
    this.previewExternalDatasourceId = 981;
    this.enrichExternalDatasourceId = 982;  

    if (this.applicationInfoService.applicationSettings['echobotDetailPreviewItemsEntity'.concat(this.resultEntity.toString())]) {
      this.enrichmentPreviewValues = this.applicationInfoService.applicationSettings['echobotDetailPreviewItemsEntity'
        .concat(this.resultEntity.toString())].split(';');
      this.enrichmentPreviewValues.forEach(item => {
        const itemDetails = item.split('-');
        itemDetails.push(true);
        this.enrichmentPreviewTableValues.push(itemDetails);
      });
    }  

    if (this.resultEntity == 1) {
      this.getAccountDataFromInterface();     
      this.getAlreadyRequestedAccountData();
    } else {
      
    }      

    if (this.isHosted) {      
      this.getAPList();
    }
  }

  getAccountDataFromInterface() {
    this.loadingData = true;
    this.interfaceResults = [];
    this.externaldatasourceService.executeExternalDataSource(this.accountDataExternalDatasourceId, [this.refId])
    .then(getDataFromInterfaceResult => {
      getDataFromInterfaceResult.sort((a, b) => a.matchScore > b.matchScore ? -1 : 1);
      this.interfaceResults = getDataFromInterfaceResult;
      this.loadingData = false;
    }).catch(() => { this.loadingData = false; });
  }

  enrichAccountData(interfaceResultItem) {
    this.loaderService.display(true);

    // echobotResult
    const echobotResultArray = this.previewDataArray;
    let echobotResultArrayJson = JSON.stringify(echobotResultArray);
    Object.keys(this.previewDataArray).forEach(keyValue =>  {      
      echobotResultArrayJson = echobotResultArrayJson.replace('"'.concat(keyValue, '"'), keyValue);
    });

    // fieldArray
    const fieldsArray = [];
    this.enrichmentPreviewTableValues.forEach(enrichmentPreviewTableValue => {
      if (enrichmentPreviewTableValue[3] == true) {
        fieldsArray.push({
          echobotField: enrichmentPreviewTableValue[0],
          accountField: enrichmentPreviewTableValue[1]
        });  
      }
    });
    let fieldsArrayJson = JSON.stringify(fieldsArray);
    Object.keys(fieldsArrayJson).forEach(() =>  {      
      fieldsArrayJson = fieldsArrayJson.replace('"'.concat('echobotField', '"'), 'echobotField');
      fieldsArrayJson = fieldsArrayJson.replace('"'.concat('accountField', '"'), 'accountField');
    });

    this.externaldatasourceService.executeExternalDataSource(this.enrichExternalDatasourceId, [
      this.refId, 
      echobotResultArrayJson,
      fieldsArrayJson
    ]).then(enrichAccountDataResult => {
        this.messagingService.showDefaultSuccess('', this.axivasTranslateService.getTranslationTextForToken('DataInterface.Label.EnrichmentSuccessfull'))
        this.eventService.customEvent.emit({ id: 'refreshQualificationOverviewpage' });
        this.loaderService.display(false);
        switch(this.resultEntity) {
          case 1:
            if (this.applicationInfoService.currentAccount) {
              if (this.applicationInfoService.currentAccount.id == this.refId) {
                this.enrichmentPreviewTableValues.forEach(valueItem => {
                  if (valueItem[3] == true) {
                    this.applicationInfoService.entities.Item(this.resultEntity.toString()).data[valueItem[1]] = 
                      this.previewDataArray[valueItem[0]];
                  }
                });
                this.eventService.updateEntityDataPropertyEvent.emit(new JJEvent(this.resultEntity.toString()));                
              }
            }
            break;
          case 4:
            if (this.applicationInfoService.currentContact) {
              if (this.applicationInfoService.currentContact.id == this.refId) {

              }
            }
            break;
        }
    }).catch(() => { this.loaderService.display(false); });
  }

  previewData(interfaceResultItem) {
    this.seletedInterfaceResultItem = interfaceResultItem;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(this.previewExternalDatasourceId, [interfaceResultItem.id, this.enforceUpdate])
    .then(previewDataResult => {
      this.previewDataArray = previewDataResult;
      this.loaderService.display(false);
      this.getCurrentValues();
      this.eventService.customEvent.emit({ id: 'refreshCredits' });
    }).catch(() => { this.loaderService.display(false); });
  }

  previewApData(interfaceResultItem) {
    this.seletedInterfaceResultItem = interfaceResultItem;
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(985, [interfaceResultItem.id, this.enforceUpdate])    
    .then(previewDataResult => {      
      this.previewDataArray = previewDataResult;
      this.loaderService.display(false);
      this.showAPList = false;
      this.eventService.customEvent.emit({ id: 'refreshCredits' });
      // this.getCurrentApValues();
    }).catch(() => { this.loaderService.display(false); });
  }

  enrichData(interfaceResultItem) {
    let confirmationHeader = this.axivasTranslateService.getTranslationTextForToken('DataInterface.EnrichPrompt.Header');
    let confirmationMessage = this.axivasTranslateService.getTranslationTextForToken('DataInterface.EnrichPrompt.Message');
    if (interfaceResultItem.hasValidEnrichmentData) {      
      confirmationMessage = this.axivasTranslateService.getTranslationTextForToken('DataInterface.EnrichPrompt.MessageFree');
    }
    confirmationMessage = confirmationMessage.replace('<0>', '1 Credit');

    this.confirmationService.confirm({
      message: confirmationMessage,
      header: confirmationHeader,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.previewData(interfaceResultItem);
      },
      reject: () => { }
    });
  }
  
  getCurrentValues() {
    let externalDatasourceId = 844;
    let values = '';        
    this.enrichmentPreviewTableValues.forEach(item => {
      values = values.concat(' ', item[1]);
    });    
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [this.refId,
      values
    ]).then(getCurrentValuesResult => {
      this.currentValues = getCurrentValuesResult;
      this.selectRelevantData();
    });
  }

  getCurrentApValues() {
    let externalDatasourceId = 483;
    let values = '';        
    this.enrichmentPreviewTableValues.forEach(item => {
      values = values.concat(' ', item[1]);
    });    
    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [this.refId,
      values
    ]).then(getCurrentValuesResult => {
      this.currentValues = getCurrentValuesResult;
      this.selectRelevantData();
    });
  }

  getAlreadyRequestedAccountData() {
    this.externaldatasourceService.executeExternalDataSource(983, [10004, this.refId, this.resultEntity])
    .then(getAlreadyRequestedAccountDataResult => { this.enrichmentData = getAlreadyRequestedAccountDataResult; });
  }

  selectAll() {
    this.enrichmentPreviewTableValues.forEach(item => {
      item[3] = true;
    });
  }

  removeAll() {
    this.enrichmentPreviewTableValues.forEach(item => {
      item[3] = false;
    });
  }

  selectRelevantData() {
    this.enrichmentPreviewTableValues.forEach(item => {
      item[3] = true;
      if (this.commonService.isNullOrUndefined(this.previewDataArray[item[0]])) {
        item[3] = false;
      }      
    });
  }

  getAPList() {
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(984, [this.accountBrokerId]).then(getAPListResult => {
      this.apList = getAPListResult;
      this.loadingData = false;
      this.showAPList = true;
    }).catch(() => { this.loadingData = false; });
  }

  enrichAP(interfaceResultItem) {
    if (interfaceResultItem.isInEverlead) {
      return;
    }
    let confirmationHeader = this.axivasTranslateService.getTranslationTextForToken('DataInterface.EnrichPrompt.Header');
    let confirmationMessage = this.axivasTranslateService.getTranslationTextForToken('DataInterface.EnrichApPrompt.Message');
    if (interfaceResultItem.hasValidEnrichmentData) {
      confirmationMessage = this.axivasTranslateService.getTranslationTextForToken('DataInterface.EnrichApPromptFree.Message');
    }
    confirmationMessage = confirmationMessage.replace('<0>', '1 Credit');
    this.confirmationService.confirm({
      message: confirmationMessage,
      header: confirmationHeader,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.previewApData(interfaceResultItem);
      },
      reject: () => { }
    });
  }

  createContactForAccount() {
    const echobotResultArray = this.previewDataArray;
    let echobotResultArrayJson = JSON.stringify(echobotResultArray);
    Object.keys(this.previewDataArray).forEach(keyValue =>  {      
      echobotResultArrayJson = echobotResultArrayJson.replace('"'.concat(keyValue, '"'), keyValue);
    });

    const fieldsArray = [];
    this.enrichmentPreviewTableValues.forEach(enrichmentPreviewTableValue => {
      if (enrichmentPreviewTableValue[3] == true) {
        fieldsArray.push({
          echobotField: enrichmentPreviewTableValue[0],
          contactField: enrichmentPreviewTableValue[1]
        });  
      }
    });
    let fieldsArrayJson = JSON.stringify(fieldsArray);
    Object.keys(fieldsArrayJson).forEach(() =>  {      
      fieldsArrayJson = fieldsArrayJson.replace('"'.concat('echobotField', '"'), 'echobotField');
      fieldsArrayJson = fieldsArrayJson.replace('"'.concat('contactField', '"'), 'contactField');
    });

    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(986, [
      this.applicationInfoService.currentAccount.id,
      echobotResultArrayJson,
      fieldsArrayJson
    ]).then(() => {
      this.getAPList();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  backToList() {
    this.getAPList();
    this.showAPList = true;
  }

  getItemTranslation(value, lookupTableDefinition) {    
    const foundItem = this.commonService.getProjectLookupTableValues(lookupTableDefinition, 'central')
      .find(departmentItem => departmentItem.defaultName == value);
    if (foundItem) {
      if (foundItem.nameTranslationToken) {
        if (foundItem.nameTranslationToken.id != 0) {
          return this.axivasTranslateService.getTranslationTextForObject(foundItem, 'nameTranslationToken');
        } else {
          return value;  
        }
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

  getUserCreditsLeft() {    
    this.externaldatasourceService.executeExternalDataSource(987, [10004, this.applicationInfoService.userID])
    .then(getUserCreditsLeftResult => {
      this.userCreditsLeft = getUserCreditsLeftResult;
    });
  }

  isItemFiltered(interfaceResultItem) {
    let returnValue = false;
    if (this.applicationInfoService.hideDataEnrichmentInterfaceContactsWithoutPhone && interfaceResultItem.hasTelephone == false) {
      returnValue = true;
    }
    return returnValue;
  }

  checkExistingContacts() {
    this.externaldatasourceService.executeExternalDataSource(988, [this.applicationInfoService.currentAccount.id])
    .then(checkExistingContactsResult => {
      console.warn(checkExistingContactsResult);
      this.getAPList();
    });
  }
}
