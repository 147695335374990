<div *ngIf="!applicationInfoService.isMobile()" [ngClass]="{ hideInDom: applicationInfoService.currentAccount == null}"></div>
<ng-container *ngIf="applicationInfoService.projectLoaded">
  <ng-container *ngIf="!applicationInfoService.currentTask && !applicationInfoService.currentAccount && !applicationInfoService.currentContact
    && applicationInfoService.designerMode == false">
    <div *ngIf="!accountInit" class="globalFontSize">
      <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
    <ng-container *ngIf="accountInit">
      <div class="noTaskComponent" *ngIf="applicationInfoService.currentProject?.isUsingWorkflow">
      <span *ngIf="applicationInfoService.currentProject?.isUsingWorkflow">{{ "Task.Label.NoTaskAvailable" |
        texttransform }}<br /></span>
      <br />
      <app-main-button *ngIf="applicationInfoService.currentProject?.isUsingWorkflow" svg="rocket"
        [label]="'Button.WelcomePage.StartWork' | texttransform" (onClick)="startWorking()"></app-main-button>    
    </div>
      <span *ngIf="!applicationInfoService.currentProject?.isUsingWorkflow">
        <div class="noContactSelectedComponent">
          <div class="row">
            <div [ngClass]="{
                     'col-sm-12 col-md-6 col-lg-6 col-xl-4': checkTaskMaxWidthWidth(),
                     'col-sm-12 col-md-6 col-lg-6': !checkTaskMaxWidthWidth()
                }">
              <button class="noContactSelectedComponent__container" (click)="navigateTo('accounts')">
                <div class="noContactSelectedComponent__container__inner">
                  <h1>{{ 'Account.Label.SelectAccount' | texttransform }}</h1>
                  <span>{{ 'Account.Label.SelectAccountDesc' | texttransform }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                    <path
                      d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z">
                    </path>
                  </svg>
                </div>
              </button>
            </div>
            <div [ngClass]="{
                  'col-sm-12 col-md-6 col-lg-6 col-xl-4': checkTaskMaxWidthWidth(),
                  'col-sm-12 col-md-6 col-lg-6': !checkTaskMaxWidthWidth()
                }">
              <button class="noContactSelectedComponent__container" (click)="navigateTo('contacts')">
                <div class="noContactSelectedComponent__container__inner">
                  <h1>{{ 'Contact.Label.SelectContact' | texttransform }}</h1>
                  <span>{{ 'Contact.Label.SelectContactDesc' | texttransform }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                    <path
                      d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z">
                    </path>
                  </svg>
                </div>
              </button>

            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4" *ngIf="checkTaskMaxWidthWidth()">
              <button class="noContactSelectedComponent__container">
                <div class="noContactSelectedComponent__container__inner">               
                </div>
              </button>
            </div>

          </div>
        </div>
      </span>
      <br />
    </ng-container>
  </ng-container>


  <ng-container *ngIf="applicationInfoService.currentAccount">

    <div [ngClass]="{
        designerMode: applicationInfoService.designerMode,
        hideInDom:
          !applicationInfoService.currentTask &&
          !applicationInfoService.currentAccount &&
          !applicationInfoService.currentContact &&
          !applicationInfoService.designerMode
      }" class="customAccount" (scroll)="onScroll($event)">
      <!-- <span id="accountMenuChecker" [class]="checkIfMenuIsVisible()"></span> -->
      <span id="customAccountWrapperSpan"></span>
      <div class="customAccount__wrapper" (scroll)="applicationInfoService.onWindowScroll($event, 'custom-account-overview')" [style.height]="getWrapperHeight('customAccountWrapperSpan')">
        <div class="customAccount__flipContainer" [ngClass]="{
          reverseContainer: applicationInfoService?.projectInfo?.isClassicView
        }">
          <div class="customAccount__container customAccount__partial">
            <app-layout [header]="'Contact.Label.Header' | texttransform" [locked]="applicationInfoService.accountLocked == true">
              <section class="customAccount__container__inner" [ngClass]="{
                customAccount__container__innerNoBorder: applicationInfoService.applicationSettings['newDesign2024']=='true'
              }">

                <div [innerHTML]="innerHTMLBonusPanel1"></div>

                <div *ngIf="!applicationInfoService.currentContact">
                  <div *ngIf="getAccountContacts(applicationInfoService.currentAccount.contacts).length > 0">

                    <div>{{ "Account.Label.NoContactSelected" | texttransform }}</div>

                    <!-- <div class="customAccount__container__header">
                      <h2>{{ "Account.Label.PleaseSelectContact" | texttransform }}</h2>
                    </div> -->

                    <div class="customAccount__container__content">
                      <div
                        *ngFor="let accountContact of getAccountContacts(applicationInfoService.currentAccount.contacts)"
                        class="contentList">
                        <div class="contentList__infoLineHead">
                          <label class="ellipsisText">{{getContactSalutation(accountContact.salutationId)}}
                            {{accountContact.firstName}} {{accountContact.lastName}}</label>
                          <div *ngIf="accountContact.position" class="contentList__infoLineHead__position ellipsisText">
                            {{accountContact.position}}</div>
                          <div *ngIf="accountContact.positionfreetext"
                            class="contentList__infoLineHead__position ellipsisText">{{accountContact.positionfreetext}}
                          </div>
                        </div>
                        <app-jj-icons type="arrow-square-out" [width]="25" [height]="25"
                          (click)="loadContact(accountContact)" [tippy]="'Contact.Label.LoadContact' | texttransform"
                          [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></app-jj-icons>
                        <!-- <button class="everleadMainButton" (click)="loadContact(accountContact)" [tippy]="'Contact.Label.LoadContact' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"><i class="fas fa-external-link-alt"></i></button> -->
                      </div>
                    </div>
                  </div>

                  <div *ngIf="getAccountContacts(applicationInfoService.currentAccount.contacts).length == 0"
                    class="accountForm__contact__info__nocontactavailable">
                    <div>{{ "Account.Label.NoContactAvailable" | texttransform }}</div>
                    <div class="accountForm__contact__info__createcontact">

                      <app-main-button svg="user-plus" type="contact" (onClick)="createContact()"
                        [label]="'Contact.Label.NewContact' | texttransform"></app-main-button>

                      <!-- <button class="everleadMainButton" (click)="createContact()">{{ 'Contact.Label.NewContact' | texttransform }}</button> -->
                    </div>
                  </div>
                </div>
                <div [innerHTML]="innerHTMLContact" *ngIf="applicationInfoService.currentContact"></div>
              </section>
            </app-layout>
          </div>


          <div class="customAccount__container customAccount__partial"
            *ngIf="(applicationInfoService.isAccountExpanded || applicationInfoService.currentContact == null || applicationInfoService.isMobile()) || applicationInfoService?.projectInfo?.isClassicView">
            <app-layout [header]="'Account.Label.Header' | texttransform" [locked]="applicationInfoService.accountLocked == true"
              [enrich]="applicationInfoService.applicationSettings['accountEnrichment']=='true'" [entityId]="1">
              <section class="customAccount__container__inner" [ngClass]="{
                customAccount__container__innerNoBorder: applicationInfoService.applicationSettings['newDesign2024']=='true'
              }">
                <div [innerHTML]="innerHTMLAccountBonusPanel1"></div>
                <div [innerHTML]="innerHTMLAccount"></div>
              </section>
            </app-layout>
          </div>
        </div>


        <div id="accountTaskSection" class="customAccount__container">
          <app-layout [header]="getAddtionalContainerName() | texttransform">
            <section class="customAccount__container__innerNoScroll">
              <!-- <div class="customAccount__container__tabs" *ngIf="additionalSectionNavigationItems.length > 1">
                <app-tab *ngFor="let additionalSectionNavigationItem of additionalSectionNavigationItems" 
                  svg="calendar-dots" 
                  [active]="tab == additionalSectionNavigationItem.id" 
                  [label]="additionalSectionNavigationItem.defaultName | texttransform" 
                  (onClick)="tab = additionalSectionNavigationItem.id">
                </app-tab>
              </div> -->

              <div *ngIf="tab === 2" class="customAccount__container__page">
                  <div class="accountQuestionnaire">
                    <app-questionaire-control></app-questionaire-control>
                  </div>               
              </div>

              <div *ngIf="tab == 1" class="customAccount__container__page">
                  <div class="maxWidth customAccount__container__innerExtra">
                    <app-custom-aipromptresult type="2"></app-custom-aipromptresult>
                  </div>                  
              </div>

              <div *ngIf="tab == 0 && !applicationInfoService.currentTask && applicationInfoService.currentProject?.isUsingWorkflow == true"
                class="accountFormMaxHeight">
                <div [innerHTML]="innerHTMLTaskNoActiveTask"></div>
              </div>

             <div class="accountFormMaxHeight" 
              *ngIf="tab == 0 && applicationInfoService.currentTask && applicationInfoService.currentProject?.isUsingWorkflow == true">
              <div class="maxWidth customAccount__container__innerExtra">
                <div [innerHTML]="innerHTMLTask" *ngIf="!applicationInfoService.accountPopupQMMode"></div>
                  <div *ngIf="applicationInfoService.accountPopupQMMode" class="accountFormMaxHeight">
                    <app-qualitymanagement></app-qualitymanagement>
                  </div>
                </div>                                        
              </div>
              
              <app-singleaction *ngIf="tab == 0 && applicationInfoService.currentProject?.isUsingWorkflow == false"></app-singleaction>
            </section>
          </app-layout>
        </div>

     <!--    <div class="customAccount__container" *ngIf="applicationInfoService.currentTask &&
                 applicationInfoService.currentProject?.isUsingWorkflow == true">
          <app-layout header="task">
            <section class="customAccount__container__inner">
              <div [innerHTML]="innerHTMLTask" *ngIf="!applicationInfoService.accountPopupQMMode"></div>
              <div *ngIf="applicationInfoService.accountPopupQMMode">
                <app-qualitymanagement></app-qualitymanagement>
              </div>
            </section>

          </app-layout>
        </div> -->

    <!--     <div class="customAccount__container">
          <app-layout header="task">
            <section class="customAccount__container__inner">
              <div [innerHTML]="innerHTMLTask" *ngIf="!applicationInfoService.accountPopupQMMode"></div>
              <div *ngIf="applicationInfoService.accountPopupQMMode">
                <app-qualitymanagement></app-qualitymanagement>
              </div>
            </section>
          </app-layout>
        </div> -->

      </div>
    </div>
  </ng-container>
</ng-container>