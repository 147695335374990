<div class="mytopcontactRow" [ngClass]="{
    mytopcontactRowOdd: isOdd
}">
    <div class="mytopcontactCol__one" *ngIf="parentHasAI">
        <app-blue-marker label="EntityTable.Label.AI" svg="thumbs-up" *ngIf="getAiStatus()"></app-blue-marker>
    </div>
    <div class="mytopcontactCol__two">
        <div class="mytopcontactCard">
            <div class="accountName ellipsis" *ngIf="contact.accountname">{{contact.accountname}}</div>
            <div class="contactName ellipsis" *ngIf="contact.firstname || contact.lastname">{{contact.firstname}} {{contact.lastname}}</div>
            <div class="contactAddress" *ngIf="contact.country || contact.email">
                <span class="ellipsis" *ngIf="contact?.country">{{ contact?.country}} | </span> 
                <span class="ellipsis" *ngIf="contact?.email">{{contact.email}}</span>
            </div>            
        </div>
    </div>
    <div class="mytopcontactCol__three">
        <div *ngIf="applicationInfoService.applicationSettings['isSelfServiceProject'] == 'true'">
            {{ commonService.getTranslationValueFromArray(getLeadState()) | texttransform }}
        </div>
        <app-bar-leadscore [value]="contact.leadscore" [label]="'Leadscore'" 
            *ngIf="applicationInfoService.applicationSettings['isSelfServiceProject'] != 'true'"></app-bar-leadscore>        
    </div>
</div>
