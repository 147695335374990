<div class="agEverleadBorder">
    <div class="agComponentHeader__wrapper">
        <app-component-header class="agComponentHeader__header"
            [header]="'Supervisor.Label.Questionaire' | texttransform"></app-component-header>
        <div class="agComponentHeader__menu" *ngIf="questionaireControlService.selectedQuestionaire == null
            && newQuestionaire == false">
            <div class="agComponentHeader__item">
                <input [(ngModel)]="showOnlyActive" type="checkbox">
                {{ 'CampaignDesign.Label.ShowOnlyActive' | texttransform }}            
            </div>
            <div class="agComponentHeader__item">
                <app-main-button (onClick)="prepareNewQuestionaire()" label="QuestionaireDesigner.Label.NewQuestionaire" 
                    type="small" ></app-main-button>
            </div>
        </div>
    </div>

    <div class="questionaireDesigner__wrapper agEverleadBorderInner"
        *ngIf="questionaireControlService.selectedQuestionaire == null && newQuestionaire == false">    
        <div class="questionaireDesigner__content">
            <div class="everleadTableRow questionaireDesignerListItem fingerCursor"
                *ngFor="let questionaire of questionaireControlService.projectQuestionaires"
                (click)="selectQuestionaire(questionaire)" [ngClass]="{
                hideInDom: questionaire.isActive == false && showOnlyActive == true
            }">
                <div class="questionaireDesignerListItem__level">
                    <app-jj-icons type="building" [tippy]="'Account.Label.Header' | texttransform" [width]="20" [height]="20" *ngIf="questionaire.entityId==1"></app-jj-icons>
                    <app-jj-icons type="calendar-dots" [tippy]="'Task.Label.Header' | texttransform" [width]="20" [height]="20" *ngIf="questionaire.entityId==2"></app-jj-icons>
                    <app-jj-icons type="user" [tippy]="'Contact.Label.Header' | texttransform" [width]="20" [height]="20" *ngIf="questionaire.entityId==4"></app-jj-icons>
                </div>
                <div class="questionaireDesignerListItem__defaultName">
                    {{ questionaire.defaultName }} 
                </div>               
                <div class="questionaireDesignerListItem__delete" (click)="$event.stopPropagation()">                    
                    <app-main-button (onClick)="toggleQuestionnaire(questionaire)" label="QuestionaireDesigner.Label.Reactivate" type="borderSmall" *ngIf="questionaire.isActive == false" ></app-main-button>    
                    <app-jj-icons type="pencil-simple-line" [tippy]="'QuestionaireDesigner.Label.EditQuestionnaire' | texttransform" [width]="20" [height]="20" *ngIf="questionaire.isActive == true"></app-jj-icons>
                    <app-jj-icons type="trash" [tippy]="'General.Label.DeleteItem' | texttransform" [width]="20" [height]="20" (click)="toggleQuestionnaire(questionaire)" *ngIf="questionaire.isActive == true"></app-jj-icons>                    
                </div>
            </div>
        </div>
    </div>





    <div class="questionaireDesigner__wrapper agEverleadBorderInner"
        *ngIf="questionaireControlService.selectedQuestionaire != null && newQuestionaire == false">
        <app-layout [header]="questionaireControlService.selectedQuestionaire.defaultName">
            <div class="questionaireDesignerBack">
                <app-main-button (onClick)="questionaireControlService.selectedQuestionaire = null" label="General.Label.Back" type="small" ></app-main-button>
            </div>
        <div class="questionaireDesigner__details">
            <div class="questionaireDesigner__settings">
                <app-text-input id="newQuestionnaireLabel" [(ngModel)]="questionaireControlService.selectedQuestionaire.defaultName"                    
                    (valueChange)="questionaireControlService.updateQuestionaire(questionaireControlService.selectedQuestionaire)"
                    label="QuestionaireDesigner.Label.DefaultName" class="maxWidth"></app-text-input>                                
                <app-main-button type="small" svg="plus" (click)="addQuestion()" [label]="'QuestionaireDesigner.Label.AddQuestion' | texttransform"></app-main-button>
                <app-main-button type="small" svg="plus" (click)="addFreeTextQuestion()" [label]="'QuestionaireDesigner.Label.AddFreeTextQuestion' | texttransform"></app-main-button>
            </div>           

            <div class="questionaireDetail__line__questions" cdkDropList
                [cdkDropListData]="questionaireControlService.selectedQuestionaire.questions"
                (cdkDropListDropped)="moveInList($event, '0')">
                <div *ngFor="let question of questionaireControlService.selectedQuestionaire.questions" cdkDrag [cdkDragData]="question" 
                    data-html="true" class="everleadTableRow" [ngClass]="{ hideInDom: question.isDeleted == true }">
                    <app-questionaire-designer-questioncontrol *ngIf="question.isDeleted==false" [question]=question
                        [parent]="questionaireControlService.selectedQuestionaire.questions" ></app-questionaire-designer-questioncontrol>
                </div>
            </div>
        </div>
        </app-layout>
    </div>

    <div *ngIf="newQuestionaire == true" class="newQuestionnaire">        
        <div class="newQuestionnaire__header">{{ 'QuestionaireDesigner.Label.NewQuestionaire' | texttransform }}</div>
        <div class="newQuestionnaire__description">{{ 'QuestionaireDesigner.Label.NewQuestionaireDesc' | texttransform }}</div>
        <app-text-input id="newQuestionnaireLabel" label="QuestionaireDesigner.Label.DefaultName" [(ngModel)]="newQuestionaireObject.defaultName"></app-text-input>
        <app-select-input label="QuestionaireDesigner.Label.Level" [(ngModel)]="newQuestionaireObject.entityId" [options]="questionaireTargets" 
            display="defaultName" bind="id"></app-select-input>
        <div class="newQuestionnaire__buttons">
            <app-main-button (onClick)="createNewQuestionaire()" label="QuestionaireDesigner.Label.Create" type="small" 
                [disabled]="newQuestionaireObject.entityId == null || newQuestionaireObject.defaultName == ''"></app-main-button>
            <app-main-button (onClick)="newQuestionaire = false" label="General._.Back" type="small" ></app-main-button>
        </div>
    </div>
</div>