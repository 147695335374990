<app-layout header="Dashboard.Item.Activity">
    <div class="projDashboardActivity">
        <div class="projDashboardActivity___header">          
            <div class="projDashboardActivity__selection">
               <app-select-input label="Task.Label.Campaign" [(ngModel)]="campaignId" bind="id" display="defaultName"
                    [options]="this.applicationInfoService.campaigns.toArray()"
                    (valueChange)="getDashboardDataArray(17)"></app-select-input>
            
                <app-select-input label="TopLevelHeader.Label.Pool" [(ngModel)]="poolId" bind="id" display="defaultName"
                    [options]="this.applicationInfoService.pools.toArray()"
                    (valueChange)="getDashboardDataArray(17)"></app-select-input>
            
                <app-select-input label="Task.Label.DataSource" [(ngModel)]="reportType"
                    [options]="reportTypeOptions" [complex]="true" display="option"
                    (valueChange)="getDashboardDataArray(17)"></app-select-input>
            
                <app-select-input [(ngModel)]="languageId" label="General._.Language" [options]=" languageOptions"
                    bind="id" display="label" (valueChange)="getDashboardDataArray(17)"></app-select-input>
            
                <!-- <app-select-input [(ngModel)]="projectId" label="DefaultActivityDashboard.Label.Projects"
                    (valueChange)="getDashboardDataArray(17)" [options]="projectOptions" display="projectName"
                    bind="id"></app-select-input> -->
            
                <app-checkbox [(ngModel)]="onlyLeads" label="DefaultActivityDashboard.Label.ShowOnlyLeads"
                    (valueChange)="getDashboardDataArray(17)"></app-checkbox>
            </div>
        </div>
        <div class="projDashboardActivity__errors" *ngIf="loading || nodata">
            <div *ngIf="loading">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>
                {{ 'General.Label.LoadingData' | texttransform }}
            </div>

            <div class="marginContainer noDataContainer" *ngIf="nodata">
                {{ 'General.Label.NoDataAvailable' | texttransform }}
            </div>
        </div> 


        <div class="projDashboardActivity__content" *ngIf="!loading && !nodata">
            <div class="projDashboardActivity__navigation">
                 <app-select-input label="Task.Label.Step" placeholder="ProjectOverview.Label.All"
                        [(ngModel)]="step" bind="id" display="step" [options]="stepsOptions"
                        (valueChange)="onSelectChange()"></app-select-input>
                <div class="projDashboardActivity__navigation__options">
                    <app-main-button svg="chart-bar" [type]=" viewChart ? 'small' : 'borderSmall'" label="Dashboard.Label.ViewChart" (onClick)="viewChart=true"></app-main-button>
                    <app-main-button svg="list" [type]="viewChart ? 'borderSmall': 'small'" label="Dashboard.Label.ViewList" (onClick)="viewChart = false"></app-main-button>
                </div>
            </div>

            <div class="projDashboardActivity__content__chart" *ngIf="viewChart">
               <app-bar-chart [height]="450" [controlid]="step === null ? 'allstepsActivities' : undefined" [chartData]="selectedData" component="dashboard_activity"></app-bar-chart>
            </div>

            <div class="projDashboardActivity__content__list" *ngIf="!viewChart">
               <app-dashboard-list-dropdown [controlid]="step === null ? 'nestedArray' : undefined" [data]="selectedData" component="activity"></app-dashboard-list-dropdown>
            </div>

        

        </div>
    </div>


</app-layout>