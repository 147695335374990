import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-mytopcontact',
  templateUrl: './mytopcontact.component.html',
  styleUrl: './mytopcontact.component.scss'
})
export class MytopcontactComponent implements OnInit{

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public commonService: CommonService
  ){}

  @Input() contact: any;
  @Input() parentHasAI: boolean = null;
  @Input() isOdd = false;

  ngOnInit(){
     /* console.log('contact', this.contact) */
  }

  getAiStatus(){
    if(this.contact.aiContact){
      return true
    }

    if(this.contact.aipotential){
      return true
    }

    if(this.contact.aiSuggestion){
      return true
    }
  }

  getLeadState() {
    const leadState = this.applicationInfoService.leadstates.toArray().find(leadstate => leadstate.lookupTable.id == this.contact.lead_state_id);
    if (leadState) {
      return leadState;
    } else {
      return ''; 
    }
  }
}
