import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from './externaldatasource.service';
import { EventService } from './event.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { InternalMethodType, MethodService } from './method.service';

@Injectable({
  providedIn: 'root'
})
export class PackagefeatureService {
  allPackages = [];
  allFeatures = [];
  projectPackageFeatures = []
  featureObject = new Object();

  constructor(
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public axivasTranslateService: AxivasTranslateService,
    private eventService: EventService,
    private domSanitizer: DomSanitizer
  ) {

  }

  initService(): Promise<any> {
    return new Promise((initServiceResolve, initServiceReject) => {
    const promiseArray: Promise<any>[] = [];
      promiseArray.push(this.getAllPackages());
      Promise.all(promiseArray)
        .then(() => {
          initServiceResolve(true);
          promiseArray.push(this.getAllFeatures());
         })
        .catch(() => { initServiceReject(null); });
    });
  }

  getAllPackages(): Promise<any> {
    return new Promise((getAllPackagesResolve, getAllPackagesReject) => {
      this.externaldatasourceService.executeExternalDataSource(919, [])
        .then((allPackages) => {          
          return Promise
            .all(allPackages.map((featuresPackage) => this.getAllFeaturesOfPackage(featuresPackage.id)))
            .then((allFeaturesOfPackage) => {
              allPackages.forEach((featuresPackage, index) => {
                featuresPackage.featuresRelations = allFeaturesOfPackage[index];

                if (this.commonService.isNullOrUndefined(featuresPackage.settings)) {
                  featuresPackage.settingJson = {};
                } else {
                  featuresPackage.settingJson = JSON.parse(featuresPackage.settings);
                }
              });
              this.allPackages = allPackages;
              getAllPackagesResolve(allPackages);
            });
        })
        .catch(error => {
          getAllPackagesReject(error);
        });
    });
  }

  getAllFeatures(): Promise<any> {
    return new Promise((getAllFeaturesResolve, getAllFeaturesReject) => {
      this.externaldatasourceService.executeExternalDataSource(907, []).then(getAllFeaturesResult => {

        getAllFeaturesResult.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
        getAllFeaturesResult.forEach(feature => {
          if (this.commonService.isNullOrUndefined(feature.settings)) {
            feature.settingJson = {};
          } else {
            feature.settingJson = JSON.parse(feature.settings);
          }
        });
        this.allFeatures = getAllFeaturesResult;
        this.getProjectFeatures();
        getAllFeaturesResolve(getAllFeaturesResult);
      }).catch(error => {
        getAllFeaturesReject(error);
      });
    });
  }

  getProjectFeatures() {
    this.projectPackageFeatures = [];    
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.projectInfo.packageId)) {
      const featurePackage = this.allPackages.find(pack => pack.id == this.applicationInfoService.projectInfo.packageId);
      if (featurePackage) {
        featurePackage.featuresRelations.forEach(feature => {
          this.projectPackageFeatures.push(feature.featureId);
        });
      }
    }
  }

  selectedPackageHasFeature(featureId) {
    if (this.applicationInfoService.applicationSettings['useFeaturesForExternalUsers'] == 'true') {
      if (this.applicationInfoService.user.isExternalUser == false) {
        return true;
      }
    }
    let returnValue = false;
    if (this.commonService.checkIfItemIsInArray(this.projectPackageFeatures, Number(featureId))) {
      returnValue = true;
    }
    return returnValue;
  }

  showFeatureInfoPage(featureId) {
    this.applicationInfoService.miscSettings['selectedPackageFeature'] = featureId;
    this.eventService.customEvent.emit({ id: 'refreshPackageFeatureInfo' });
    this.eventService.showJjPopup('EVERLEAD', 'features', '90');
  }

  showFeatureInfoPopup(featureId, slideId = null) {
    this.applicationInfoService.miscSettings['selectedPackageFeature'] = featureId;
    this.applicationInfoService.miscSettings['selectedPackageFeatureSlide'] = slideId;
    this.eventService.customEvent.emit({ id: 'refreshPackageFeatureInfo' });
    // todo ivo adjust height and width
    this.eventService.showJjPopup('EVERLEAD', 'features', '75');
  }

  navigationItemHasNonActiveFeature(menuItem) {
    let returnValue = false;
    const featureId = this.navigationItemFeatureId(menuItem);    
    if (featureId != null) {
      if (!this.selectedPackageHasFeature(featureId)) {
        returnValue = true;
      }
    }
    return returnValue;
  }

  navigationItemFeatureId(menuItem) {
    if (this.commonService.isNullOrUndefined(menuItem.target) || menuItem.target == '') {
      return null;
    }

    let potentialFeature = null;
    if (this.applicationInfoService.applicationSettings['useFeaturesForExternalUsers'] == 'true') {
      potentialFeature = this.allFeatures.find(feature =>
        this.checkFeatureRoute(feature, menuItem.target) &&
        feature.isActive == true &&
        this.applicationInfoService.user.isExternalUser == true
      );
    } else {
      potentialFeature = this.allFeatures.find(feature =>
        this.checkFeatureRoute(feature, menuItem.target) &&
        feature.isActive == true &&
        this.applicationInfoService.user.isExternalUser == false
      );
    }
    if (potentialFeature) {
      return potentialFeature.id;
    } else {
      return null;
    }
  }

  checkFeatureRoute(feature, routeString) {
    let returnValue = false;
    if (!this.commonService.isNullOrUndefined(feature.settingJson.route)) {
      const possibleRoutes = feature.settingJson.route.split(';');
      if (this.commonService.checkIfItemIsInArray(possibleRoutes, routeString)) {
        returnValue = true;
      }
    }    
    return returnValue;
  }

  getFeatureSlides(featureId): Promise<any> {
    return new Promise((getFeatureSlidesResolve, getFeatureSlidesReject) => {
      this.externaldatasourceService.executeExternalDataSource(902, [featureId])
      .then(getFeatureSlidesResult => {
        getFeatureSlidesResolve(getFeatureSlidesResult);
      }).catch(error => {
        getFeatureSlidesReject(error);
      });
    });
  }

  createFeatureSlide(featureId, slide): Promise<any> {
    //console.log('slide', slide)
    return new Promise((createFeatureSlideResolve, createFeatureSlideReject) => {
      this.externaldatasourceService.executeExternalDataSource(904, [
        featureId,
        slide.base64Img,
        //slide.projectId,
        JSON.stringify(JSON.stringify(slide.settings)) //double stringify to escape quotes
      ])
      .then(createFeatureSlideResult => {
        createFeatureSlideResolve(createFeatureSlideResult);
      }).catch(error => {
        createFeatureSlideReject(error);
      });
    });
  }

  updateFeatureSlide(featureId, slide): Promise<any> {
    //console.log('slide', slide)
    return new Promise((updateFeatureSlideResolve, updateFeatureSlideReject) => {
      this.externaldatasourceService.executeExternalDataSource(927, [
        slide.id,
        slide.base64Img,
        JSON.stringify(JSON.stringify(slide.settings)) , //double stringify to escape quotes
        slide.isActive,
        slide.order,
        slide.projectId,

      ])
      .then(updateFeatureSlideResult => {
        updateFeatureSlideResolve(updateFeatureSlideResult);
      }).catch(error => {
        updateFeatureSlideReject(error);
      });
    });
  }

  deleteFeatureSlide(slideId): Promise<any> {
    return new Promise((deleteFeatureSlideResolve, deleteFeatureSlideReject) => {
      this.externaldatasourceService.executeExternalDataSource(906, [slideId])
      .then(deleteFeatureSlideResult => {
        deleteFeatureSlideResolve(deleteFeatureSlideResult);
      }).catch(error => {
        deleteFeatureSlideReject(error);
      });
    });
  }


  reducedEntityTableFunctions(entityTableBaseEntityId) {
    if (this.commonService.isNullOrUndefined(entityTableBaseEntityId)) {
      return false;
    }

    const entityTableFeature = this.allFeatures.find(feature =>
      feature.settingJson.entityId == entityTableBaseEntityId &&
      feature.isActive == true
    );
    if (entityTableFeature) {
      if (!this.selectedPackageHasFeature(entityTableFeature.id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // navigateToFeatureRoute(featureId) {
  //   const selectedFeature = this.allFeatures.find(feature => feature.id == featureId);
  //   if (this.commonService.isNullOrUndefined(selectedFeature.settingJson.route)) {
  //     return;
  //   } else {
  //     this.eventService.showJjPopup('EVERLEAD', 'features', '90');      
  //   }
  // }

  getFeatureById(featureId) {
    const feature = this.allFeatures.find(feature => feature.id == featureId);
    if (feature) {
      return feature;
    } else {
      return null;
    }
  }

  getInnerHTMLOfFeatureSlider(featureSlider) {
    if (this.commonService.isNullOrUndefined(featureSlider)) {
      return '';
    } else {
      const settings = JSON.parse(featureSlider.settings) || {};
      const text = settings[`text_${this.axivasTranslateService.currentLanguage}`];
      return this.domSanitizer.bypassSecurityTrustHtml(text);
    }
  }

  getFeatureSubHeaderById(featureId) {
    const feature = this.getFeatureById(featureId);
    if (feature) {
      return feature.settingJson.featureSubHeader;
    }
  }

  getImageOfFeatureSlider(currentSlider) {
    if (this.commonService.isNullOrUndefined(currentSlider)) {
      return '';
    } else {
      return currentSlider.base64Img;
    }
  }

  createFeature(): Promise<any> {
    return new Promise((createFeatureResolve, createFeatureReject) => {
      this.externaldatasourceService.executeExternalDataSource(908, [
        'New feature',
        true
      ])
        .then(createFeatureResult => {
          createFeatureResolve(createFeatureResult);
        }).catch(error => {
          createFeatureReject(error);
        });
    });
  }

  updateFeature(feature): Promise<any> {
    return new Promise((updateFeatureResolve, updateFeatureReject) => {
      this.externaldatasourceService.executeExternalDataSource(909, [
        feature.id,
        feature.defaultName,
        feature.isActive,
        feature.notes,
        JSON.stringify(feature.settings),
      ])
        .then(updateFeatureResult => {
          updateFeatureResolve(updateFeatureResult);
        }).catch(error => {
          updateFeatureReject(error);
        });
    });
  }

  createPackage(): Promise<any> {
    return new Promise((createFeatureResolve, createFeatureReject) => {
      this.externaldatasourceService.executeExternalDataSource(918, [
        'New package',
        true
      ])
        .then(createFeatureResult => {
          createFeatureResolve(createFeatureResult);
        }).catch(error => {
          createFeatureReject(error);
        });
    });
  }

  updatePackage(featuresPackageId): Promise<any> {
    const updatedFeaturesPackage = this.allPackages.find(featuresPackage => featuresPackage.id == featuresPackageId);

    return new Promise((updatePackageResolve, updatePackageReject) => {
      this.externaldatasourceService.executeExternalDataSource(917, [
        updatedFeaturesPackage.id,
        updatedFeaturesPackage.defaultName,
        updatedFeaturesPackage.isActive,
        updatedFeaturesPackage.notes,
        JSON.stringify(updatedFeaturesPackage.settings),
      ])
        .then(updatePackageResult => {
          updatePackageResolve(updatePackageResult);
        }).catch(error => {
          updatePackageReject(error);
        });
    });
  }

  createPackageFeature(packageId, featureId, notes = '', order = 1): Promise<any> {
    return new Promise((createPackageFeatureResolve, createPackageFeatureReject) => {
      this.externaldatasourceService.executeExternalDataSource(920, [
        this.commonService.getModifyArrayBody({
          packageId: packageId,
          featureId: featureId,
          notes: notes,
          order: order
        }, [])
      ])
        .then(createPackageFeatureResult => {
          createPackageFeatureResolve(createPackageFeatureResult);
        }).catch(error => {
          createPackageFeatureReject(error);
        });
    });
  }

  updatePackageFeature(applicationPackageFeature): Promise<any> {
    return new Promise((updatePackageFeatureResolve, updatePackageFeatureReject) => {
      this.externaldatasourceService.executeExternalDataSource(921, [
        this.commonService.getModifyArrayBody(applicationPackageFeature, [])
      ])
        .then(updatePackageFeatureResult => {
          updatePackageFeatureResolve(updatePackageFeatureResult);
        }).catch(error => {
          updatePackageFeatureReject(error);
        });
    });
  }

  getAllFeaturesOfPackage(packageId): Promise<any> {
    return new Promise((getAllFeaturesOfPackageResolve, getAllFeaturesOfPackageReject) => {
      this.externaldatasourceService.executeExternalDataSource(924, [packageId])
        .then(getAllFeaturesOfPackageResult => {
          getAllFeaturesOfPackageResolve(getAllFeaturesOfPackageResult);
        }).catch(error => {
          getAllFeaturesOfPackageReject(error);
        });
    });
  }

  getMaxMemberForEntity(entityId) {
    if (this.commonService.isNullOrUndefined(this.featureObject['maxEntityMember' + entityId])) {
      return 0;
    } else {
      return this.featureObject['maxEntityMember' + entityId];
    }
  }

  createPackageFeatureRelation(packageId, featureId) {
    return new Promise((createPackageFeatureRelationResolve, createPackageFeatureRelationReject) => {
      this.externaldatasourceService.executeExternalDataSource(922, [
        this.commonService.getModifyArrayBody({ packageId: packageId, featureId: featureId }, [])
      ])
        .then(createPackageFeatureRelationResult => {
          createPackageFeatureRelationResolve(createPackageFeatureRelationResult);
        }).catch(error => {
          createPackageFeatureRelationReject(error);
        });
    });

  }

  deletePackageFeatureRelation(relationId) {
    return new Promise((deletePackageFeatureRelationResolve, deletePackageFeatureRelationRelationReject) => {
      this.externaldatasourceService.executeExternalDataSource(923, [relationId])
        .then(() => {
          deletePackageFeatureRelationResolve(null);
        }).catch(error => {
          deletePackageFeatureRelationRelationReject(error);
        });
    });
  }

  getPackageName(packageId) {
    let returnValue = '';
    const foundPackage = this.allPackages.find(packageItem => packageItem.id == packageId);
    if (foundPackage) {
      returnValue = foundPackage.defaultName;
    }
    return returnValue;
  }
}
