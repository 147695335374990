

<app-layout header="WebsiteDashboard.Label.MyLastActivities">
    <div class="dashboardMyLastActivities">
        <div class="dashboardMyLastActivities__inner">
            <div class="dashboardupdates__tabs">
                <app-tab svg="calendar-dots" [active]="page == 0" label="WebsiteDashboard.Label.MyLastActivities" (onClick)="page = 0"></app-tab>        
            </div>
        
            <div *ngIf="page === 0" class="dashboardMyLastActivities__page">
                <div *ngIf="noData == true" class="noData">
                    <div>{{ 'General.Label.NoDataAvailable' | texttransform }}<br>
                    </div>
                </div>
                <div *ngFor="let activity of activities" class="everleadTableRow">
                    <app-dashdashboardcomponent-lastactivity-item [activity]="activity"></app-dashdashboardcomponent-lastactivity-item>
                </div>                
            </div>
        </div>
    </div>
</app-layout>
  
  
  