import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-movetasks',
  templateUrl: './movetasks.component.html',
  styleUrl: './movetasks.component.scss'
})
export class MovetasksComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  mode = 0;
  fromUserId = null;
  toUserId = null;
  multiUserIds = [];
  projectUsers = [];
  distributionMode = 0;
  proModeControlId = 275114;
  
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    private eventService: EventService,
    public commonService: CommonService,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.getProjectUsers();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getProjectUsers();
      }
    });
  }

  getProjectUsers() {
    if (this.userService.hasPermission('CanUpdateTask')) {
      this.projectUsers = this.applicationInfoService.projectUsers.toArray();
    }
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  reassignTasks(multiIds = false) {
    let userIds = [];
    if (multiIds) {
      if (this.commonService.checkIfItemIsInArray(this.multiUserIds, this.fromUserId)) {
        this.commonService.removeItemFromArray(this.multiUserIds, this.fromUserId);
      }
      userIds = this.multiUserIds;
    } else {
      userIds.push(this.toUserId);
    }
    this.externaldatasourceService.executeExternalDataSource(975, [this.fromUserId, userIds]).then(() => {
      this.messagingService.showDefaultSuccess('TaskOrganisation.Label.MoveTasks', 'TaskOrganisation.Label.MoveTasksSuccess');
    });
  }

  toggleUser(userId) {
    if (this.commonService.checkIfItemIsInArray(this.multiUserIds, userId)) {
      this.commonService.removeItemFromArray(this.multiUserIds, userId);
    } else {
      this.multiUserIds.push(userId);
    }
  }
}
