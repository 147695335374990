<div [id]="applicationInfoService?.versionNumber"></div>
<div class="everleadMainContentWrapper">
  <div class="everleadMainContentMisc">
    <video id="remoteVideo" hidden="hidden"></video>
    <video id="localVideo" hidden="hidden" muted></video>

    <span *ngIf="setLocation()"></span>
    <p-toast key="topRight" position="top-right" [baseZIndex]="999999"></p-toast>
    <div [id]="applicationInfoService?.versionNumber"></div>
    <app-banner [ngClass]="{'hideInDom': checkAppVisibilityRequirements() }"></app-banner>
    <app-incomingcall> </app-incomingcall>
    <app-newversionavailable *ngIf="showNewVersionControl"></app-newversionavailable> 
    <app-wizard-translations mode=2></app-wizard-translations>
    <app-videoplayer *ngIf="applicationInfoService.useNewDesign && applicationInfoService.isMobile() == false"></app-videoplayer>
    <app-awslogin *ngIf="applicationInfoService.showAWsLoginPage"></app-awslogin>
    <p-confirmDialog *ngIf="!applicationInfoService.showAWsLoginPage" acceptLabel="{{ 'Message.General.Yes' | texttransform }}" rejectLabel="{{ 'Message.General.No' | texttransform }}"></p-confirmDialog>      
    <app-contentinfo class="componentInfoContainer" *ngIf="applicationInfoService.projectLoaded"></app-contentinfo>
    <app-custom-popup controluiid="popup"></app-custom-popup>
    <app-jj-popup></app-jj-popup>
    <app-toolbar-popup *ngIf="applicationInfoService.projectLoaded"></app-toolbar-popup>
    <app-custom-palette *ngIf="applicationInfoService.projectLoaded"></app-custom-palette>  
  </div>
  
  <div class="everleadMainContentBody" (window:resize)="onResize()">        
    <app-navigation *ngIf="applicationInfoService.projectLoaded && !applicationInfoService?.showAWsLoginPage"></app-navigation>

    <div class="everleadMainContentBody__content">
      <!-- Mobile -->
      <div class="everleadMainContentHeaderMobile" *ngIf="!applicationInfoService?.showAWsLoginPage">
        <app-mobile-header></app-mobile-header>
        <app-breadcrumbandmenu *ngIf="applicationInfoService.projectSelected && applicationInfoService.noProject == false"></app-breadcrumbandmenu>
      </div>

      <!-- Nonmobile -->
      <div class="everleadMainContentHeader" *ngIf="!applicationInfoService?.showAWsLoginPage">
        <app-custom-webphone-popup controluiid="softphone" *ngIf="applicationInfoService.projectSelected"></app-custom-webphone-popup>
        <app-ajando-header *ngIf="applicationInfoService.projectSelected" [ngClass]="{everleadHeaderHidden: !applicationInfoService.isHeaderVisible, everleadHeaderVisible: applicationInfoService.isHeaderVisible }"></app-ajando-header>
        <app-breadcrumbandmenu [ngClass]="{everleadOnlyNavVisible: !applicationInfoService.isHeaderVisible}" *ngIf="applicationInfoService.projectSelected && applicationInfoService.noProject == false"></app-breadcrumbandmenu>
      </div>

      <div class="col nopadding showOnlyOnLargeScreen ringCentralSpacer" [ngClass]="{ hideInDom: checkAppVisibilityRequirements() }"
      *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'"></div>
      <app-phone-ringcentral class="appPhoneRingCentral" [ngClass]="{ hideInDom: checkAppVisibilityRequirements() }"
        *ngIf="applicationInfoService.applicationSettings['showSoftphoneWidget'] == 'true' && featurePhoneNotFound == false"></app-phone-ringcentral>

      <span id="everleadMainContentContentSpan"></span>
      <div class="everleadMainContentContent"
        
        [ngClass]="{ everleadBackground: applicationInfoService?.showAWsLoginPage || !applicationInfoService.projectSelected}" (scroll)="applicationInfoService.onWindowScroll($event)">      
        <router-outlet *ngIf="applicationInfoService.projectLoaded && applicationInfoService.projectSelected" class="routerOutletDiv"
          [ngClass]="{ hideInDom: !checkAppVisibilityRequirements() }"></router-outlet>
      </div>
    </div>    
</div>

<span *ngIf="showLoader && applicationInfoService.projectLoaded" class="loading"></span>
<app-splashscreen *ngIf="applicationInfoService.showLoadProjectSpinner && !applicationInfoService?.showAWsLoginPage"></app-splashscreen>
<div id="everleadFooter" class="everleadFooter" *ngIf="applicationInfoService?.showApplicationFooter"></div>

<div>    
    <div *ngIf="
      applicationInfoService.errorOccured || 
      applicationInfoService.postGrahphileRestart ||
      applicationInfoService.noProjectsForUser" class="errorScreen">
      <div class="maxHeight maxWidth" class="errorScreen__inner maxHeight">
        <div class="errorScreen__inner__header everleadCoral jjBold">
          EVERLEAD
        </div>
        <div class="errorScreen__inner__body everleadBlueColor">
          <div class="d-flex">
            <div class="errorScreen__inner__body__left">
              <i class="fas fa-sad-tear fa-3x" *ngIf="applicationInfoService.errorOccured"></i>
              <i class="fas fa-exclamation-triangle fa-3x" *ngIf="applicationInfoService.postGrahphileRestart || applicationInfoService.noProjectsForUser"></i>
            </div>
            <div class="errorScreen__inner__body__right" *ngIf="applicationInfoService.errorOccured">
              <div>Derzeit besteht ein Problem mit der Anwendung, bitte versuchen Sie es später erneut.</div>
              <div>There is currently a problem with the application, please try again later.</div>    
            </div>
            <div class="errorScreen__inner__body__right" *ngIf="applicationInfoService.noProjectsForUser">
              <div>Ihrem Benutzer sind noch keine Projekte zugeordnet. Bitte wenden Sie sich an ihren Supervisor.</div>
              <div>No projects have been assigned to your user yet. Please contact your supervisor.</div>    
            </div>
            <div class="errorScreen__inner__body__right" *ngIf="applicationInfoService.postGrahphileRestart">
              {{ 'PostGraphile.Label.Restarting' | texttransform }}
            </div>
          </div>
        </div>
        <div *ngIf="applicationInfoService.errorOccured">
          <button class="errorScreen__inner__body__button everleadBlueColor" (click)="reloadPage()">Seite neu laden / Reload page</button>
        </div>
        <div class="errorScreen__inner__footer">
          v.{{ applicationInfoService.versionNumber }}
        </div>
      </div>
</div>