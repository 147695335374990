import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import * as FileSaver from 'file-saver';
import { SessionStorageService } from 'ngx-webstorage';
import { isUndefined, isNull } from 'util';
import { ObjectDeserializeService } from '../pipe/object-deserialize.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EntityService } from './entity.service';
import { Dictionary } from 'app/core/dictionary';
import { now } from 'moment';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

enum CompareValueType {
  ValueFromEntity = 1,
  SimpleValueCompare = 2,
  ValueFromApplicationInfoService = 3,
  ValueFromApplicationMiscSettings = 4,
  Pool = 6,
  Campaign = 7
}

export class ToggleArrayItemResult {
  itemRemoved: boolean = null;
  resultArray: any[] = null;

  constructor(itemRemoved: any, resultArray: any) {
    this.itemRemoved = itemRemoved;
    this.resultArray = resultArray;
  }
}

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  encryptKey = '1203199320052021';
  encryptIv = '1203199320052021';

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private sessionStorage: SessionStorageService,
    private axivasTranslateService: AxivasTranslateService,
    private objectDeserializeService: ObjectDeserializeService,
    private commonService: CommonService,
    private http: HttpClient,
  ) { }


  cleanKeysfromEntityTable(array){
    return array.map((item) =>{
      const newItem={}
      for (const key in item){
          const newKey = key.replace(/^_\d+_/, '')
          newItem[newKey] = item[key];
         
      }
      return newItem;
      })
  }


  makeFirstLetterUpperCase(str:string){
    if(!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  makeToTitleCase(str:string){
    return str.toLowerCase().split(' ').map((item) => item.charAt(0) +item.slice(1)).join(' ');
  }

  newSortArrayWithTranslationToken(array, defaultName = 'defaultName', deleteUiHelper = true , sortKey?:any ){
    array.forEach(item => {
      if (this.isNullOrUndefined(item.lookupTable)) {
        item['uiSortHelper'] = this.getNameTranslationOrDefaultName(item, defaultName, true);
        item['translation'] = this.axivasTranslateService.getTranslationTextForToken(item['uiSortHelper']);
      } else {
        item['uiSortHelper'] = this.getNameTranslationOrDefaultName(item.lookupTable, defaultName, true);
        item['translation'] = this.axivasTranslateService.getTranslationTextForToken(item['uiSortHelper']);
      }
    });
    const key = sortKey ? sortKey : 'translation';
    if(array.length > 0){
      array?.sort((a, b) => a[key].toLowerCase() < b[key].toLowerCase() ? -1 : 1);
    }
 
    if (deleteUiHelper) {
      array.forEach(item => {
        delete item['uiSortHelper'];
      });
    }
  }

  sortArrayWithTranslationToken(array, defaultName = 'defaultName', deleteUiHelper = true) {
    array.forEach(item => {
      if (this.isNullOrUndefined(item.lookupTable)) {
        item['uiSortHelper'] = this.getNameTranslationOrDefaultName(item, defaultName, true);
      } else {
        item['uiSortHelper'] = this.getNameTranslationOrDefaultName(item.lookupTable, defaultName, true);
      }
    });
    array.sort((a, b) => a.uiSortHelper.toLowerCase() < b.uiSortHelper.toLowerCase() ? -1 : 1);
    if (deleteUiHelper) {
      array.forEach(item => {
        delete item['uiSortHelper'];
      });
    }
  }

  randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  dapperToGQL(dapperKram) {
    dapperKram = this.switchDapperValues(dapperKram, 'accountid', 'accountId');
    dapperKram = this.switchDapperValues(dapperKram, 'contactid', 'contactId');
    dapperKram = this.switchDapperValues(dapperKram, 'taskid', 'taskId');
    return dapperKram;
  }

  switchDapperValues(array, source, destination) {
    if (source != undefined) {
      array[destination] = array[source];
    }
    return array;
  }

  getProjectLookupTableValues(lookupTableDefinitionId, source) {
    const lookupTable = this.applicationInfoService.projectLookupTables.find(projectLookupTable =>
      projectLookupTable.definitionId == lookupTableDefinitionId &&
      projectLookupTable.source == source
    );
    if (lookupTable) {
      return lookupTable.lookups;
    } else {
      return [];
    }
  }

  checkApplicationRoute(sectionName) {
    let currentLocation = window.location.href.toString();
    const currentLocationDetails = currentLocation.split('?');
    currentLocation = currentLocationDetails[0];
    if (currentLocation.endsWith(sectionName)) {
      return true;
    } else {
      return false;
    }
  }

  checkApplicationRouteIsNotParamValue(sectionName) {
    let currentLocation = window.location.href.toString();
    const currentLocationDetails = currentLocation.split('?');
    currentLocation = currentLocationDetails[0];
    if (currentLocation.endsWith(sectionName)) {
      return false;
    } else {
      return true;
    }
  }

  getAlternateTable(table) {
    let alternateTable = {
      table: null,
      alternateDisplayName: null,
      isTranslated: true
    };

    switch(table) {
      case 'projectUserAll':
        alternateTable.table = this.applicationInfoService.projectUsersAll;
        alternateTable.alternateDisplayName = 'userName';
        alternateTable.isTranslated = false;
        alternateTable.table.sort((a, b) => a.userName < b.userName ? -1 : 1);
        break;
      case 'projectUser':
        alternateTable.table = this.applicationInfoService.projectUsers.toArray();
        alternateTable.alternateDisplayName = 'userName';
        alternateTable.isTranslated = false;
        alternateTable.table.sort((a, b) => a.userName < b.userName ? -1 : 1);
        break;
      case 'steps':
        alternateTable.table = this.applicationInfoService.steps.toArray();
        break;
      case 'results':        
        alternateTable.table = this.applicationInfoService.results.toArray();
        break;
      case 'campaigns':
        alternateTable.table = this.applicationInfoService.campaigns.toArray();
        break;
      case 'workflows':
        alternateTable.table = this.applicationInfoService.workflows.toArray();
        break;
      case 'pools':
        alternateTable.table = this.applicationInfoService.pools.toArray();
        break;
      case 'leadstate': // war aus den Lookups, macht aber mittlerweile keinen sinn mehr
        // alternateTable.table = this.getProjectLookupTableValues(1, 'central');
        alternateTable.table = JSON.parse(JSON.stringify(this.applicationInfoService.leadstates.toArray()));
        alternateTable.table.forEach(item => {
          item.id = item.lookupTable.id;
        });
        break;
      case 'projectleadstate':
        alternateTable.table = JSON.parse(JSON.stringify(this.applicationInfoService.leadstates.toArray()));
        alternateTable.table.forEach(item => {
          item.id = item.lookupTable.id;
        });
        break;
      case 'progresstates':
        alternateTable.table = this.getProjectLookupTableValues(31, 'central');
          break;
    }
    return alternateTable;
  }

  checkIfDateIsSoon(date, daysInFuture) {
    date = new Date(date);
    const today = new Date(Date.now());
    var dateSoon = new Date(today.getFullYear(), today.getMonth(), today.getDate() + Number(daysInFuture));
    if (date > dateSoon) {
        return true;
    } else {
      return false;
    }
  }

  isOdd(number) {
    return number % 2;
  }

  checkIfDateIsBetweenTodayAndXDays(date, daysInFuture) {
    const checkDate = new Date(date);
    const dateNow = new Date(Date.now());
    if (checkDate < dateNow) {
      return false;
    }
    const dateWithDaysInFuture = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() + Number(daysInFuture));
    if (checkDate < dateWithDaysInFuture) {
      return true;
    } else {
      return false;
    }
  }

  checkIfDateIsBetweenTodayAndXDaysInPast(date, daysInPast) {
    const checkDate = new Date(date);
    const dateNow = new Date(Date.now());
    const dateWithDaysInPast = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() - Number(daysInPast));
    if (checkDate > dateWithDaysInPast) {
      return true;
    } else {
      return false;
    }
  }

  checkIfDateIsInFuture(date) {
    const today = new Date();
    if (today < new Date(date)) {
      return true;
    } else {
      return false;
    }
  }

  checkIfDateIsInPast(date) {
    if (date == null) {
      return false;
    }
    const today = new Date();
    if (today > new Date(date)) {
      return true;
    } else {
      return false;
    }
  }

  checkIfDateIsToday(date) {
    const today = new Date();
    date = new Date(date);
    return date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear();
  }

  checkIfDateIsTomorrow(date) {
    date = new Date(date);
    const today = new Date(Date.now());
    var date1_tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    if (date1_tomorrow.getFullYear() == date.getFullYear() && date1_tomorrow.getMonth() == date.getMonth() && date1_tomorrow.getDate() == date.getDate()) {
        return true;
    } else {
      return false;
    }
  }

  getAlternateTableValue(table, value) {
    let alternateTable = null;
    if (this.isNullOrUndefined(value)) {
      return '';
    }
    alternateTable = this.getAlternateTable(table);
    if (!alternateTable.table) {
      return 'table not found';
    }

    value = Number(value);
    let tableItem  = null;
    tableItem = alternateTable.table.find(item => item.id == value);
    if (tableItem) {
      if (alternateTable.isTranslated) {
        return this.getTranslationValueFromArray(tableItem);
      } else {
        if (alternateTable.alternateDisplayName==null) {
          return tableItem.defaultName;
        } else {
          return tableItem[alternateTable.alternateDisplayName];
        }
      }
    } else {
      return 'Item '.concat(value, ' not found');
    }
  }

  public startsWidth(baseString: string, checkString: any) {
    return (baseString.substring(0, checkString.length) === checkString);
  }

  public endsWidth(baseString: string, checkString: any) {
    return baseString.match(checkString+"$")==checkString;
  }

  public getTranslationValueFromArray(arrayToCheck: any, defaultValue = 'defaultName'): string {
    // Sonderfall uiControlTranslation
    if (arrayToCheck == null) {
      return '';
    }
    let translationValue = 'translation' + this.applicationInfoService.languageId;
    if (this.getControlSettingOrDefault(arrayToCheck, translationValue, '') != '') {
      return this.getControlSettingOrDefault(arrayToCheck, translationValue, '');
    }
    if (!this.isNullOrUndefined(arrayToCheck.lookupTable)) {
      arrayToCheck = arrayToCheck.lookupTable;
    }
    let resultString = '';
    if (arrayToCheck.nameTranslationTokenId != null) {
      resultString = arrayToCheck.nameTranslationToken.tokenFullName;
    } else {
      resultString = arrayToCheck[defaultValue];
    }
    return resultString;
  }

  getControlSettingOrDefault(control, setting, defaultValue) {
    const settingJSON = this.getControlSettings(control);
    if (settingJSON != null) {
      if (this.isNullOrUndefined(settingJSON)) {
        return defaultValue;
      } else {
        if (this.isNullOrUndefined(settingJSON[setting])) {
          return defaultValue;
        } else {
          return settingJSON[setting];
        }
      }
    } else {
      return defaultValue;
    }
  }

  getControlSettings(control) {
    if (control == null) {
      return null;
    }
    let returnValue = null;
    if (control.settings != null && control.settings != '') {
      returnValue = JSON.parse(control.settings);
    }
    return returnValue;
  }

  createCopyOfArray(array) {
    return JSON.parse(JSON.stringify(array));
  }

  getLookupTableIdByDefaultName(lookupTableArray, defaultNameToSearch) {
    let returnValue = null;
    lookupTableArray.forEach(lookupTable => {
      if (lookupTable.defaultName.toUpperCase() === defaultNameToSearch.toUpperCase()) {
        returnValue = lookupTable.id;
      }
    })
    return returnValue;
  }

  toNumber(value) {
    return Number(value);
  }

  checkRegEx(regEx, value) {
    return regEx.test(value)
  }

  getDurationString(durationInSeconds) {
    const hrs = ~~(durationInSeconds / 3600);
    const mins = ~~((durationInSeconds % 3600) / 60);
    const secs = ~~durationInSeconds % 60;

    let finalTimeString = "";
    if (hrs > 0) {
      finalTimeString += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    finalTimeString += "" + mins + ":" + (secs < 10 ? "0" : "");
    finalTimeString += "" + secs;

    return finalTimeString;
  }

  encryptSignalRText(text: string, iv: string): string {​​​​​​​​
    const dataIv = '000' + iv;
    const dataKey = 'efoije4rpk40rk4!';
    return this.encryptData(dataKey, dataIv, text);
  }​​​​​​​​

  encryptData(dataKey: string, dataIv: string, txtText: string): string {
    if (txtText.length > 0 ) {
      const key = CryptoJS.enc.Utf8.parse(dataKey);
      const iv = CryptoJS.enc.Utf8.parse(dataIv);

      const encryptedData = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(txtText),
        key,
        {
          keySize: 128 / 8,
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );
      const encodeData = encodeURIComponent(encryptedData.toString());
      return encodeData;
    }
    return '';
  }

  encryptUsingAES256(text): any {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.encryptKey, {
        keySize: 128 / 8,
        iv: this.encryptIv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  decryptUsingAES256(decString) {
      var decrypted = CryptoJS.AES.decrypt(decString, this.encryptKey, {
          keySize: 128 / 8,
          iv: this.encryptIv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
  }

  getApplicationSectionContentTipp(currentLocation) {
    if (this.applicationInfoService.applicationSettings['showHelpContent'] != 'true') {
      return null;
    }

    let returnValue = null;
    let content = [];
    this.applicationInfoService.tipps.forEach(tipp => {
      if (this.checkIfStringContainsString(currentLocation, tipp.tippGuid + '?')) {
        content.push(tipp)
        returnValue = content;
      }
    });
    return returnValue;
  }

  getTimeDifferenceString(element1, element2, stringContent = '', checkIfOverdue = false){
    let returnString = '';
    var date = (element1 != undefined) ? element1 : element2;
    var compareDate = new Date(date);
    var now = new Date();
    let showThatDifferenceIsOverdue = false;
    var time = now.getTime() - compareDate.getTime();
    if (time < 0) {
      time = time * -1;
    } else {
      if (checkIfOverdue) {
        showThatDifferenceIsOverdue = true;
      }
    }
    var days = Math.floor(time / (1000 * 3600 * 24));
    if (days > 0) {
      returnString = returnString.concat(days.toString(), ' ', this.axivasTranslateService.getTranslationTextForToken('DateDifference.Label.Days'), ' ')
    }
    var hours =  Math.floor(time / (1000 * 3600) - (days * 24));
    if (hours > 0) {
      returnString = returnString.concat(hours.toString(), ' ', this.axivasTranslateService.getTranslationTextForToken('DateDifference.Label.Hours'), ' ')
    }
    var minutes =  Math.floor((time/1000)/60) - (hours * 60) - (days * 60 * 24);
    if (minutes > 0) {
      returnString = returnString.concat(minutes.toString(), ' ', this.axivasTranslateService.getTranslationTextForToken('DateDifference.Label.Minutes'))
    }
    var seconds =  Math.round((time/1000)/60) - (hours * 60) - (days * 60 * 24) - (minutes * 60);

    if (showThatDifferenceIsOverdue) {
      returnString = this.axivasTranslateService.getTranslationTextForToken('DateDifference.Label.Overdue').replace('<0>', returnString);
    } else {
      if (stringContent != '') {
        stringContent = this.axivasTranslateService.getTranslationTextForToken(stringContent);
        returnString = stringContent.replace('<0>', returnString);
      }
      if (minutes == 0 && hours == 0 && days == 0) {
        returnString = this.axivasTranslateService.getTranslationTextForToken('DateDifference.Label.Now');
      }
    }
    return returnString;
  }

  getTimeDifferenceShortString(element1, element2){
    var diffMs = (element1 - element2);
    let diffMins = Math.floor((diffMs / 1000) / 60);
    let diffSecs = Math.round((diffMs / 1000) - (60 * diffMins));
    if (diffSecs == 60) { diffSecs = 0; diffMins ++; }
    return ''.concat(diffMins.toString().padStart(2, '0'), ':', diffSecs.toString().padStart(2, '0'));
  }

  getTimeDifferenceInHours(date1, date2) {
    // const date1 = new Date(Date.now());
    // const date2 = new Date(storageItem.timestamp);
    return Math.abs(date1.getTime() - date2.getTime()) / 36e5;
  }

  getLocalStorageCache(cacheItem: string, maxAgeInHours = 8) {
    let returnValue = null;
    const cacheItemName = this.applicationInfoService.projectID.toString().concat(cacheItem);
    const storageItem = JSON.parse(localStorage.getItem(cacheItemName));
    if (storageItem) {
      if (this.getTimeDifferenceInHours(new Date(Date.now()), new Date(storageItem.timestamp)) > maxAgeInHours) {
        localStorage.removeItem(cacheItemName)
      } else {
        returnValue = storageItem.value;
      }
    }
    return returnValue;
  }

  setLocalStorageCache(cacheItem: string, value) {
    const cacheItemName = this.applicationInfoService.projectID.toString().concat(cacheItem);
    localStorage.setItem(cacheItemName, JSON.stringify({
      timestamp: new Date(Date.now()),
      value: value
    }));
  }


  isInViewport(elementId, topBonusValue = 0, leftBonusValue = 0) {
    const element = document.getElementById(elementId);
    if (element == null || element == undefined) {
      return;
    }
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= (0 + topBonusValue) &&
        rect.left >= (0 + leftBonusValue) &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  getDifferenceInDays(element1, element2, stringContent = '', multiplicator = 1){

    var date = (element1 != undefined) ? element1 : element2;
    var compareDate = new Date(date);
    var now = new Date();

    var time = now.getTime() - compareDate.getTime();
    var days = Math.ceil(time / (1000 * 3600 * 24));
    days = (days != null) ? days : 0;
    days = days * multiplicator;
    // return (days < 1) ? 'heute' : ('vor ' + days + ' Tage(n)');
    if (stringContent == '') {
      return days;
    } else {
      let returnValue = '';
      const hours = (Math.ceil(time / (1000 * 3600)));
      if (hours < 24) {
        returnValue = hours + ' hours';
      } else {
        returnValue = stringContent.replace('<0>', days.toString());
      }

      // return (days <= 1) ?  returnHours : stringContent.replace('<0>', days.toString());
      return returnValue;
    }
  }

  getLookupTableItemByDefaultName(lookupTableArray, defaultNameToSearch) {
    let returnValue = null;
    lookupTableArray.forEach(lookupTable => {
      if (lookupTable.defaultName.toUpperCase() === defaultNameToSearch.toUpperCase()) {
        returnValue = lookupTable;
      }
    })
    return returnValue;
  }


  splitRows(value: string) {
    if (this.isNullOrUndefined(value)) {
      return [];
    }
    return value.split('\n');
  }


  downloadFile(response: any, mediaType: string, fileName: string) {
    fileName = this.modifyQueryStringWithApplicationInfos(fileName);
    fileName = fileName.replace('%2b', '+');
    fileName = fileName.replace('%26', '&');
    const blob = new Blob([response], {type: mediaType});
    FileSaver.saveAs(blob, fileName);
  }

 

  checkIfStringEndsWithString(baseString, containedString) {
    if (this.isNullOrUndefined(baseString)) { return false; }
    let checkString = baseString.substr(baseString.length - containedString.length);
    if (checkString.toLowerCase() == containedString.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  }

  checkIfStringContainsString(baseString, containedString, debugMessage = false) {
    if (this.isNullOrUndefined(baseString)) {
      return false;
    } else {
      baseString = baseString.toString();
    }
    try {
      if (baseString.toUpperCase().indexOf(containedString.toUpperCase()) > -1) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  replaceStringWithEntityValues(headerString, entityId, identifier) {
    let entityData = this.applicationInfoService.entities.toArray().find(entity => entity.id == entityId);
    if (this.isNullOrUndefined(entityData)) {
      return headerString;
    } else {
      entityData = entityData.data;
    }

    Object.keys(entityData).forEach((key) => {
      let entityValue = entityData[key];
      if (this.isNullOrUndefined(entityValue)) { entityValue = ''; }
      headerString = headerString.replace('{{'.concat(identifier, '.', key, '}}'), entityValue );
    });
    return headerString;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  deCapitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  checkIfStringStartsWith(baseString, startString) {
    return baseString.toUpperCase().startsWith(startString.toUpperCase());
  }

  checkIfArrayItemChanged(item, array): boolean {
    let returnValue = true;
    if (this.isNullOrUndefined(item) || this.isNullOrUndefined(array)) {
      returnValue = true;
    } else {
      array.forEach(arrayItem => {
        if (arrayItem.id === item.id) {
          if (JSON.stringify(arrayItem) === JSON.stringify(item)) {
            returnValue = false;
          }
        }
      });
    }
    return returnValue;
  }

  getDateTimeString(date: Date): string {
    const dateString = ''.concat(
      date.getFullYear().toString(),
      '_',
      (date.getMonth() + 1).toString(),
      '_',
      date.getDate().toString(),
      '_',
      date.getHours().toString(),
      '_',
      date.getMinutes().toString(),
      '_',
      date.getSeconds().toString()
    );
    return dateString;
  }

  getDateString(date: Date): string {
    const dateString = ''.concat(
      date.getFullYear().toString(), '-',
      (date.getMonth() + 1).toString(), '-',
      date.getDate().toString()
    );
    return dateString;
  }

  setSessionStorageItem(key: string, value: any) {
    if (!value) {
      value = '';
    }
    this.sessionStorage.store(key, value);
  }

  getSessionStorageItem(key: string) {
    return this.sessionStorage.retrieve(key);
  }

  clearSessionStorageItem(key: string) {
    this.sessionStorage.clear(key);
  }

  removeItemFromArray(array: any, item: any): any {
    const index = array.indexOf(item, 0);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }

  getUserListForExternalUser(userList, customerIdMode = false) {
    if (customerIdMode == true) {
      return userList.filter(item => 
        item.customerId != null && 
        item.isActive == true &&
        item.id != 6
      );  
    } else {
      return userList.filter(item => 
        item.isExternalUser == true && 
        item.isActive == true &&
        item.id != 6
      );  
    }
  }

  removeItemFromArrayByIndex(array: any, index: any): any {
    array.splice(index, 1);
    return array;
  }

  isDate(value) {
    return !isNaN(new Date(value).getDate());
  }

  getTooltip(object: any): string {
    let returnString = '';
    if (!this.isNullOrUndefined(object.nameTranslationToken)) {
      returnString = this.axivasTranslateService.getTranslationTextForToken(object.nameTranslationToken.tokenFullName);
    } else {
      returnString = object.defaultName;
    }
    if (!this.isNullOrUndefined(object.descriptionTranslationToken)) {
      returnString = returnString.concat('\n', '\n',
        this.axivasTranslateService.getTranslationTextForToken(object.descriptionTranslationToken.tokenFullName));
    }
    return returnString;
  }

  objectToArray(baseObject: any): any[] {
    return Object.keys(baseObject).map((key) => [key, baseObject[key]]);
  }

  openUrl(siteUrl, target = '_new') {
    let value = siteUrl;
    if (value !== '' && value !== undefined) {      
      if (value.toLowerCase().startsWith('www')) {
        value = 'http://' + value;
      }
      if (!value.toLowerCase().startsWith('http')) {
        value = 'http://' + value;
      }
      console.warn(value)
      window.open(value, target);
    }
  }

  checkForNullAndUndefinedAndReturnDefault(value, defaultValue) {
    if (this.isNullOrUndefined(value)) {
      return defaultValue;
    } else {
      return value;
    }
  }

  convertStringToUnicode(value) {
    value = value.replace(/Ä/g, 'u00c4');
    value = value.replace(/ä/g, 'u00e4');
    value = value.replace(/Ü/g, 'u00dc');
    value = value.replace(/ü/g, 'u00fc');
    value = value.replace(/Ö/g, 'u00d6');
    value = value.replace(/ö/g, 'u00f6');
    value = value.replace(/ß/g, 'u00df');
    return value;
  }

  searchPhoneNumberInWebPhonebook(account, link = 'https://www.dastelefonbuch.de/Suche/<0>/<1>') {
    let name = '';
    let nameShortcutForOpendi = '';
    if (account.name3 == '' || this.isNullOrUndefined(account.name3)) {
      if (!this.isNullOrUndefined(account.name1)) {
        name = name.concat(account.name1);
        nameShortcutForOpendi = name.substring(0, 1).toUpperCase();
      }
      if (!this.isNullOrUndefined(account.name2)) {
        if (name != '') { name = name.concat(' '); }
        name = name.concat(account.name2);
      }
      link = link.replace('<0>', name);
    } else {
      link = link.replace('<0>', account.name3);
      nameShortcutForOpendi = account.name3.substring(0, 1).toUpperCase();
    }
    if (this.isNullOrUndefined(account.city)) {
      link = link.replace('<1>', '');
    } else {
      link = link.replace('<1>', account.city);
    }
    if (this.isNullOrUndefined(account.zipcode)) {
      link = link.replace('<2>', '');
    } else {
      link = link.replace('<2>', account.zipcode.concat(' '));
    }
    link = link.replace('<3>', nameShortcutForOpendi);
    link = encodeURI(link);
    this.openUrl(link, '_new')
  }

  cleanStringValue(value: string): string {
    if (value == null) { return ''; }
    value = value.replace(/\\/g, '\\\\');
    value = value.replace(/\w\\(?!\\)/g, '$&\\');
    value = value.replace(/(?:\r\n|\r|\n)/g, '\\n');
    value = value.replace(/"/g, '\\"');
    value = value.replace(/\//g, '\\/');
    value = value.replace(/(?:\r\n|\r|\n)/g, '\\n');
    return value;
  }

  removeQuotes(baseText): string {
    if (this.startsWidth(baseText, '"')) {
      baseText = baseText.substr(1);
    }
    if (this.endsWidth(baseText, '"')) {
      baseText = baseText.slice(0, -1);
    }
    return baseText;
  }

  getModifiedEntityValuesString(entityId: any, excludedValues: any[], mustBeOneOfThisMembers: any[] = null) {
    let resultString = '';
    const modifiedValues = this.getModifiedEntityValues(entityId);
    modifiedValues.forEach(modifiedValue => {
      if (!this.checkIfItemIsInArray(excludedValues, modifiedValue)) {
        if (mustBeOneOfThisMembers) {
          if (this.checkIfItemIsInArray(mustBeOneOfThisMembers, modifiedValue)) {
            resultString = resultString.concat(this.getQueryStringForEntityMemberValue(entityId, modifiedValue), ' ');
          }
        } else {
          resultString = resultString.concat(this.getQueryStringForEntityMemberValue(entityId, modifiedValue), ' ');
        }
      }
    });
    return resultString;
  }

  undefinedToDefaultValue(value: any, defaultValue = null) {
    if (isUndefined(value)) {
      return defaultValue;
    } else {
      return value;
    }
  }

  nullOrUndefinedToDefaultValue(value: any, defaultValue = null) {
    if (this.isNullOrUndefined(value)) {
      return defaultValue;
    } else {
      return value;
    }
  }

  removeNonNumericsFromInt(value: any) {
    value = value.replace('.', '');
    value = Number(value);
    return Number(value);
  }

  getQueryStringForEntityMemberValue(entityId: any, memberName: any): string {
    let result = '';
    entityId = entityId.toString();
    if (this.applicationInfoService.entities.ContainsKey(entityId)) {
      this.applicationInfoService.entities.Item(entityId).entityMembers.forEach(entityMember => {
        if (entityMember.defaultName === memberName) {
          let memberValue = this.applicationInfoService.entities.Item(entityId).data[memberName];
          switch (entityMember.memberType) {
            case 'DATETIME':
                result = '<0>: "<1>"';
                memberValue = new Date(memberValue).toUTCString();
              break;
            case 'TEXT':
                result = '<0>: "<1>"';
              break;
            case 'NUMERIC':
                result = '<0>: <1>';
                // memberName = this.removeNonNumericsFromInt(memberName);
              break;
            case 'BOOL':
                result = '<0>: <1>';
              break;
          }
          result = result.replace('<0>', memberName);
          result = result.replace('<1>', memberValue);
        }
      });
    } else {
      console.error('EntityService getQueryStringForEntityMemberValue: Entity not found: ', entityId);
    }
    return result;
  }

  getModifiedEntityValues(entityId: any, insertQuery: boolean = false): any[] {
    const modifiedValues: any[] = [];
    const baseValues = this.objectToArray(this.applicationInfoService.entities.Item(entityId).data);
    const origValues = this.objectToArray(this.applicationInfoService.entitiesorig.Item(entityId).data);
    baseValues.forEach(element => {
      let addElement = false;
      if (insertQuery) {
        if ('id' !== element[0]) {
          addElement = true;
        }
      } else {
        if (this.getArrayValue(baseValues, element[0]) !== this.getArrayValue(origValues, element[0])) {
          addElement = true;
        }
      }
      if (this.startsWidth(element[0], 'ui')) {
        addElement = false;
      }
      if (addElement) {
        modifiedValues.push(element[0]);
      }
    });
    return modifiedValues;
  }

  getArrayValue(array: any[], key: any) {
    let result = null;
    array.forEach(element => {
      if (element[0] === key) {
        result = element[1];
      }
    });
    return JSON.stringify(result);
  }

  msToTime(duration) {
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24).toString();
    let seconds = Math.floor((duration / 1000) % 60).toString();
    let minutes = Math.floor((duration / (1000 * 60)) % 60).toString();
    // let milliseconds: any = duration / 1000 / 100;
    // milliseconds = (milliseconds.toString().length > 3)
    //   ? milliseconds.toString().substring(0, 3)
    //   : milliseconds;
    hours = (Number(hours) < 10) ? '0' + hours : hours;
    minutes = (Number(minutes) < 10) ? '0' + minutes : minutes;
    seconds = (Number(seconds) < 10) ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds;
  }

  getFirstItemFromArrayIfAvailable(array: any[], defaultValue: any = null): any {
    let getFirstItemFromArrayIfAvailableResult = defaultValue;
    if (array.length > 0) {
      getFirstItemFromArrayIfAvailableResult = array[0];
    }
    return getFirstItemFromArrayIfAvailableResult;
  }

  isValidDate(date) {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
  }

  public modifyQueryStringWithApplicationInfos(queryBaseString, convertSpecialValues = false): string {
    let resultString = queryBaseString;
    resultString = resultString.replace(/<projectId>/g, this.applicationInfoService.projectID.toString());
    if (convertSpecialValues) {
      resultString = resultString.replace(/{projectId}/g, this.applicationInfoService.projectID.toString());
    }
    resultString = resultString.replace(/<dateNow>/g, new Date(Date.now()).toUTCString());
    resultString = resultString.replace(/<poolId>/g, this.applicationInfoService.poolId.toString());
    resultString = resultString.replace(/<dateNowString>/g, this.getDateTimeString(new Date(Date.now())));
    resultString = resultString.replace(/<languageId>/g, this.applicationInfoService.languageId);
    resultString = resultString.replace(/<campaingnModeId>/g, this.getCampaignModeCampaignId());
    resultString = resultString.replace(/<campaingnModeIdNull>/g, this.getCampaignModeCampaignId(true));
    if (this.applicationInfoService.miscSettings) {
      resultString = resultString.replace(/<miscIgnoreOptin>/g, this.applicationInfoService.miscSettings['ignoreOptin']);
    }
    if (this.applicationInfoService.isFollowUpWorkflowStepId != null) {
      resultString = resultString.replace(/<followUpStepId>/g, this.applicationInfoService.isFollowUpWorkflowStepId.toString());
    }

    if (convertSpecialValues) {
      // Mis settings
      if (this.applicationInfoService.miscSettings) {
        resultString = resultString.replace(/{miscIgnoreOptin}/g, this.applicationInfoService.miscSettings['ignoreOptin']);
      }

      resultString = resultString.replace(/{projectId}/g, this.applicationInfoService.projectID.toString());
      resultString = resultString.replace(/{poolId}/g, this.applicationInfoService.poolId.toString());
      resultString = resultString.replace(/{languageId}/g, this.applicationInfoService.languageId);
      resultString = resultString.replace(/{campaingnModeId}/g, this.getCampaignModeCampaignId());
      resultString = resultString.replace(/{campaingnModeIdNull}/g, this.getCampaignModeCampaignId(true));
      if (!this.isNullOrUndefined(this.applicationInfoService.user)) {
        resultString = resultString.replace(/{userName}/g, this.applicationInfoService.user.userName);
        if (!this.isNullOrUndefined(this.applicationInfoService.user.fakeUserId)) {
          resultString = resultString.replace(/{userId}/g, this.applicationInfoService.user.fakeUserId.toString());
        } else {
          resultString = resultString.replace(/{userId}/g, this.applicationInfoService.userID.toString());
        }
      }
      resultString = resultString.replace(/{userId}/g, this.applicationInfoService.userID);
    }

    if (this.applicationInfoService.projectInfo) {
      if (this.applicationInfoService.projectInfo.defaultWorkflowId != null) {
        resultString = resultString.replace(/<defaultWorkflowId>/g, this.applicationInfoService.projectInfo.defaultWorkflowId.toString());
      } else {
        resultString = resultString.replace(/<defaultWorkflowId>/g, '');
      }
      if (this.applicationInfoService.projectInfo.defaultWorkflowId != null) {
        if (this.applicationInfoService.workflows.ContainsKey(this.applicationInfoService.projectInfo.defaultWorkflowId.toString())) {
          if (this.applicationInfoService.workflows.Item(this.applicationInfoService.projectInfo.defaultWorkflowId.toString()).starterStep) {
            resultString = resultString.replace(/<defaultStepId>/g, this.applicationInfoService.workflows.Item(
              this.applicationInfoService.projectInfo.defaultWorkflowId.toString()
            ).starterStep.id);
          } else {
            console.warn('starter step not found');
          }
        }
      }
    }
    resultString = resultString.replace(/<defaultCampaignId>/g, this.getDefaultCampaign());

    if (this.applicationInfoService.currentAccount) {
      resultString = resultString.replace('<accountId>', this.applicationInfoService.currentAccount.id.toString());
      resultString = resultString.replace('<accountName>', this.applicationInfoService.currentAccount.name1.toString());
      if (convertSpecialValues) {
        resultString = resultString.replace('{accountId}', this.applicationInfoService.currentAccount.id.toString());
        resultString = resultString.replace('{accountName}', this.applicationInfoService.currentAccount.name1.toString());
      }
    } else {
      resultString = resultString.replace('<accountId>', null);
      resultString = resultString.replace('<accountName>', '');
    }
    if (this.applicationInfoService.currentContact) {
      resultString = resultString.replace('<contactId>', this.applicationInfoService.currentContact.id.toString());
      resultString = resultString.replace('<contactName>', this.applicationInfoService.currentContact.lastName.toString());
      if (convertSpecialValues) {
        resultString = resultString.replace('{contactId}', this.applicationInfoService.currentContact.id.toString());
        resultString = resultString.replace('{contactName}', this.applicationInfoService.currentContact.lastName.toString());
      }
    } else {
      resultString = resultString.replace('<contactId>', null);
      resultString = resultString.replace('<contactName>', '');
    }
    if (this.applicationInfoService.currentTask) {
      if (this.applicationInfoService.currentTask.id) {
        resultString = resultString.replace('<taskId>', this.applicationInfoService.currentTask.id.toString());
      }
      if (this.applicationInfoService.currentTask.stepId) {
        resultString = resultString.replace('<stepId>', this.applicationInfoService.currentTask.stepId.toString());
      }
      if (this.applicationInfoService.currentTask.resultId) {
        resultString = resultString.replace('<resultId>', this.applicationInfoService.currentTask.resultId.toString());
      }
    }

    resultString = resultString.replace('<customerId>', this.applicationInfoService.customerID.toString());

    if (!this.isNullOrUndefined(this.applicationInfoService.user)) {
      resultString = resultString.replace('<userName>', this.applicationInfoService.user.userName);
      if (!this.isNullOrUndefined(this.applicationInfoService.user.fakeUserId)) {
        // resultString = resultString.replace('<fakeUserId>', this.applicationInfoService.user.fakeUserId.toString());
        resultString = resultString.replace('<userId>', this.applicationInfoService.user.fakeUserId.toString());
      } else {
        resultString = resultString.replace('<userId>', this.applicationInfoService.userID.toString());
        // resultString = resultString.replace('<fakeUserId>', this.applicationInfoService.userID.toString());
      }
    } else {
      resultString = resultString.replace('<userId>', this.applicationInfoService.userID.toString());
      // resultString = resultString.replace('<fakeUserId>', this.applicationInfoService.userID.toString());
    }
    return resultString;
  }

  validatePhoneForE164(phoneNumber, checkInternal = true) {
    const regEx = /^\+[1-9]\d{1,14}$/;
    if (phoneNumber.length == 4 && checkInternal) {
      return true;
    } else {
      return regEx.test(phoneNumber);
    }
  };

  hideBoldBiDropdowns() {
    for (let i = 0; i < 15; i++) {
      const checkElement = document.getElementById('dashboard_embeddedbicomboBoxElementComboBox'.concat(i.toString(), '_popup_wrapper'));
      if (checkElement) { checkElement.style.display='none'; }
    }
  }

  getEntityDynamicFields(entityId: any) {
    if (entityId === null) {
      return '';
    }

    let returnString = '';
    const dynamicFieldList = [];
    this.applicationInfoService.controls.impArray.items.forEach(controlId => {
      const control = this.applicationInfoService.controls.Item(controlId);
      if (control.uiEntityInstanceId === entityId) {
        if (!this.isNullOrUndefined(control.value)) {
          if (!this.checkIfItemIsInArray(dynamicFieldList, control.value)) {
            dynamicFieldList.push(control.value);
          }
        }
      }
    });
    dynamicFieldList.forEach(item => {
      returnString = returnString.concat(' ', item);
    });
    return returnString;
  }

  validateEMail(email) {
    let returnValue = false;
    if (
      this.checkIfStringContainsString(email, '@') &&
      this.checkIfStringContainsString(email, '.')
    ) {
      returnValue = true;
    }
    return returnValue;
  };

  isPhoneNumberInNumberArray(array, number) {
    var targSanitized = number.replace (/[^\d]/g, "")
                                .replace (/^.*(\d{10})$/, "$1");
    var arraySanitized = array.join ('Á').replace (/[^\dÁ]/g, "") + 'Á';
    return (new RegExp (targSanitized + 'Á') ).test (arraySanitized);
  }

  getModifyArrayBody(array: any, excludeList: any, enumList: any = [], referenceEntity = null, useArrayValues = false) {
    const ignoreTheseValuesPerDefault = this.nullOrUndefinedToDefaultValue(this.applicationInfoService.
      applicationSettings['createModifyStringFromArrayIgnoreTheseValuesPerDefault'], '').split(';');
    let returnValue = '';
    excludeList.push('tempItemId');
    const values = Object.getOwnPropertyNames(array);
    values.forEach(value => {
      if (!this.checkIfItemIsInArray(excludeList, value) && !this.checkIfItemIsInArray(
        ignoreTheseValuesPerDefault, value)) {
        let convertType = '';
        if (referenceEntity === null) {
          convertType = (typeof array[value]);
          if (this.isValidDate(array[value])) {
                convertType = 'date';
          }
        } else {
          let valueFound = false;
          referenceEntity.entityMembers.forEach(entityMember => {
            if (entityMember.defaultName === value) {
                valueFound = true;
                if (entityMember.memberType === 'TEXT') { convertType = 'string'; }
                if (entityMember.memberType === 'BOOL') { convertType = 'boolean'; }
                if (entityMember.memberType === 'NUMERIC') { convertType = 'number'; }
                if (entityMember.memberType === 'DATE') { convertType = 'date'; }
                if (entityMember.memberType === 'DATETIME') { convertType = 'date'; }
            }
            if (!valueFound) {
              convertType = (typeof array[value]);
              if (this.isValidDate(array[value])) {
                convertType = 'date';
              }
            }
          });
        }
        if (!Array.isArray(array[value])) {
          if (array[value] == null) { convertType = 'null'; }
          if (convertType === 'string') {
            if (this.checkIfItemIsInArray(enumList, value)) {
              returnValue = returnValue.concat(value, ': ', this.cleanStringValue(array[value]), ' ');
            } else {
              returnValue = returnValue.concat(value, ': ', '"', this.cleanStringValue(array[value].toString()), '" ');
            }
          }
          if (convertType === 'boolean') {
            returnValue = returnValue.concat(value, ': ', array[value], ' ');
          }
          if (convertType === 'date') {
            returnValue = returnValue.concat(value, ': "', new Date(array[value]).toUTCString(), '" ');
          }
          if (convertType === 'number') {
            returnValue = returnValue.concat(value, ': ', array[value], ' ');
          }
          if (convertType === 'null') {
            returnValue = returnValue.concat(value, ': ', array[value], ' ');
          }
          if (convertType === 'object') {
            returnValue = returnValue.concat(value, ': [', array[value], '] ');
          }
        } else {
          if (useArrayValues) {
            returnValue = returnValue.concat(value, ': [', array[value], '] ');
          }
        }
      }
    });
    return returnValue;
  }

  getDefaultCampaign() {
    let returnValue = null;
    if (this.applicationInfoService.projectInfo != null) {
      return this.applicationInfoService.projectInfo.defaultCampaignId;
    }
    return returnValue;
  }

  getCampaignModeCampaignId(useNullIfNoSpecificCampaignIdIsSelected = false) {
    let returnValue = null;
    if (this.applicationInfoService.campaingnModeId == null) {
      if (useNullIfNoSpecificCampaignIdIsSelected) {
        returnValue = null;
      } else {
        returnValue = this.getDefaultCampaign();
      }
    } else {
      returnValue = this.applicationInfoService.campaingnModeId;
    }
    return returnValue;
  }

  getArrayFromEnum(targetEnum: any): string[] {
    const myEnum = [];
    const objectEnum = Object.keys(targetEnum);
    const values = objectEnum.slice( 0 , objectEnum.length / 2 );
    const keys = objectEnum.slice( objectEnum.length / 2 );

    for (let i = 0 ; i < objectEnum.length / 2 ; i++ ) {
      myEnum.push( { key: keys[i], value: values[i] } );
    }
    return myEnum;
  }

  modifyQueryWithDynamicEntityMember(query: string, entity: any): string {
    query = query.replace(/<dynamicFields>/, this.getEntityDynamicFields(entity));
    return query;
  }

  toPascalCase(string) {
    return `${string}`
      .replace(new RegExp(/[-_]+/, 'g'), ' ')
      .replace(new RegExp(/[^\w\s]/, 'g'), '')
      .replace(
        new RegExp(/\s+(.)(\w+)/, 'g'),
        ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
      )
      .replace(new RegExp(/\s/, 'g'), '')
      .replace(new RegExp(/\w/), s => s.toUpperCase());
  }

  GetValueForTranslation(originalValue: any, defaultValue: any = ''): string {
    let resultString = defaultValue;
    if (originalValue) {
      resultString = originalValue;
    }
    return resultString;
  }

  enumToArray(sourceEnum): any[] {
    const result = [];
    for (var enumMember in sourceEnum) {
       result.push({ id: enumMember, name: sourceEnum[enumMember] })
    }
    return result;
  }

  toggleArrayItem(array: any[], item: any): ToggleArrayItemResult {
    let itemRemoved = false;
    if (!array) {
      array = [];
    }
    const index = array.indexOf(item, 0);
    if (index > -1) {
      array.splice(index, 1);
      itemRemoved = true;
    } else {
      array.push(item);
    }
    return new ToggleArrayItemResult(itemRemoved, array);
  }

  createGuid(): string {
    let result = '';
    let i: string;
    let j: number;
    for (j = 0; j < 32; j++) {
        if ((j === 8) || (j === 12) || (j === 16) || (j === 20)) {
            result = result + '-';
        }
        i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
        result = result + i;
    }
    return result;
  }

  removeLastCharacterFromString(baseString): string {
    if (baseString.length > 0) {
      baseString = baseString.substring(0, baseString.length - 1);
    }
    return baseString;
  }

  checkIfItemIsInArray(array: any[], item: any): boolean {
    if (this.isNullOrUndefined(array)) {
      return false;
    }
    const index = array.indexOf(item, 0);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  camelToSnakeCase(camelString) {
    return camelString.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  addToObject(item: any, args: string) {
    // console.warn('addToObject start', item, args);
    if (item == null) {return null; }
    const argsSplit = args.split('.');
    let result: any = null;
    let currentLayer = '';
    for (let i = 0; i <= argsSplit.length - 1; i++) {
      // console.warn('addToObject step', i, argsSplit.length - 1, 'start');
      if (currentLayer === '') {
        result = item;
      } else {
        result = this.objectDeserializeService.transform(item, currentLayer);
        // console.warn('addToObject step', i, 'currentValueToAdd', argsSplit[i], 'in', result);
      }
      if (this.isNullOrUndefined(result[argsSplit[i]])) {
        result[argsSplit[i]] = [];
      }

      // console.warn('addToObject step', i, result, currentLayer, argsSplit[i], item);
      if (currentLayer !== '') {
        currentLayer = currentLayer.concat('.');
      }
      currentLayer = currentLayer.concat(argsSplit[i]);
      // console.warn('addToObject step', i, 'currentLayer', currentLayer);
    }
  }

  toggleItemExpanded(array: any, itemId: any): any {
    if (this.isNullOrUndefined(array[itemId])) {
      array[itemId] = true;
    } else {
      array[itemId] = !array[itemId];
    }
    return array;
  }

  isItemExpanded(array: any, itemId: any) {
    if (this.isNullOrUndefined(array[itemId])) {
      return false;
    } else {
      return array[itemId];
    }
  }

  getCompareValue(modifier, compareValue): any {
    switch (modifier.compareValueType) {
      case CompareValueType.SimpleValueCompare:
        compareValue = modifier.compareValue;
      break;
      case CompareValueType.ValueFromEntity:
        const toArray = modifier.compareValue.split(';');
        if (this.applicationInfoService.entities.ContainsKey(toArray[0])) {
          if (!this.isNullOrUndefined(this.applicationInfoService.entities.Item(toArray[0]).data)) {
            compareValue = this.objectDeserializeService.transform(this.applicationInfoService.entities.Item(toArray[0]).data, toArray[1], null)
            // compareValue = this.applicationInfoService.entities.Item(toArray[0]).data[toArray[1]];
          }
        }
        break;
      case CompareValueType.ValueFromApplicationInfoService:
          compareValue = this.applicationInfoService[modifier.compareValue];
        break;
      case CompareValueType.ValueFromApplicationMiscSettings:
          compareValue = this.applicationInfoService.miscSettings[modifier.compareValue];
        break;
      case CompareValueType.Pool:
        compareValue = this.applicationInfoService.poolId;
        break;
      case CompareValueType.Campaign:
        compareValue = this.applicationInfoService.campaingnModeId;
        break;
    }
    return compareValue;
  }

  getValueByType(value) {
    if (typeof(value) === 'string') {
      return '"' + value + '"';
    } else {
      return value;
    }
  }

  removeIllegalCharactersFromFileName(source: string): string {
    source = source.replace(/[/\\?%*:|"<>]/g, '-');
    return source;
  }

  compare(modifier, compareValue, result, returnValue = 'newValue'): any {
    if (compareValue === undefined) { compareValue = null; }
    if (!this.isNullOrUndefined(compareValue)) { compareValue = compareValue.toString(); }
    let refValue = '';
    if (modifier.refValue == '<null>') {
      refValue = null;
    } else {
      refValue = modifier.refValue;
    }

    if (refValue == '<null>') { refValue = null; }
    switch (modifier.compareType) {
      case '!=':
        if (compareValue !== refValue) { result = modifier[returnValue]; }
        break;
      case '=':
        if (compareValue === refValue) { result = modifier[returnValue]; }
        break;
      case '>=':
        if (compareValue >= refValue) { result = modifier[returnValue]; }
        break;
      case '<=':
        if (compareValue <= refValue) { result = modifier[returnValue]; }
        break;
      case '<':
        if (compareValue < refValue) { result = modifier[returnValue]; }
        break;
      case '>':
        if (compareValue > refValue) { result = modifier[returnValue]; }
        break;
    }
    return result;
  }

  isNullOrUndefined(value) {
    if (value !== null && value !== undefined) {
      return false;
    } else {
      return true;
    }
  }

  encodeUri(url): string {
    return encodeURI(url);
  }

  getContactLanguageId(defaultValue) {
    if (!this.isNullOrUndefined(this.applicationInfoService.currentContact)) {
      if (!this.isNullOrUndefined(this.applicationInfoService.currentContact.languageId)) {
        return this.applicationInfoService.currentContact.languageId;
      }
    }

    if (!this.isNullOrUndefined(this.applicationInfoService.currentContact.languageId)) {
      return this.applicationInfoService.currentContact.languageId;
    }

    return defaultValue;
  }

  copyTextToClipboard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerHTML = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  openGoogle(value: string) {
    this.openUrl('https://www.google.de/search?q=' + value);
  }

  openXing(value: string) {
    this.openUrl(
      'https://login.xing.com/?dest_url=https%3A%2F%2Fwww.xing.com%2Fgo%2Flogin%2Fcompanies%2Fsearch%3Fkeywords%3D' + value);
  }

  openLinkedIn(value1: string, value2, isAccount) {
    let url = 'https://www.linkedin.com/pub/dir?firstName=<0>&lastName=<1>&trk=guest_homepage-basic_people-search-bar_search-submit';
    if (isAccount) {
      url = 'https://www.linkedin.com/search/results/companies/?keywords=<0>&origin=SWITCH_SEARCH_VERTICAL&sid=%3AJ.';
      url = url.replace('<0>',  encodeURI(value1));
    } else {
      url = url.replace('<0>', value1);
      url = url.replace('<1>', value2);
    }
    this.openUrl(url);
  }

  getNameTranslationFromIdOrDefaultName(object, defaultName = 'defaultName'){
    if (this.isNullOrUndefined(object.nameTranslationTokenId)) {
      return object[defaultName];
    } else {
      return this.axivasTranslateService.getTranslationTextForId(object.nameTranslationTokenId);
    }
  }
  
  getDescriptionTranslationFromIdOrDefaultName(object, defaultName = 'defaultName'){
    if (this.isNullOrUndefined(object.descriptionTranslationTokenId)) {
      return object[defaultName];
    } else {
      return this.axivasTranslateService.getTranslationTextForId(object.descriptionTranslationTokenId);
    }
  }

  getNameTranslationOrDefaultName(object, defaultName = 'defaultName', translateToken = false) {
    if (!this.isNullOrUndefined(object.lookupTable)) {
      object = object.lookupTable;
    }
      if (object.nameTranslationToken && object.nameTranslationTokenId != null) {
        if (translateToken) {
          return this.axivasTranslateService.getTranslationTextForToken(object.nameTranslationToken.tokenFullName);
        } else {
          return object.nameTranslationToken.tokenFullName;
        }
      } else {
        if (object.nameTranslationTokenId) {
        
          return this.axivasTranslateService.getTranslationTextForId(object.nameTranslationTokenId);
        } else {
          return this.axivasTranslateService.getTranslationTextForToken(object[defaultName]);
        }
    }
  }

  createSeperatedStringFromArray(array, arrayValue = null, seperator = ';') {
    let returnString = '';
    array.forEach(item => {
      if (returnString != '') {
        returnString = returnString.concat(seperator);
      }
      if (arrayValue == null) {
        returnString = returnString.concat(item);
      } else {
        returnString = returnString.concat(item[arrayValue]);
      }
    })
    return returnString;
  }

  flatArrayToLevelArray(flatArray, valueToAssign = null) {
    const levelArray = [];
    let currentLayer = levelArray;
    flatArray.forEach((item, index) => {
      if (index == flatArray.length - 1) {
        currentLayer[item] = valueToAssign;
      } else {
        currentLayer[item] = [];
      }
      currentLayer = currentLayer[item];
    });
    return levelArray;
  }

  checkJsonMenuVisibilityBlockedComponent(array, value) {
    let returnValue = true;
    if (!this.isNullOrUndefined(array) && array != '') {
      const details = array.split(';');
      details.forEach(detail => {
        if (Number(detail) == value) { returnValue = false; }
      });
    } else {
      returnValue = true;
    }

    return returnValue;
  }

  checkJsonMenuVisibilityRequiredComponent(array, value) {
    let returnValue = false;
    if (!this.isNullOrUndefined(array) && array != '') {
      const details = array.split(';');
      details.forEach(detail => {
        if (Number(detail) == value) { returnValue = true; }
      });
    } else {
      returnValue = true;
    }
    return returnValue;
  }

  getAttachmentSizeStringFromAttachmantSize(size) {
    let returnValue = '';
    size = Number(size);
    if (this.isNullOrUndefined(size)) {
      return returnValue;
    }
    if (size > 1048576) {
      return (size / 1048576).toFixed(2) + ' MB';
    } else if (size > 1024) {
      return (size / 1024).toFixed(2) + ' kb';
    } else {
      return size + ' b';
    }
  }

   getOptinStateForMedia(availableMediaItem, optStatesResult, contact) {
    let returnValue = {
      defaultName: '',
      lookupTypeRefId: 0,
      startDate: null,
      endDate: null,
      endDateInPast: false,
      ip: null,
      userId: null,
      comment: null
    };
    const availableOptStates = optStatesResult.filter(
      optState => optState.lookupMediumRefId == availableMediaItem.id &&
                  optState.contactId == contact.id
    );
    availableOptStates.forEach(state => {
      let updateValue = false;
      if (returnValue.startDate == null) {
        updateValue = true;
      } else {
        if (new Date(returnValue.startDate) < new Date(state.startDate)) {
          updateValue = true;
        }
      }

      if (updateValue) {
        returnValue.startDate = state.startDate;
        returnValue.endDate = state.endDate;
        returnValue.endDateInPast = this.checkIfDateIsInPast(state.endDate);
        returnValue.ip = state.ip;
        returnValue.lookupTypeRefId = state.lookupTypeRefId;
        if (state.changedByUserId != null)  {
          returnValue.userId = state.changedByUserId;
        } else if (state.createdByUserId != null) {
          returnValue.userId = state.createdByUserId;
        }
        returnValue.comment = state.comment;
      }
    });
    return returnValue;
  }

  sumArrayValue(array, property) {
  
    return array.reduce( function(a, b){
      return a + b[property];
    }, 0);

  
  }

  formatNumberToCurrencyString(valueToConvert, currencyShortcut, environment, digits = 2) {
    const formatter = new Intl.NumberFormat(environment, {
      style: 'currency',
      currency: currencyShortcut,         
      maximumFractionDigits: digits, 
      minimumFractionDigits: digits,          
    });
    return formatter.format(valueToConvert)
  }
}

type ClickHandler = (...any) => void;
export class ClickContext {
  public isSingleClick = true;
  public timeOut = 250;

  clickHandler(handler: ClickHandler): ClickHandler {
      return function() {
          const args = arguments;
          this.isSingleClick = true;
          setTimeout(() => {
              this.isSingleClick && handler.apply(undefined, args);
          }, 250);
      };
  }

  dblClickHandler(handler: ClickHandler): ClickHandler {
      return function() {
          const args = arguments;
          this.isSingleClick = false;
          setTimeout(() => {
              this.isSingleClick = true;
          }, 250);
          handler.apply(undefined, args);
      };
  }
}
