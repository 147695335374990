import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Injectable({
  providedIn: 'root'
})
export class EntitytableService {
  vipEntity = 975;
  aiContactEntity = 992;
  vipSettingEntity = 1061;
  optinEntity = 20;
  emailEntity = 1130;
  eventsEntity = 780;
  contentLibraryFileEntity = 1227;
  opportunityEntity = 99;
  userProjectEntity = 1354;

  // prospects
  webvisitors= 917;
  webvisitordetail=780;

  public tableFilterSettings = new Map();

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    private packagefeatureService: PackagefeatureService
  ) { }

  reducedFeatures(entityTableEntityId) {
    return this.packagefeatureService.reducedEntityTableFunctions(entityTableEntityId);
  }

  getEntityTableDefinitionForControl(logicalControlId) {
    // Project specific
    let tableData = this.applicationInfoService.tableLayoutDefinitions.find(definition => 
      definition.logicalControlId == logicalControlId &&
      definition.projectId == this.applicationInfoService.projectID
    );
    if (!tableData) {
      // Global setting
      tableData = this.applicationInfoService.tableLayoutDefinitions.find(definition => 
        definition.logicalControlId == logicalControlId &&
        definition.projectId == null
      );        
    }
    return tableData;
  }

  getUniversalTableDefinitionForControl(logicalControlId) {
    const tableData = this.applicationInfoService.tableLayoutDefinitions.find(definition => 
      definition.logicalControlId == logicalControlId &&
      definition.projectId == null
    );        
    return tableData;
  }

  createOrUpdateUniversalTemplateFromThisLayout(entityTableDefinition, logicalControlId): Promise<any> {
    return new Promise((createOrUpdateGeneralTemplateFromThisLayoutResolve, createOrUpdateGeneralTemplateFromThisLayoutReject) => {
      let externalDataSourceId = 766; // New layout
      let excludes = 'id';
      
      // Check if there is already a default
      const existingUniversalTemplate = this.getUniversalTableDefinitionForControl(logicalControlId);
      let templateToAdd = JSON.parse(JSON.stringify(entityTableDefinition));
      templateToAdd.projectId = null;
      if (existingUniversalTemplate) {
        externalDataSourceId = 764;        
        templateToAdd.id = existingUniversalTemplate.id;
        excludes = '';
      }    
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [
        this.commonService.getModifyArrayBody(templateToAdd, [excludes])
      ]).then(createOrUpdateGeneralTemplateFromThisLayoutResult => {
        if (!existingUniversalTemplate) {
          // Wurde neu angelegt
          let templateToAdd = JSON.parse(JSON.stringify(entityTableDefinition));
          templateToAdd.id = createOrUpdateGeneralTemplateFromThisLayoutResult.id;
          this.applicationInfoService.tableLayoutDefinitions.push(templateToAdd);
        }
        createOrUpdateGeneralTemplateFromThisLayoutResolve(createOrUpdateGeneralTemplateFromThisLayoutResult);
      }).catch(error => { 
        console.error(error);
        createOrUpdateGeneralTemplateFromThisLayoutReject(error); 
      });
    }); 
  }

  createNewProjectSpecificEntityTableDefinition(entityTableDefinition): Promise<any> {
    return new Promise((createNewProjectSpecificEntityTableDefinitionResolve, createNewProjectSpecificEntityTableDefinitionReject) => {
      this.externaldatasourceService.executeExternalDataSource(766, [this.commonService.getModifyArrayBody(entityTableDefinition, ['id'])
      ]).then(createNewProjectSpecificEntityTableDefinitionResult => {
        this.applicationInfoService.tableLayoutDefinitions.push(entityTableDefinition);
        entityTableDefinition.id = createNewProjectSpecificEntityTableDefinitionResult.id;
        createNewProjectSpecificEntityTableDefinitionResolve(entityTableDefinition);
      }).catch(error => { createNewProjectSpecificEntityTableDefinitionReject(error); });
    }); 
  }


  getAvailableEntities(dataTableItem) {
    //console.log('dataTableItem', dataTableItem)
  let availableEntities = [];
    if (dataTableItem == null) { return; }
    switch (dataTableItem.mainEntityId) {
      case 1:
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        availableEntities.push(this.applicationInfoService.entities.Item(this.vipSettingEntity.toString()));        
        break;
      case 2:
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        availableEntities.push(this.applicationInfoService.entities.Item('2'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        availableEntities.push(this.applicationInfoService.entities.Item(this.vipSettingEntity.toString()));        
        availableEntities.push(this.applicationInfoService.entities.Item('462'));        
        availableEntities.push(this.applicationInfoService.entities.Item('780'));        
        break;
      case 9:
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        availableEntities.push(this.applicationInfoService.entities.Item('2'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        break;
      case 4: 
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        availableEntities.push(this.applicationInfoService.entities.Item(this.aiContactEntity.toString()));        
        availableEntities.push(this.applicationInfoService.entities.Item(this.vipSettingEntity.toString()));        
        break;
      case 462: // conversion
        availableEntities.push(this.applicationInfoService.entities.Item('462'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        break;
      case 917: // prospect  
        availableEntities.push(this.applicationInfoService.entities.Item('917'));
        availableEntities.push(this.applicationInfoService.entities.Item('975'));
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        break;
      case 934: // recording
        availableEntities.push(this.applicationInfoService.entities.Item('934'));
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        break;
      case this.vipEntity: // vip
        availableEntities.push(this.applicationInfoService.entities.Item(this.vipEntity.toString()));
        break;
      case this.aiContactEntity:
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        availableEntities.push(this.applicationInfoService.entities.Item(this.aiContactEntity.toString()));
        break;
      case this.optinEntity:
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        availableEntities.push(this.applicationInfoService.entities.Item(this.optinEntity.toString()));
        break;
      case this.emailEntity:
        availableEntities.push(this.applicationInfoService.entities.Item('462'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        availableEntities.push(this.applicationInfoService.entities.Item(this.emailEntity.toString()));
        break;
      case this.eventsEntity:
        availableEntities.push(this.applicationInfoService.entities.Item(this.eventsEntity.toString()));
        break;  
      case this.contentLibraryFileEntity:
        availableEntities.push(this.applicationInfoService.entities.Item(this.contentLibraryFileEntity.toString()));
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        break;
      case this.opportunityEntity:
        availableEntities.push(this.applicationInfoService.entities.Item(this.opportunityEntity.toString()));
        availableEntities.push(this.applicationInfoService.entities.Item('1'));
        availableEntities.push(this.applicationInfoService.entities.Item('4'));
        availableEntities.push(this.applicationInfoService.entities.Item('1256'));
        break;
      case this.userProjectEntity:
        availableEntities.push(this.applicationInfoService.entities.Item(this.userProjectEntity.toString()));
        availableEntities.push(this.applicationInfoService.entities.Item('1353'));
        availableEntities.push(this.applicationInfoService.entities.Item('1355'));
         break;        
    }

/*     console.log('available Entities', availableEntities); */
    return availableEntities;
}

}
