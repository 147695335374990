<div class="echobotInterfaceWrapper">
    <span *ngIf="loadingData==true" class="loadingData">
        <app-jj-icons type="stack-plus" [width]="48" [height]="48"></app-jj-icons>
        <div>
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
            texttransform }}
        </div>
    </span>

    <!-- Account -->
    <div class="echobotInterfaceResult" *ngIf="loadingData==false && previewDataArray == null && resultEntity==1">
        <app-layout header="DataInterface.Label.Results">
            <div class="echobotInterfaceResultInfo" *ngIf="interfaceResults?.length > 0">
                {{ 'DataInterface.Label.SelectResultItemToEnrich' | texttransform }}
            </div>
            <!-- <div class="echobotInformationLine__wrapper" *ngIf="resultEntity == 1">
                <div class="echobotInformationLine">
                    <div class="echobotInformationLine__value">{{ 'DataInterface.Label.MatchScore' | texttransform }}</div>
                    <div class="echobotInformationLine__description">{{ 'DataInterface.Label.MatchScoreDesc' |texttransform }}</div>
                </div>
                <div class="echobotInformationLine">
                    <div class="echobotInformationLine__value">{{ 'DataInterface.Label.DataQuality' | texttransform }}</div>
                    <div class="echobotInformationLine__description">{{ 'DataInterface.Label.DataQualityDesc' | texttransform }}</div>
                </div>
            </div> -->
            <div *ngIf="interfaceResults?.length == 0" class="echobotInterfaceResultNoData">
                {{ 'General.Label.NoDataAvailable' | texttransform }}
            </div>
            <div class="echobotInterfaceResultItemlistItem echobotInterfaceResultItemlistItemHeader"
                *ngIf="interfaceResults?.length > 0">
                <div class="echobotInterfaceResultItemlistItem__name">{{ 'DataInterface.Label.Name' | texttransform }}</div>
                <div class="echobotInterfaceResultItemlistItem__details">{{ 'DataInterface.Label.Details' |texttransform }}</div>
                <div class="echobotInterfaceResultItemlistItem__completenessScore">
                    {{ 'DataInterface.Label.DataQuality' | texttransform }}
                    <app-jj-icons type="info" [width]="16" [height]="16" [tippy]="'DataInterface.Label.DataQualityDesc' | texttransform"></app-jj-icons>
                </div>
                <div class="echobotInterfaceResultItemlistItem__matchScore">
                    {{ 'DataInterface.Label.MatchScore' | texttransform }}
                    <app-jj-icons type="info" [width]="16" [height]="16" [tippy]="'DataInterface.Label.MatchScoreDesc' | texttransform"></app-jj-icons>
                </div>
                <div class="echobotInterfaceResultItemlistItem__coins"></div>
            </div>
            <div class="echobotInterfaceResultItemlist">
                <div class="echobotInterfaceResultItemlistItem everleadTableRow fingerCursor"
                    *ngFor="let interfaceResultItem of interfaceResults" (click)="enrichData(interfaceResultItem)">
                    <div class="echobotInterfaceResultItemlistItem__name" *ngIf="resultEntity == 1">
                        {{ interfaceResultItem.companyName }}
                    </div>
                    <div class="echobotInterfaceResultItemlistItem__details" *ngIf="resultEntity == 1">
                        <div *ngIf="interfaceResultItem.zipcode">{{ interfaceResultItem.zipcode }}</div>
                        <div *ngIf="interfaceResultItem.city">{{ interfaceResultItem.city }}</div>
                        <div *ngIf="interfaceResultItem.street">{{ interfaceResultItem.street }}</div>
                        <div *ngIf="interfaceResultItem.email">{{ interfaceResultItem.email }}</div>
                    </div>
                    <div class="echobotInterfaceResultItemlistItem__completenessScore">
                        <app-bar-leadscore [value]="interfaceResultItem.completenessScore"></app-bar-leadscore>
                    </div>

                    <div class="echobotInterfaceResultItemlistItem__matchScore">
                        <app-bar-leadscore [value]="interfaceResultItem.matchScore"></app-bar-leadscore>
                    </div>
                    <div class="echobotInterfaceResultItemlistItem__coins">
                        <app-jj-icons type="coins" [width]="20" [height]="20" *ngIf="!interfaceResultItem.hasValidEnrichmentData"
                            [tippy]="'DataInterface.Label.EnrichmentIsNotFree' | texttransform"></app-jj-icons>
                        <app-jj-icons type="coins" color="disabledGray" [width]="20" [height]="20" *ngIf="interfaceResultItem.hasValidEnrichmentData"
                            [tippy]="'DataInterface.Label.EnrichmentIsFree' | texttransform"></app-jj-icons>
                    </div>
                </div>
                <div class="echobotInterfaceEnforce" *ngIf="interfaceResultItemIsFree != null">
                    <div>{{ 'DataInterface.Label.LastRequest' | texttransform }} <b>{{ enrichmentData?.requestTime |
                            date: 'medium' }}</b></div>
                    <app-checkbox label="DataInterface.Label.EnforceUpdate" [(ngModel)]="enforceUpdate"></app-checkbox>
                    <div>{{ 'DataInterface.Label.EnforceUpdateDescription' | texttransform }} </div>
                </div>
            </div>
        </app-layout>
    </div>

    <div class="echobotInterfaceResult" *ngIf="loadingData==false && previewDataArray != null && resultEntity==1">
        <!-- Preview -->
        <div class="echobotInterfaceResultInfo" *ngIf="interfaceResults?.length > 0">
            {{ 'DataInterface.Label.TheFollowingDataCanBeEnriched' | texttransform }}
        </div>
        <div class="enrichtmentPreviewItemList">
            <div class="enrichtmentPreviewItemListItem enrichtmentPreviewItemListItem__header">
                <div class="enrichtmentPreviewItemListItem__enrich"></div>
                <div class="enrichtmentPreviewItemListItem__name">
                    {{ 'DataInterface.Label.DataField' | texttransform }}
                </div>
                <div class="enrichtmentPreviewItemListItem__value">
                    {{ 'DataInterface.Label.FromDataInterface' | texttransform }}
                </div>
                <div class="enrichtmentPreviewItemListItem__value">
                    {{ 'DataInterface.Label.CurrentValue' | texttransform }}
                </div>
            </div>
            <div *ngFor="let enrichmentPreviewValue of enrichmentPreviewTableValues"
                class="enrichtmentPreviewItemListItem everleadTableRow">
                <div class="enrichtmentPreviewItemListItem__enrich">
                    <input type="checkbox" [(ngModel)]="enrichmentPreviewValue[3]">
                </div>
                <div class="enrichtmentPreviewItemListItem__name">{{ enrichmentPreviewValue[2] | texttransform }}</div>
                <div class="enrichtmentPreviewItemListItem__value">{{ previewDataArray[enrichmentPreviewValue[0]] }}
                </div>
                <div class="enrichtmentPreviewItemListItem__value">
                    <span *ngIf="currentValues != null">{{ currentValues[enrichmentPreviewValue[1]] }}</span>
                </div>
            </div>
        </div>
        <div class="enrichtmentPreviewItemMinibuttons">
            <app-main-button (onClick)="selectAll()" label="DataInterface.Label.SelectAll"
                type="borderSmall"></app-main-button>
            <app-main-button (onClick)="removeAll()" label="DataInterface.Label.RemoveAll"
                type="borderSmall"></app-main-button>
        </div>
        <div class="enrichtmentPreviewItemButtons">
            <app-main-button (onClick)="enrichAccountData()"
                label="DataInterface.Label.ExecuteEnrichtment"></app-main-button>
        </div>
    </div>

    <!-- Contact -->
    <div class="echobotInterfaceResult" *ngIf="loadingData==false && resultEntity==4">
        <div *ngIf="isHosted" class="apListEnrichment">
            <div class="apListEnrichmentList" *ngIf="apList != null">
                <div class="echobotInformationLine__wrapper" *ngIf="showAPList">                   
                    <!-- <div class="echobotInformationLine__apforaccount">
                        {{ 'DataInterface.Label.APListForAccount' | texttransform }}
                    </div> -->
                    <app-checkbox label="DataInterface.Label.HideWithoutPhone" 
                        [(ngModel)]="applicationInfoService.hideDataEnrichmentInterfaceContactsWithoutPhone"></app-checkbox>
                    <app-main-button svg="magnifying-glass" (onClick)="checkExistingContacts()" 
                        label="DataInterface.Label.CheckExistingContacts" type="borderSmall"></app-main-button>
                </div>
                <div *ngIf="showAPList">
                    <div class="echobotInterfaceResultItemlistItem echobotInterfaceResultItemlistItemHeader">                                                
                        <div class="echobotInterfaceResultItemlistItem__details">{{ 'DataInterface.Label.Department' | texttransform }}</div>
                        <div class="echobotInterfaceResultItemlistItem__apDetailItem">{{ 'DataInterface.Label.HierarchyLevel' | texttransform }}</div>
                        <div class="echobotInterfaceResultItemlistItem__apDetailItem">{{ 'Contact.Label.Position' | texttransform }}</div>
                        <div class="echobotInterfaceResultItemlistItem__icons"></div>
                        <div class="echobotInterfaceResultItemlistItem__completenessScore">
                            {{ 'DataInterface.Label.DataQuality' | texttransform }}
                            <app-jj-icons type="info" [width]="16" [height]="16" [tippy]="'DataInterface.Label.DataQualityApDesc' | texttransform"></app-jj-icons>
                        </div>
                        <!-- <div class="echobotInterfaceResultItemlistItem__matchScore">{{ 'DataInterface.Label.MatchScore' | texttransform }}</div> -->
                        <div class="echobotInterfaceResultItemlistItem__coins"></div>
                    </div>
                </div>
                <div *ngIf="showAPList" class="apListWrapper">
                    <div *ngFor="let interfaceResultItem of apList" (click)="enrichAP(interfaceResultItem)" 
                        class="everleadTableRow" [ngClass]="{ hideInDom: isItemFiltered(interfaceResultItem) }">
                        <div class="echobotInterfaceResultItemlistItem" [ngClass]="{ 
                            fingerCursor: !interfaceResultItem.isInEverlead, 
                            isInEverlead: interfaceResultItem.isInEverlead 
                        }">
                        <div class="echobotInterfaceResultItemlistItem__details">
                            <div *ngFor="let department of interfaceResultItem.departments" class="ellipsisText"
                                [tippy]="getItemTranslation(hierarchyLevel, 112) | texttransform">
                                {{ getItemTranslation(department, 112) | texttransform }}
                            </div>
                        </div>
                        <div class="echobotInterfaceResultItemlistItem__apDetailItem">                            
                            <div *ngFor="let hierarchyLevel of interfaceResultItem.hierarchyLevels" class="ellipsisText"
                                [tippy]="getItemTranslation(hierarchyLevel, 111) | texttransform">
                                {{ getItemTranslation(hierarchyLevel, 111) | texttransform }}
                            </div>
                        </div>
                        <div class="echobotInterfaceResultItemlistItem__apDetailItem">
                            <div class="ellipsisText" [tippy]="interfaceResultItem.position">
                                {{ interfaceResultItem.position }}
                            </div>
                        </div>
                        <div class="echobotInterfaceResultItemlistItem__icons">
                            <app-jj-icons type="gender-male" [width]="20" [height]="20" *ngIf="interfaceResultItem.gender == 'male'"></app-jj-icons>
                            <app-jj-icons type="gender-female" [width]="20" [height]="20" *ngIf="interfaceResultItem.gender == 'female'"></app-jj-icons>
                            <app-jj-icons type="question" [width]="20" [height]="20" *ngIf="
                                interfaceResultItem.gender != 'male' && 
                                interfaceResultItem.gender != 'female'
                            "></app-jj-icons>
                            <app-jj-icons type="envelope" [width]="20" [height]="20" *ngIf="interfaceResultItem.hasMail"></app-jj-icons>
                            <app-jj-icons type="envelope" [width]="20" [height]="20" color="disabledGray" *ngIf="!interfaceResultItem.hasMail"></app-jj-icons>
                            <app-jj-icons type="phone" [width]="20" [height]="20" *ngIf="interfaceResultItem.hasTelephone"></app-jj-icons>
                            <app-jj-icons type="phone" [width]="20" [height]="20" color="disabledGray" *ngIf="!interfaceResultItem.hasTelephone"></app-jj-icons>
                        </div>
                        <div class="echobotInterfaceResultItemlistItem__completenessScore">
                            <app-bar-leadscore [value]="interfaceResultItem.qualityScore"></app-bar-leadscore>
                        </div>
                        <!-- <div class="echobotInterfaceResultItemlistItem__matchScore">
                            <app-bar-leadscore [value]="interfaceResultItem.matchScore"></app-bar-leadscore>
                        </div> -->
                        <div class="echobotInterfaceResultItemlistItem__coins" *ngIf="interfaceResultItem.isInEverlead">
                            <app-jj-icons type="user-check" [width]="20" [height]="20" [tippy]="'DataInterface.Label.ApIsInEverlead' | texttransform"></app-jj-icons>
                        </div>
                        <div class="echobotInterfaceResultItemlistItem__coins" *ngIf="!interfaceResultItem.isInEverlead">
                            <app-jj-icons type="coins" [width]="20" [height]="20" *ngIf="!interfaceResultItem.hasValidEnrichmentData"
                                [tippy]="'DataInterface.Label.EnrichmentIsNotFree' | texttransform"></app-jj-icons>
                            <app-jj-icons type="coins" color="disabledGray" [width]="20" [height]="20" *ngIf="interfaceResultItem.hasValidEnrichmentData"
                                [tippy]="'DataInterface.Label.EnrichmentIsFree' | texttransform"></app-jj-icons>
                        </div>
                        </div>
                        <div class="apInfo" *ngIf="interfaceResultItem.isInEverlead">                            
                            <div class="apInfo__user">
                                <app-jj-icons type="user" [width]="48" [height]="48"></app-jj-icons>
                            </div>
                            <!-- <div class="apInfo__load">
                                <app-jj-icons type="" [width]="24" [height]="24"></app-jj-icons>
                            </div> -->
                            <div class="apInfo__item apInfo__itemHeader">
                                {{ interfaceResultItem.everleadContact.firstName }} 
                                {{ interfaceResultItem.everleadContact.lastName }}
                            </div>
                            <div class="apInfo__item">
                                {{ interfaceResultItem.everleadContact.position }}
                            </div>
                            <div *ngIf="interfaceResultItem.everleadContact.extension" class="apInfo__item">
                                <app-jj-icons type="phone" [width]="20" [height]="20"></app-jj-icons>
                                {{ interfaceResultItem.everleadContact.extension }}
                            </div>
                            <div *ngIf="interfaceResultItem.everleadContact.email" class="apInfo__item">
                                <app-jj-icons type="envelope" [width]="20" [height]="20"></app-jj-icons>
                                {{ interfaceResultItem.everleadContact.email }}
                            </div>
                        </div>    
                    </div>
                </div>

                <div *ngIf="showAPList == false && previewDataArray != null" class="maxHeight">
                    <div class="enrichtmentPreviewItemList">
                        <div class="enrichtmentPreviewItemListItem enrichtmentPreviewItemListItem__header">
                            <div class="enrichtmentPreviewItemListItem__enrich"></div>
                            <div class="enrichtmentPreviewItemListItem__name">
                                {{ 'DataInterface.Label.DataField' | texttransform }}
                            </div>
                            <div class="enrichtmentPreviewItemListItem__value">
                                {{ 'DataInterface.Label.FromDataInterface' | texttransform }}
                            </div>
                            <div class="enrichtmentPreviewItemListItem__value">
                                {{ 'DataInterface.Label.CurrentValue' | texttransform }}
                            </div>
                        </div>
                        <div *ngFor="let enrichmentPreviewValue of enrichmentPreviewTableValues"
                            class="enrichtmentPreviewItemListItem everleadTableRow">
                            <div class="enrichtmentPreviewItemListItem__enrich">
                                <input type="checkbox" [(ngModel)]="enrichmentPreviewValue[3]">
                            </div>
                            <div class="enrichtmentPreviewItemListItem__name">{{ enrichmentPreviewValue[2] | texttransform }}</div>
                            <div class="enrichtmentPreviewItemListItem__value">{{ previewDataArray[enrichmentPreviewValue[0]] }}</div>
                            <div class="enrichtmentPreviewItemListItem__value">
                                <span *ngIf="currentValues != null">{{ currentValues[enrichmentPreviewValue[1]] }}</span>
                            </div>
                        </div>
                        <div class="enrichtmentPreviewItemMinibuttons">
                            <app-main-button (onClick)="selectAll()" label="DataInterface.Label.SelectAll"
                                type="borderSmall"></app-main-button>
                            <app-main-button (onClick)="removeAll()" label="DataInterface.Label.RemoveAll"
                                type="borderSmall"></app-main-button>
                        </div>
                        <div class="enrichtmentPreviewItemButtons">                            
                            <app-main-button (onClick)="backToList()" label="General._.Back"></app-main-button>
                            <app-main-button (onClick)="createContactForAccount()" label="DataInterface.Label.CreateContactForAccount"></app-main-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>