<div *ngIf="type ==='processState'" class="uiDropdown">
    <div class="uiDropdown__button" [ngxTippy]="uidropdowncontent" [tippyProps]="uicontentprops">
            <div class="uiDropdown__value">
                <app-status
                *ngIf="selectedValue" 
                [blue]="selectedValue.defaultName === 'closed'"
                [orange]="selectedValue.defaultName === 'in process'"
                [green]="selectedValue.defaultName === 'open'"
                [width]="15"
                [height]="15"></app-status>
                <span *ngIf="selectedValue" [ngClass]="{ smallFont: smallFont }"> {{ commonService.getTranslationValueFromArray(selectedValue) | texttransform  }}</span>
            </div>

        <app-jj-icons type="caret-down" [width]="15" [height]="15" color="secondary"></app-jj-icons>
    </div>

    <div class="uiDropdown__content" #uidropdowncontent>
        <div *ngFor="let option of options" class="uiDropdown__field" (click)="valueChanged(option)">
            <app-status 
            [blue]="option.defaultName === 'closed'"
            [orange]="option.defaultName === 'in process'"
            [green]="option.defaultName === 'open'"
            [width]="15"
            [height]="15"
            ></app-status>
            <span>{{ commonService.getTranslationValueFromArray(option) | texttransform  }}</span>
        </div>
    </div>
</div>

<div *ngIf="type ==='tab'" class="uiDropdownTab">
    <div class="uiDropdown__button" [ngxTippy]="uidropdowncontent" [tippyProps]="uicontentprops">
            <div class="uiDropdown__value">
                <app-tab [active]="selectedValue" [svg]="svg ? svg : selectedTab.svg" [label]="selectedValue.nameTranslationTokenId ? selectedValue.nameTranslationTokenId : selectedValue.defaultName"></app-tab>
            </div>

        <app-jj-icons type="caret-down" [width]="15" [height]="15" color="secondary"></app-jj-icons>
    </div>

    <div class="uiDropdown__content" #uidropdowncontent>
        <div *ngFor="let option of options" class="uiDropdown__field" (click)="valueChanged(option)">
            <app-tab [svg]="svg ? svg : option.svg"[label]="option.nameTranslationTokenId ? option.nameTranslationTokenId : option.defaultName"></app-tab>
        </div>
    </div>
</div>


<div *ngIf="type ==='sortHeaderLookUpTable'" class="uiDropdown">
    <div class="uiDropdown__button" [ngxTippy]="uidropdowncontent" [tippyProps]="uicontentprops">
        <div class="uiDropdown__value" *ngIf="selectedValue">
                <!-- <span *ngIf="!selectedValue.color">{{selectedValue[bind]}}</span> -->
                <app-status
                *ngIf="selectedValue.color && !selectedValue.placeholder"
                [color]="selectedValue.color" 
                [width]="15"
                [height]="15"></app-status>
                <span [ngClass]="{ 'uiDropdown__placeholder': selectedValue.placeholder, 'uiDropdown__value__label':  !selectedValue.placeholder }">{{ commonService.getTranslationValueFromArray(selectedValue) | texttransform  }}</span>
        </div>

        <app-jj-icons type="caret-down" [width]="15" [height]="15" color="secondary"></app-jj-icons>
    </div>

    <div class="uiDropdown__content" #uidropdowncontent>
        <div *ngFor="let option of options" class="uiDropdown__field" (click)="valueChanged(option)">
            <app-status
            *ngIf="option.color" 
            [color]="option.color"
            [width]="15"
            [height]="15"
            [noLabelColor]="true"
            [label]="commonService.getTranslationValueFromArray(option)"
            ></app-status>
            <span *ngIf="!option.color" class="uiDropdown__placeholder">{{ commonService.getTranslationValueFromArray(option) | texttransform  }}</span>
        </div>
    </div>
</div>
