<div [ngClass]="{filterAccordion: !noDropdown, noFilterAccordion: !noDropdown}">
    <div *ngIf="!noDropdown" class="filterAccordion__parameterContainer"(click)="togglePanel()">
        <span *ngIf="title" class="filterAccordion__parameterContainer__divider" [style.backgroundColor]="backgroundColor">
            {{ title }} 
            <app-jj-icons [type]="isOpen ? 'caret-down' : 'caret-up'" [width]="15" [height]="15"></app-jj-icons>
        </span>
   </div>
    <div *ngIf="isOpen" class="filterAccordion__body">
        <ng-content></ng-content>
    </div>
</div>