<div *ngIf="applicationInfoService.entities.Item(entityId).data != null" class="booking__wrapper">
<!--     <div [title]="applicationInfoService.entities.Item(entityId).data | json">TESTING</div> -->
    <app-booking-image [result]="microCampaignToStart" class="booking__page"
        *ngIf="microCampaignToStart != null && page == 1"></app-booking-image>

    <div class="booking__row booking__page" [ngClass]="{ hideInDom: page != 1 || microCampaignToStart != null }">
        <div class="booking__row__general">
            <div class="row">
                <div id="booking_taskcreationguid" class="col-12 newTaskTaskCountContainer"
                    *ngIf="createTaskList?.length > 0">
                    <div class="sectionDecorator">{{ createTaskCount }} {{ 'CreateTask.Label.TaskWillBeCreated' |
                        texttransform }}</div>
                    <app-text-input id="bookingField_0" [label]="'Task.Label.CreationGuidDescription' | texttransform"
                        [(ngModel)]="taskCreationGuid"></app-text-input>
                </div>

                <div class="col-12" *ngIf="showCampaignDropdown">
                    <app-select-input [(ngModel)]="applicationInfoService.entities.Item(entityId).data.campaignId"
                        [label]="'Task.Label.Campaign'" bind="id" id="bookingField_1" [disabled]="!canEdit('campaign')" 
                        [options]="filterArrays(campaigns, 'campaigns')"
                        display="displayName"></app-select-input>

                </div>

                <div class="col-12" *ngIf="controlMode != 2">
                    <app-select-input id="bookingField_2" [label]="'Contact.Label.Header'"
                        [options]="filterArrays(contacts, 'contacts')" bind="id" display="displayName"
                        [(ngModel)]="applicationInfoService.entities.Item(entityId).data.contactId"
                        displayMultiValue="firstName;lastName"
                        [disabled]="!canEdit('contact')"></app-select-input>
                </div>

                <!-- workflows -->
                <div class="col-12" id="booking_process"
                    *ngIf="applicationInfoService.applicationSettings['isDemoproject'] !='true' && showProcessDropdown">

                    <app-select-input id="bookingField_3" label="Task.Label.Process"
                        [(ngModel)]="applicationInfoService.entities.Item(entityId).data.workflowId"
                        (valueChange)="getWorkflowSteps(applicationInfoService.entities.Item(entityId).data.workflowId, true)"
                        [disabled]="!canEdit('workflow')" [options]="filterArrays(workflows, 'workflows')" bind="id"
                        display="displayName"></app-select-input>
                </div>

                <div class="col-12 noStepAvailableDiv"
                    *ngIf="(steps?.length == 0 || workflows?.length == 0) && applicationInfoService.entities.Item(entityId).data.workflowId">
                    <label class="noStepAvailableLabel">
                        <app-jj-icons type="info" [width]="20" [height]="20"></app-jj-icons>
                        {{ 'Task.Label.NoStepsAvailable' | texttransform }}
                    </label>
                </div>


                <!-- steps -->

                <span *ngIf="steps?.length > 0 && workflows?.length > 0">
                    <div class="col-12">
                        <app-select-input id="bookingField_4" label="Task.Label.Step"
                            [(ngModel)]="applicationInfoService.entities.Item(entityId).data.stepId"
                            (valueChange)="getWorkflowStepResults(applicationInfoService.entities.Item(entityId).data.stepId)"
                            [disabled]="!canEdit('step')" bind="id" display="displayName"
                            [options]="filterArrays(steps ,'steps')"></app-select-input>

                    </div>

                    <div class="col-12" *ngIf="controlMode != 2">
                        <app-select-input id="bookingField_5" label="Task.Label.Result" [disabled]="!canEdit('result')"
                            [(ngModel)]="applicationInfoService.entities.Item(entityId).data.resultId"
                            (valueChange)="resultChanged(applicationInfoService.entities.Item(entityId).data.resultId)"
                            bind="id" display="displayName"
                            [options]="filterArrays(results, 'results')"></app-select-input>

                    </div>

                    <div class="col-12" id="opportunitySelector"
                        *ngIf="opportunityService.checkIfResultRequiresOpportunity(applicationInfoService.entities.Item(entityId).data.resultId)">
                        <div id="opportunitySelector"
                            *ngIf="opportunityService.checkIfResultRequiresOpportunity(applicationInfoService.entities.Item(entityId).data.resultId)">
                            <label class="bookingEntityMemberLabel">
                                {{ 'Task.Label.Opportunity' | texttransform }}
                            </label>
                            <div class="opportunityBox">
                                <div class="opportunityBox__description">
                                    {{ 'Task.Label.OpportunityDescription' | texttransform }}
                                </div>
                                <div class="opportunityBox__createOpportunity">
                                    <app-main-button type="borderSmall" (onClick)="editOpportunity()" svg="editor"
                                        [label]="'Task.Label.EditOpportunity'"></app-main-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div id="bookingFormBookingPreview" class="bookingFormBookingPreview"
                            *ngIf="bookingPreview != null && applicationInfoService.applicationSettings['bookingFormShowBookingPreview'] == 'true'">
                            <label class="bookingFormBookingPreview__header">{{ 'TaskBooking.Preview.Header' |
                                texttransform }}:</label>

                            <div *ngIf="selectedResult?.emailTemplateId != null"
                                class="bookingFormBookingPreview__email">

                                <app-jj-icons color="primaryLight" type="envelope" width="20" [height]="20"
                                    [tippy]="'Timeline.Label.ShowEmail' | texttransform"
                                    (click)="showMail()"></app-jj-icons>
                                {{ 'BookingPreview.Label.SendingEMail' | texttransform }}
                            </div>

                            <div *ngIf="bookingPreview.HasFollowUpTask == true">
                                {{ 'BookingPreview.Label.FollowupTaskWillbeCreated' | texttransform }}:
                                <span class="bookingFormBookingPreview__nextStep">{{ nextStep?.defaultName }}</span>
                            </div>

                            <div *ngIf="bookingPreview.HasFollowUpTask == false">
                                {{ 'BookingPreview.Label.ChainEndsHere' | texttransform }}
                            </div>
                            <div *ngIf="bookingPreview.LeadStatus != null">
                                {{ 'BookingPreview.Label.Leadstatus' | texttransform }}: <span
                                    class="bookingFormBookingPreview__leadstate">{{ bookingPreview.LeadStatus }}</span>
                            </div>
                            <div *ngIf="selectedResult?.qmUserGroupId != null">
                                {{ 'BookingPreview.Label.QMRequired' | texttransform }}
                            </div>

                            <div
                                *ngIf="applicationInfoService.entities.Item(entityId).data.isNotifyFollowUpOwner == true">
                                {{ 'BookingPreview.Label.NotifyOwnerByEMail' | texttransform }}
                            </div>

                        </div>
                    </div>
                </span>


                <div class="col-12">
                    <app-custom-subresult [controlDefinition]="controlDefinition"
                        [externalDataSource]="externalDataSource">
                    </app-custom-subresult>
                    <div [innerHTML]="innerHTMLAdd2" class=""></div>
                </div>

                <div class="col-12" *ngIf="additionalTaskInformation?.resultId">
                    <app-date-input id="bookingField_6" [label]="'Task.Label.ResultDate'"
                        parent="booking"
                        [disabled]="!canEdit('resultdate')"
                        [(ngModel)]="applicationInfoService.entities.Item(entityId).data.resultDate"></app-date-input>
                </div>

                <div class="col-12" *ngIf="controlMode == 2">
                    <app-select-input id="bookingField_7" [label]="'Task.Label.Owner'"
                        [(ngModel)]="applicationInfoService.entities.Item(entityId).data.followUpUserId" bind="id"
                        display="userName" [options]="users"></app-select-input>
                </div>

                <div class="col-12" *ngIf="controlMode == 2">
                    <app-date-input id="bookingField_8" [label]="'Task.Label.FollowUpDate'"
                        [(ngModel)]="applicationInfoService.entities.Item(entityId).data.followUpDate"
                        (dateChange)="checkFollowUpDate()"></app-date-input>
                </div>

                <span *ngIf="bookingPreview?.HasFollowUpTask == true">
                    <div class="col-12">
                        <app-select-input id="bookingField_9"
                            [(ngModel)]="applicationInfoService.entities.Item(entityId).data.followUpUserId"
                            [disabled]="!canEdit('owner')" [label]="'Task.Label.Owner'" [options]="users" bind="id"
                            display="userName"></app-select-input>
                    </div>

                    <div class="col-12 checkbox__container">
                        <div class="ckeckbox__container">
                            <app-checkbox id="bookingField_10" [label]="'Task.Label.Inform'"
                                [(ngModel)]="applicationInfoService.entities.Item(entityId).data.isNotifyFollowUpOwner"></app-checkbox>
                        </div>
                    </div>
                </span>

                <div class="col-12 checkbox__container" *ngIf="checkResultAttribute('isPersonalFollowup', true)">
                    <app-checkbox id="bookingField_11" [label]="'Task.Label.PersonalContact'"
                        [(ngModel)]="applicationInfoService.entities.Item(entityId).data.isPersonalFollowup"></app-checkbox>
                </div>

                <span *ngIf="controlMode != 2">
                    <div class="col-12">
                        <app-text-input id="bookingField_12" [label]="'Task.Label.Subject'"
                            [(ngModel)]="applicationInfoService.entities.Item(entityId).data.subject"></app-text-input>

                    </div>

                    <div class="col-12">
                        <app-text-area-input id="bookingField_13" 
                            *ngIf="aiReplacementText == null"
                            [fire]="true"
                            [label]="'Task.Label.Notes'"
                            [(ngModel)]="applicationInfoService.entities.Item(entityId).data.notes"
                            ></app-text-area-input>

                        <app-text-area-input id="bookingField_14" *ngIf="aiReplacementText != null"
                            [label]="'Task.Label.Notes'" [(ngModel)]="aiReplacementText"></app-text-area-input>

                    </div>

          <!--           <div class="col-12">
                        <div [title]="applicationInfoService.entities.Item(entityId).data | json">
                            {{ applicationInfoService.entities.Item(entityId).data.notes?.length | json }}
                           fire {{ applicationInfoService.entities.Item(entityId).data.notes?.length < 10 }}
                        </div> 

                    </div> -->

                    <div class="col-12"
                        *ngIf="applicationInfoService.applicationSettings['showBookingAITextTransform']=='true'">
                        <div *ngIf="aiGenerating == true" class="aiTextarea__message">{{
                            'TextArea.Label.AICalculating' | texttransform }}</div>
                        
                        <div class="aiTextarea" id="aiTextArea">
                            <app-main-button type="borderSmall" 
                                label="TextArea.Label.AITransform"
                                [disabled]="applicationInfoService.entities.Item(entityId).data.notes?.length < 10"
                                (onClick)="aiTransformText('notes')"
                                [tippy]="'TextArea.Label.AICalculatingDescription' | texttransform"
                                svg="info"></app-main-button>

                            <div *ngIf="aiReplacementText != null && aiGenerating == false" class="aiTextarea">
                                <app-main-button 
                                type="borderSmall" 
                                svg="seal-check"
                                label="TextArea.Label.Accept"
                                (onClick)="acceptAiText('notes')"></app-main-button>
                                <app-main-button label="TextArea.Label.Cancel" type="borderSmall" svg="x-circle" (onClick)="cancelAiText('notes')"></app-main-button>
                            </div>

                        </div>
                    </div>
                </span>


                <div class="col-12">
                    <div [innerHTML]="innerHTMLAdd3" class=""></div>
                </div>

                <div class="col-12" *ngIf="bookingPreview != null">
                    <div class="Maxwidth bookingRow bookingPreview">
                        <div *ngIf="bookingPreview.HasFollowUpTask == true">
                            {{ 'BookingPreview.Label.FollowupTaskWillbeCreated' | texttransform }}
                        </div>
                        <div *ngIf="bookingPreview.HasFollowUpTask == false">
                            {{ 'BookingPreview.Label.ChainEndsHere' | texttransform }}
                        </div>
                        <div *ngIf="bookingPreview.LeadStatus != null">
                            {{ 'BookingPreview.Label.Leadstatus' | texttransform }}: {{ bookingPreview.LeadStatus }}
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div [innerHTML]="innerHTMLAdd4" class=""></div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-12 booking__row__additional" *ngIf="controlMode == 2" id="bookingPreview">
                <div class="bookingPreviewContainer__wrapper">
                    <div *ngIf="innerHTMLEMailPreview != null" class="sectionDecorator">
                        {{ 'TaskBooking.EMailPreview.Header' | texttransform }}
                    </div>
                    <div class="bookingPreviewContainer" [innerHTML]="innerHTMLEMailPreview"></div>
                </div>

                <app-booking-preview></app-booking-preview>
                <div [innerHTML]="innerHTMLAdd1" class=""></div>
            </div>
        </div>
    </div>


    <div *ngIf="page == 2" class="booking__page" id="bookingEmailEditor">
      <app-custom-emaileditor [templateId]=selectedResult.emailTemplateId [taskEntityId]=entityId
                    [contactId]="applicationInfoService.entities.Item(entityId).data.contactId"></app-custom-emaileditor>
       
    </div>

    <div id="bookingFooterRow" class="bookingFooterRow">
        <div class="bookingFooterRow__buttonContainer">

            <app-main-button class="bookingFooterRow__button" id="booking1" type="border" [svg]="'arrow-right'"
                (onClick)="bookTask(true)"
                [label]="this.checkResultAttribute('isEditableEmail', true) ? 'CreateTask.Label.EditEMail' : 'CreateTask.Label.BookTask'"
                *ngIf="(controlMode == 1 || controlMode == 4) && page == 1"
                [disabled]="!canBookTask()"></app-main-button>


            <app-main-button class="bookingFooterRow__button" type="border" id="booking2"
                *ngIf="controlMode == 2 && (bookingMailMode != 2 && bookingMailMode != 3)" [disabled]="!canCreateTask()"
                (onClick)="createTask()" label="CreateTask.Label.CreateTask" svg="plus"></app-main-button>

            <app-main-button class="bookingFooterRow__button" (onClick)="createMailShot()" id="booking2"
                type="border" *ngIf="controlMode == 2 && (bookingMailMode == 2 || bookingMailMode == 3)"
                [disabled]="!canCreateTask() || taskCreationGuid.length < 3"
                label="CreateTask.Label.CreateMailshot" svg="plus"></app-main-button>

            <app-main-button class="bookingFooterRow__button" id="booking3" type="border"
                *ngIf="controlMode == 3 && page == 1" [disabled]="!canBookTask()"
                label="CreateTask.Label.UpdateTask" (onClick)="updateTask()"
                svg="arrows-counter-clockwise"></app-main-button>

            <app-main-button class="bookingFooterRow__button" id="booking4"
                *ngIf="(controlMode == 1 || controlMode == 4)  && page == 2 && isStartingMicroCampaignShowPreview == false" type="border"
                label="CreateTask.Label.Back" svg="arrow-left" (onClick)="changePage(1)">
            </app-main-button>

            <app-main-button class="bookingFooterRow__button" id="booking5" type="border"
                *ngIf="(controlMode == 1 || controlMode == 4)  && page == 2" [disabled]="!canBookTask()"
                (onClick)="bookTask()"
                [label]="this.microCampaignToStart ? 'MicroCampaign.Label.StartCampaign' : 'CreateTask.Label.BookTask'">
            </app-main-button>

            <app-main-button class="bookingFooterRow__button" id="booking6" type="border"
                (onClick)="showSendMailContainer = true" *ngIf="(bookingMailMode == 2 || bookingMailMode == 3) && selectedTemplate != null"
                label="CreateTask.Label.TestEMail" svg="envelope"></app-main-button>

            <app-main-button type="border" class="bookingFooterRow__button" (onClick)="deleteTask()"
                *ngIf="canDeleteTask() && userService.hasPermission(deleteTaskUserRightName)"
                label="CreateTask.Label.DeleteTask" svg="trash"></app-main-button>
        </div>
    </div>


    <div *ngIf="showSendMailContainer == true" id="sendMailContainer">
        <div class="sendMailContainer__background"></div>
        <div class="sendMailContainer">
            <div class="row">
                <div class="col-12">
                    <div class="sendMailContainer__header">
                        <ng-container>{{ 'CreateTask.Label.TestEMail' | texttransform }}</ng-container>
                        <app-jj-icons type="x-circle" (click)="showSendMailContainer = false"
                            color="secondary"></app-jj-icons>
                    </div>
                </div>
                <div class="col-12">
                    <app-select-input id="bookingField_15" label='Task.Label.TestEMailReceiver'
                        [(ngModel)]="testMailUser" (valueChange)="testMailAdress = testMailUser.email"
                        [options]="user" bind="'userName" display="userName"
                        [complex]="true"></app-select-input>
                </div>

                <div class="col-12">
                    <app-text-input label="Task.Label.TestEMailReceiverAdress"
                        [(ngModel)]="testMailAdress"></app-text-input>
                </div>

                <div class="col-12">
                    <div class="sendMailContainer__infoContainer">
                        <label class="sendMailContainer__infoLabel">{{
                            'Task.Label.FirstContactWillBePickedForTestMail' | texttransform }}:</label>
                        <br>
                        <label class="sendMailContainer__infoLabel everleadCoral">{{ testMailContact?.firstName }}
                            {{ testMailContact?.lastName }}</label>
                    </div>
                </div>

                <div class="col-12">
                    <app-main-button label="CreateTask.Label.TestEMail" type="border" (onClick)="sendEmail()"
                        svg="paper-plane-tilt"></app-main-button>

                </div>
            </div>

        </div>
    </div>
</div>