<div class="questionaireQuestion" *ngIf="subQuestionDeleted == false">
    <div class="">
        <div class="questionaireAnswer questionaireAnswer__topline">
            <div class="questionaireType"></div>
            <div class="questionaireAnswer__defaultName">{{ 'QuestionaireDesigner.Label.DefaultName' | texttransform }}</div>
            <div class="questionaireAnswer__type">{{ 'QuestionaireDesigner.Label.Type' | texttransform }}</div>
            <div class="questionaireAnswer__subQuestions"></div>
        </div>
        <div class="questionaireQuestion__line">
            <div class="questionaireType questionaireType__question">
                <span *ngIf="parent!=null">{{ 'QuestionaireDesigner.Label.Question' | texttransform }}</span>
                <span *ngIf="parent==null">{{ 'QuestionaireDesigner.Label.SubQuestion' | texttransform }}</span>
            </div>
            <div class="questionaireQuestion__defaultName">
                <input [(ngModel)]="question.defaultName" class="maxWidth" (change)="questionaireControlService.updateQuestion(question)">
            </div>    
            <div class="questionaireQuestion__type">
                <app-checkbox label="QuestionaireDesigner.Label.IsMultiselect" [(ngModel)]="question.isMultiselect" (valueChange)="questionaireControlService.updateQuestion(question)"></app-checkbox>
            </div>    
            <div class="questionaireQuestion__newanswers">                
                <app-jj-icons type="trash" [tippy]="'General.Label.DeleteItem' | texttransform" [width]="20" [height]="20" (click)="deleteQuestion(question)"></app-jj-icons>
            </div>                
        </div>
    </div>
    <div class="questionaireQuestion__answerwrapper" *ngIf="question.type != 3">
        <div class="questionaireQuestion__answers">               
            <div cdkDropList [cdkDropListData]="question.answers" (cdkDropListDropped)="moveInList($event, '0')">
                <div *ngFor="let answer of question.answers" cdkDrag [cdkDragData]="answer" data-html="true" [ngClass]="{ hideInDom: answer.isDeleted }">
                    <div class="questionaireAnswer">
                        <div class="questionaireType">{{ 'QuestionaireDesigner.Label.Answer' | texttransform }}</div>
                        <div class="questionaireAnswer__defaultName" (click)="$event.stopPropagation()">
                            <input class="maxWidth" [(ngModel)]="answer.defaultName" (change)="questionaireControlService.updateAnswer(answer)">
                        </div>
                        <div class="questionaireAnswer__type">
                            <select [(ngModel)]="answer.typeLookupId" (change)="questionaireControlService.updateAnswer(answer)">
                                <option [ngValue]=null></option>
                                <option [ngValue]=typeLookup.id *ngFor="let typeLookup of typeLookups">{{ commonService.getTranslationValueFromArray(typeLookup) }}</option>
                            </select>
                        </div>
                        <div class="questionaireAnswer__subQuestions">
                            <app-jj-icons type="plus" [tippy]="'QuestionaireDesigner.Label.NewSubquestion' | texttransform" [width]="20" [height]="20" (click)="addQuestion(answer)"></app-jj-icons>
                            <app-jj-icons type="trash" [tippy]="'General.Label.DeleteItem' | texttransform" [width]="20" [height]="20" (click)="deleteAnswer(answer)"></app-jj-icons>
                            <app-jj-icons type="equals" [width]="20" [height]="20" color="secondary"></app-jj-icons>
                        </div>
                    </div>
                    <div *ngIf="answer.childQuestions.length > 0" class="subQuestions">
                        <div cdkDropList [cdkDropListData]="answer.childQuestions" (cdkDropListDropped)="moveInList($event, '0')">
                            <div *ngFor="let question of answer.childQuestions" cdkDrag [cdkDragData]="questionitem" data-html="true" [ngClass]="{
                                hideInDom: question.isDeleted
                            }">
                                <app-questionaire-designer-questioncontrol [question]=question [parent]="answer.questions"></app-questionaire-designer-questioncontrol>
                            </div>                        
                        </div>                                   
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="questionaireButtonLine">
        <app-main-button type="borderSmall" svg="plus" (click)="addAnswer()" [label]="'QuestionaireDesigner.Label.AddAnswer' | texttransform"></app-main-button>
    </div>
</div>