import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { Subscription } from 'rxjs';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { WizardMode, WizardService } from '../../wizard/wizard.service';

@Component({
  selector: 'app-leadstate',
  templateUrl: './leadstate.component.html',
  styleUrls: ['./leadstate.component.scss']
})
export class LeadstateComponent implements OnInit, OnDestroy {
  translateTokenPopupConfirmTranslationEventSubscription: Subscription = new Subscription();
  wizardFinishedEventSubscription: Subscription = new Subscription();
  leadStateTypes = []
  showOnlyActive = true;
  newMode = true;
  filterString = '';
  leadstates = [];
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['defaultName', 'order', 'isActive', 'isNetto', 'type', 'isTableCreation', 'isLeadQuote', 'isAutomatic', 'isAutomaticStart', 'validXDays'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  loadingData = false;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private messagingService: MessagingService,
    public commonService: CommonService,
    public methodService: MethodService,
    public applicationInfoService: ApplicationInfoService,
    public dragdropService: DragdropService,
    public jjtranslationService: JjtranslationService,
    public userService: UserService,
    private wizardService: WizardService,
    private uiService: UiService
  ) { }


  handle(event){
    //console.log(event)
  }

  ngOnInit() {
    this.getLeadStates();
    this.getLeadStateTypes();
    this.wizardFinishedEventSubscription = this.wizardService.wizardFinishedEvent.subscribe(() => {
      this.getLeadStates();
    });

    if (!this.userService.hasPermission('CanUseNonSelfServiceNaviagionItem')) {
      this.displayedColumns = ['defaultName', 'order', 'isActive'];
    }
  }

  ngOnDestroy() {
    if (this.translateTokenPopupConfirmTranslationEventSubscription) {
      this.translateTokenPopupConfirmTranslationEventSubscription.unsubscribe();
    }
    if (this.wizardFinishedEventSubscription) {
      this.wizardFinishedEventSubscription.unsubscribe();
    }
  }

  getLeadStates() {
    if (!this.userService.hasPermission('CanReadLookupLeadStatus')) {
      return;
    }
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(926)
    .then(executeExternalDataSourceResult => {
      this.leadstates = executeExternalDataSourceResult.filter(leadstate => leadstate.lookupTable.projectId != null);
      this.dataSource = new MatTableDataSource(executeExternalDataSourceResult);
      this.loadingData = false;
    })
    .catch(error => {
      this.loadingData = false;
      this.messagingService.showDefaultError('getLeadStates', error, false);
    });
  }

  showWizard(leadstate) {
    if (this.userService.hasPermission('CanUpdateLookupLeadStatus')) {
      this.wizardService.showWizard(WizardMode.LeadState, leadstate);
    }
  }

  createNewWizardLeadState() {
    const item = [];
    item['lookupTable'] = [];
    item['isUnproductive'] = false;
    item['isProductive'] = false;
    item['lookupTable']['defaultName'] = 'LeadState';
    item['lookupTable']['isActive'] = true;
    item['lookupTable']['projectId'] = this.applicationInfoService.projectID;
    item['lookupTable']['definitionId'] = 1;
    this.wizardService.showWizard(WizardMode.LeadState, item);
  }

  createNewLeadState(leadState: any, index: any): Promise<any> {
    return new Promise((createNewLeadStateResolve, createNewLeadStateReject) => {

    });
  }

  getLeadStateTypes() {
    this.leadStateTypes = [];
    this.externaldatasourceService.executeExternalDataSource(387, ['70'])
    .then(getLeadStateTypesResult => {
      this.leadStateTypes = getLeadStateTypesResult;
    })
  }

  getTableHeight() {
    let tableSize = Number(this.uiService.getDesignSpanPosition('projectSettingsDesignSpan', 40));
    return tableSize + 'px';
  }

  saveLeadState(element) {
    if (!this.userService.hasPermission('CanUpdateLookupLeadStatus')) {
      return;
    }
    this.externaldatasourceService.executeExternalDataSource(215, [this.commonService.getModifyArrayBody(element.lookupTable, ['length'])])
    .then(() => {
      this.externaldatasourceService.executeExternalDataSource(522, [
        this.commonService.getModifyArrayBody(element, ['lookupTable', 'length'])
      ]);
    });
  }

  itemIsFiltered(row) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(row.lookupTable.defaultName, this.filterString)) {
     returnValue = false
    }
    if (this.showOnlyActive && !row.lookupTable.isActive) {
      returnValue = true;
    }
    return returnValue;
  }
}
