import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionaireControlService {
  questionaireAnswers = [];

  projectQuestionaire = null;
  projectQuestionaires = null;
  selectedQuestionaire = null;
  filterString = '';
  showOnylyActive: true;
  // questionaireLookupId = 8061;
  // answerLookupId = 8062;
  questionaireLookupId = 8066;
  answerLookupId = 8062;

  constructor (
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    public loaderService: LoaderService,
    private messagingService: MessagingService,
    private methodService: MethodService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  getQuestionaires(): Promise<any> {
    return new Promise((getQuestionairesResolve, getQuestionairesReject) => {
      this.externaldatasourceService.executeExternalDataSource(869)
      .then(getQuestionairesResult => {
        this.projectQuestionaires = getQuestionairesResult;
        getQuestionairesResolve(getQuestionairesResult);
      }).catch(() => { getQuestionairesReject(null); });
    });
  }

  //#region  Questionnaire CRUD
  createQuestionaire(preparedQuestionnaire = null): Promise<any> {  
    return new Promise((createQuestionairesResolve, createQuestionairesReject) => {
      let questionnaire = {
        defaultName: 'new Questionnaire',
        projectId: this.applicationInfoService.projectID,
        entityId: 1
      }
      if (preparedQuestionnaire != null) {
        questionnaire = preparedQuestionnaire;
      }
      this.externaldatasourceService.executeExternalDataSource(866, [
        this.commonService.getModifyArrayBody(questionnaire, [])
      ])
      .then(createQuestionaireResult => {
        questionnaire['id'] = createQuestionaireResult.id;
        questionnaire['questions'] = [];
        this.projectQuestionaires.push(questionnaire);
        createQuestionairesResolve(questionnaire);
      }).catch(() => { createQuestionairesReject(null); });
    });
  }

  updateQuestionaire(questionaire) {
    this.externaldatasourceService.executeExternalDataSource(867, [this.commonService.getModifyArrayBody(questionaire, [])]);
  }

  deleteQuestionaire(questionaire): Promise<any> {
    return new Promise((deleteQuestionaireResolve, deleteQuestionaireReject) => {
      this.externaldatasourceService.executeExternalDataSource(868, [questionaire.id]).then(() => {
        this.commonService.removeItemFromArray(this.projectQuestionaires, questionaire);
        deleteQuestionaireResolve(null);
      }).catch(() => { deleteQuestionaireReject(null); });
    });
  }
  //#endregion
  
  //#region Question CRUD
  getQuestionsById(typeId, refId): Promise<any> { 
    return new Promise((getQuestionsByIdResolve, getQuestionsByIdReject) => {
      this.externaldatasourceService.executeExternalDataSource(870, [refId, typeId])
      .then(getQuestionsByIdResult => {
        getQuestionsByIdResolve(getQuestionsByIdResult);
      }).catch(() => {
        getQuestionsByIdReject(null);
      });
    });
  }

  addQuestion(parentAnswerId = null): Promise<any> {
    return new Promise((addQuestionResolve, addQuestionReject) => {
      this.loaderService.display(true);
      const question = {
        defaultName: this.axivasTranslateService.getTranslationTextForToken('QuestionaireDesigner.Label.NewQuestion'),
        lookupRefTypeId: this.getQuestionTypeLookupId(parentAnswerId),
        parentAnswerId : parentAnswerId,
        refId: this.selectedQuestionaire.id,
        order: this.selectedQuestionaire.questions.length + 1,
        isMandatory: false,
        isMultiselect: false
      };
      if (parentAnswerId != null) {
        question.refId = parentAnswerId;
      }
      this.externaldatasourceService.executeExternalDataSource(156, [this.commonService.getModifyArrayBody(question, ['answers'])])
      .then(addQuestionResult => {
        question['id'] = addQuestionResult.id;
        question['answers'] = this.axivasTranslateService.getTranslationTextForToken('QuestionaireDesigner.Label.FreeText');
        addQuestionResolve(question);
        this.loaderService.display(false);
      })
      .catch(() => {
        this.loaderService.display(false);
        addQuestionReject(null);
      });
    });
  }

  updateQuestion(question) {
    this.externaldatasourceService.executeExternalDataSource(155, [this.commonService.getModifyArrayBody(question, ['answers'])])
  }

  deleteQuestion(question): Promise<any> {
    return new Promise((deleteQuestionResolve, deleteQuestionReject) => {
      this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, [
        'Questionaire.DeleteQuestion.Header',
        'Questionaire.DeleteQuestion.Message'
      ]).then(() => {
        this.externaldatasourceService.executeExternalDataSource(264, [question.id])
        .then(() => { deleteQuestionResolve(null); })
        .catch(() => { deleteQuestionReject(null); });  
      });
    });
  }
  //#endregion

  //#region Answer CRUD
  addFreeTextAnswer() {

  }

  addAnswer(question, freeText = false): Promise<any> {
    return new Promise((addAnswerResolve, addAnswerReject) => {
      const answer = {
        questionId: question.id,
        order: question.answers.length + 1,
        defaultName: 'Neue Antwort',
        nameTranslationTokenId: null,
        descriptionTranslationTokenId: null
      };
      if (freeText == true) {
        answer['typeLookupId'] = 1907;
        answer['defaultName'] = 'Freitext';
      }
      this.externaldatasourceService.executeExternalDataSource(157, [this.commonService.getModifyArrayBody(answer, [])])
      .then(addAnswerResult => {
        answer['id'] = addAnswerResult.id;
        addAnswerResolve(answer);
      }).catch(() => { addAnswerReject(null); });
    });
  }

  deleteAnswer(answer): Promise<any> {
    return new Promise((deleteAnswerResolve, deleteAnswerReject) => {
        this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, [
          'Questionaire.DeleteAnswer.Header',
          'Questionaire.DeleteAnswer.Message'
        ]).then(() => {
        this.externaldatasourceService.executeExternalDataSource(263, [answer.id])
        .then(() => { deleteAnswerResolve(null);
        }).catch(() => { deleteAnswerReject(null); });
      });
    });
  }

  updateAnswer(answer) {
    this.externaldatasourceService.executeExternalDataSource(158, [this.commonService.getModifyArrayBody(answer, ['questions', 'childQuestions'])]);
  }
  //#endregion

  getAnswersByQuestion(question): Promise<any> {
    return new Promise((getAnswersByQuestionResolve, getAnswersByQuestionrReject) => {
      this.externaldatasourceService.executeExternalDataSource(872, [question.id])
      .then(getAnswersByQuestionResult => { getAnswersByQuestionResolve(getAnswersByQuestionResult); })
      .catch(() => { getAnswersByQuestionrReject(null); });
    });
  }

  getAnwsersForQuestionaire(questionaire): Promise<any> {
    return new Promise((getAnwsersForQuestionaireResolve, getAnwsersForQuestionaireReject) => {  
      let refId = this.getRefId(questionaire);
      if (refId == null) {
        return;
      }
      this.externaldatasourceService.executeExternalDataSource(862, [
        Number(this.applicationInfoService.applicationSettings['createQuestionAnswerLookupRefTypeId']),
        refId
      ]).then(getAnwsersForQuestionaireResult => {
        this.questionaireAnswers[questionaire.id] = getAnwsersForQuestionaireResult;
        getAnwsersForQuestionaireResolve(getAnwsersForQuestionaireResult);
      }).catch(error => getAnwsersForQuestionaireReject(error));
    });
  }

  getQuestionTypeLookupId(parentAnswerId) {
    let lookupTypeId = null;
    if (parentAnswerId) {
      lookupTypeId = this.answerLookupId;
    } else {
      lookupTypeId = this.questionaireLookupId;
    }
    return lookupTypeId;
  }

  createQuestionAnswer(question, answer, refId): Promise<any> {
    return new Promise((createQuestionAnswerResolve, createQuestionAnswerReject) => {
      const questionAnswer = {
        questionId: question.id,
        answerId: answer.answerId,
        answerText: answer.answerText,
        answerInt: answer.answerInt,
        refId: refId,
        lookupRefTypeId: Number(this.applicationInfoService.applicationSettings['createQuestionAnswerLookupRefTypeId'])
      }
      this.externaldatasourceService.executeExternalDataSource(863, [
        this.commonService.getModifyArrayBody(questionAnswer, [])
      ]).then(createQuestionAnswerresult => {
        createQuestionAnswerResolve(createQuestionAnswerresult);
      }).catch(() => {
        createQuestionAnswerReject(null);
      });
    });
  }

  updateQuestionAnswer(answer): Promise<any> {
    return new Promise((updateQuestionAnswerResolve, updateQuestionAnswerReject) => {
      const questionAnswer = {
        id: answer.id,
        answerId: answer.answerId,
        answerText: answer.answerText,
        answerInt: answer.answerInt
      }
      this.externaldatasourceService.executeExternalDataSource(864, [
        this.commonService.getModifyArrayBody(questionAnswer, [])
      ]).then(createQuestionAnswerresult => {
        updateQuestionAnswerResolve(createQuestionAnswerresult);
      }).catch(() => {
        updateQuestionAnswerReject(null);
      });
    });
  }

  deleteQuestionAnswer(answer): Promise<any> {
    return new Promise((deleteQuestionAnswerResolve, deleteQuestionAnswerReject) => {
      this.externaldatasourceService.executeExternalDataSource(865, [answer.id]).then(() => {
        deleteQuestionAnswerResolve(null);
      }).catch(() => {
        deleteQuestionAnswerReject(null);
      });
    });
  }

  getRefId(questionnaire) {
    let refId = null;
    switch(questionnaire.entityId) {
      case 1:
        if (this.applicationInfoService.currentAccount) {
          refId = this.applicationInfoService.currentAccount.id;
        }
        break;
      case 2:
        if (this.applicationInfoService.currentTask) {
          refId = this.applicationInfoService.currentTask.id;
        }
        break;
      case 4:
        if (this.applicationInfoService.currentContact) {
          refId = this.applicationInfoService.currentContact.id;
        }
        break;
    }
    return refId;
  }
}
