import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS } from '@angular/forms';
@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    },
/*     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    } */
  ]
})
export class TextInputComponent implements ControlValueAccessor, AfterContentInit{
  cdr: any;

  constructor(){}

  @Input() id: string| number | null = null ;
  @Input() label: string | number | any = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() type: string = 'text';
  @Input() fire: boolean | null = false;
  @Input() info: string | null = null;
  @Input() error: boolean | any = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() touched = new EventEmitter<void>();
  @Output() enter = new EventEmitter<void>();
  @Output() stoptyping = new EventEmitter<string>();
  @Output() key = new EventEmitter<string>();

  value: any = '';

  isOpen: boolean = false;

  private debounceTimer: any;

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
    //console.log('this.value', this.value)
  }


  ngAfterContentInit(): void {
      //console.log('fire', this.fire)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    // Handle setting the disabled state if needed
  }



  handleInput(value: any): void {
    if(!this.disabled){
      this.value = value;
        this.onChange(this.value);
        this.valueChange.emit(this.value)
        this.onTouched();
    }
  }

  handleFireInput(value){
    this.valueChange.emit(value);
    this.cdr.detectChanges(); 
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !this.disabled) {
      this.enter.emit()
    }
  }

  toggleType(){
    return this.isOpen ? 'text' : 'password'
  }
}
