<div class="defaultDashboardMailshotWrapper">
    <app-layout header="Mailshots">
        <app-select-input label="Mailshot" [(ngModel)]="selectedBookingBatchId" [options]="eventBookingBatches" 
            display="batchName" bind="id" (valueChange)="getEventBookingBatchData()"></app-select-input>

      
    
    <div class="defaultDashboardMailshotKPIs" *ngIf="kpis != null" (click)="getDashboardResults()">        
        <div class="defaultDashboardMailshotKPIs__boxes">
            <div *ngFor="let kpiItem of kpis" [ngClass]="{
                hideInDom: kpiItem.event_type == 'Failure' || kpiItem.event_type == 'Error' || kpiItem.event_type == 'Complaint'
            }" class="defaultDashboardMailshotKPIs__box">
                <div class="defaultDashboardMailshotKPIs__box__percentage">{{ kpiItem.percentage }}%</div>
                <div class="defaultDashboardMailshotKPIs__box__type">{{ kpiItem.translation | texttransform }}</div>
            </div>
        </div>
        <div class="defaultDashboardMailshotKPIs__charts">
            <div class="defaultDashboardMailshotKPIs__chart defaultDashboardMailshotKPIs__chartArrange" *ngIf="kpisChart != null">
                <app-bar-chart [height]="300" [chartData]="kpisChart" component="mailshotKPIChart" [controlid]="agentwvlChart"></app-bar-chart>
            </div>
            <div *ngIf="deviceDataPie != null" class="defaultDashboardMailshotKPIs__chart"
                [ngClass]="{ defaultDashboardMailshotKPIs__chartArrange: kpisChartArrange }">
                <app-echarts class="maxWidth"
                    id="#4567"
                    [chart]="'pie'"
                    [title]="'Mailshots.Label.Devices' | texttransform"
                    [chartData]="deviceDataPie"
                    [height]="175"                    
                ></app-echarts>
            </div>    
        </div> 

        <div class="defaultDashboardMailshot24hr" *ngIf="eventHoursChart != null" class="defaultDashboardMailshot24Hours">
            <app-echarts class="maxWidth" id="#mailshotChart" [createChartOnLoad]="false" [height]="400" [options]="eventHoursChart" ></app-echarts>
        </div>
        <div>
            <div>
                {{ selectedMailshotDetailList }}
                {{ mailshotDetailListOptions | json }}
                <app-select-input label="GeneralSettings.Setting.Customer" [(ngModel)]="selectedMailshotDetailList" 
                    [options]="mailshotDetailListOptions" display="defaultName" bind="id" 
                    (valueChange)="changeSelectedMailshotDetailList()"></app-select-input>
            </div>
        </div>
    </div>
    </app-layout>
</div>