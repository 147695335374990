<div class="myLastActivityRow">
    <div class="myLastActivity__two" (click)="loadContact()">
        <div class="myLastActivityCard">
            <div class="accountName ellipsis" *ngIf="activity._1_name1">{{activity._1_name1}}</div>
            <div class="contactName ellipsis" *ngIf="activity._4_firstname || activity._4_lastname">
                {{activity._4_firstname}} {{activity._4_lastname}}
            </div>
            <div class="contactAddress" *ngIf="activity._2_resultdate">{{ activity._2_resultdate | date:'medium' }}</div>            
        </div>
    </div>
    <div class="myLastActivity__three">
        {{ getResultTranslation(activity._2_resultid) | texttransform }}
    </div>
</div>