<!-- <label class="adminEntity__header">{{ 'AdminEntity.Label.ManageEntities' | texttransform }}</label> -->
<div *ngIf="featureNotFound == true" class="featureNotAvailableDiv">
    <app-features [fixFeatureId]="featureId"></app-features>
</div>



<div class="maxHeight" *ngIf="featureNotFound == false" >
    <div class="adminEntity">
        <div class="adminEntity__header">
            <app-component-header [header]="applicationInfoService?.breadCrumbText"></app-component-header>
            <div class="adminEntity__header__nav">
                <app-main-button
                type="small" 
                svg="plus" 
                label="AdminEntity.Label.CreateNewMember"
                (onClick)="createNewMember()"
                [disabled]="selectedEntity == null"
                ></app-main-button>
    
                <span *ngIf="applicationInfoService.user.isExternalUser == false && selectedEntity != null" class="maxMemberInfo">
                    ({{ getMaxEntityMemberText() }})
                </span>
    
                <app-main-button
                type="borderSmall"
                svg="arrows-counter-clockwise"
                (onClick)="refreshEntityCache()"
                label="AdminEntity.Label.RefreshDataEntityCache"></app-main-button>  
            </div>
        </div>
        <div class="adminEntity__inner">
            <div class="adminEntity__content">
                <div class="adminEntity__content__nav">

                    <div class="adminEntity__selectContainer">
                        <app-select-input
                        label="AdminEntity.Label.Layer"
                        [(ngModel)]="selectedEntity"
                        (valueChange)="changeEntity()"
                        [options]="entities"
                        display="displayName"
                        complex=true
                    ></app-select-input>

                    <!-- <div class="customTextControlWrapper">
                        <label class="customTextControlWrapper__label">{{ 'AdminEntity.Label.Layer' | texttransform }}</label>
                        <div class="customTextControlWrapper__valueWrapper">
                            <select class="customTextControlWrapper__dropdown" [(ngModel)]="selectedEntity" (change)="changeEntity()">
                                <option *ngFor="let entity of entities" [ngValue]=entity>
                                    {{ commonService.getNameTranslationOrDefaultName(entity, 'entityName') | texttransform }}</option>
                            </select>
                        </div>
                    </div> -->

    
                    <app-wizard-translations 
                    *ngIf="selectedEntity && applicationInfoService.isDeveloper" 
                    [exclude]="excludeEntity" 
                    [array]="selectedEntity" 
                    updateBaseArrayExternaldatasourceId=168 
                    mode="3"
                    projectSpecific=false></app-wizard-translations>
                    
                    <app-select-input
                        *ngIf="selectedEntity"
                        [(ngModel)]="viewMode"
                        [options]="[
                        { label:'AdminEntity.Label.ViewModeAll', value:'All'},
                        { label: 'AdminEntity.Label.ViewModeProjectSpecific', value: 'Specific'},
                        { label:'AdminEntity.Label.ViewModeUniversal', value: 'Universal'}]"
                        bind="value"
                        display="label"
                    ></app-select-input>

                    </div>

                <!-- todo new reusable filter needs to be tested -->
                <app-filter (keyUp)="filterString=$event"></app-filter>
    
                  <!--   <div class="adminEntity__filter">
                        <div class="filterWrapper">
                            <input [placeholder]="'General.Label.Search' | texttransform" [(ngModel)]="filterString">
                            <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                        </div>
                    </div> -->
    
                </div>
    
                <div *ngIf="selectedEntity" class="adminEntity__list">
                    <div class="adminEntity__list__header">
                        <label>{{ 'AdminEntity.Label.Member' | texttransform }}</label>
                        <span id="entityDesignSpan"></span>
                    </div>    
             
                    <div class="adminEntity__list__body">
                        <div *ngFor="let member of selectedEntity.entityMembers" [ngClass]="{ hideInDom: checkIfMemberNeedsToBeHidden(member) }" class="everleadTableRow">
                            <div class="adminEntity__list__row " (mouseenter)="highlighRow(member.id)" [ngClass]="{ adminEntity__list__highlight : member.id === highlightRowID }">
                                <div class="adminEntity__list__row__inner">
                                    <div class="maxWidth adminEntity__list__name">
                                        <span *ngIf="member.nameTranslationTokenId != null">
                                            {{ axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId) | texttransform }} 
                                        (</span>
                                        <!-- <span *ngIf="applicationInfoService?.isDeveloper">{{ member.id }}-</span> -->
                                        <span>{{ member.defaultName }}</span>
                                        <span *ngIf="member.nameTranslationTokenId != null">)</span>
                                    </div>
            
                                    <div class="adminEntity__list__icons">
                                        <app-jj-icons type="text-aa" [pointer]="false" *ngIf="member.memberType == 'TEXT'" [width]="20" [height]="20" color="black"></app-jj-icons>
                                        <app-jj-icons type="list-numbers" [pointer]="false" *ngIf="member.memberType == 'NUMERIC'" [width]="20" [height]="20" color="black"></app-jj-icons>
                                        <app-jj-icons type="calendar-dots" [pointer]="false" *ngIf="member.memberType == 'DATETIME'" [width]="20" [height]="20" color="black"></app-jj-icons>
                                        <app-jj-icons type="arrow-square-offset" [pointer]="false" *ngIf="member.memberType == 'BOOL'" [width]="30" [height]="30" color="black"></app-jj-icons>
                                    </div>
                                    <div class="adminEntity__list__editors">
                                        <app-wizard-translations [array]="member"  [svgW]="20" [svgH]="20" [exclude]="translationExcludes" updateBaseArrayExternaldatasourceId=170 mode="3"></app-wizard-translations>
                                        <app-jj-icons *ngIf="checkIfMemberIsProjectSpecific(member) || applicationInfoService.isDeveloper" type="editor" (click)="editMember(member)" [width]="20" [height]="20"></app-jj-icons>  
                                    </div>                    

                                </div>
                               
                                <div *ngIf="!checkIfMemberIsProjectSpecific(member)" class="adminEntity__list__isProjectSpecificInfo">
                                    <app-jj-icons type="info" color="red" [width]="20" [height]="20" [tippy]="'AdminEntity.Label.ProjectSpecificMember' | texttransform"></app-jj-icons>
                       <!--              {{ 'AdminEntity.Label.ProjectSpecificMember' | texttransform }} -->
                                </div>
                            </div>          
                            
                        </div>    
                    </div>
                </div>
    
            </div>

        </div>

    </div>

</div>