<div class="navigationDesign__wrapper">
    <div class="navigationDesign__header">        
        <select [(ngModel)]="tableLayoutListSelectedItem" *ngIf="applicationInfoService.isDeveloper" (change)="getTableLayout()">
            <option *ngFor="let tableLayoutListItem of tableLayoutList" [ngValue]="tableLayoutListItem.value">
                {{ tableLayoutListItem.name }}
            </option>
        </select>
        <div>
            Rolle: 
            <select [(ngModel)]="roleId" *ngIf="applicationInfoService.isDeveloper" (change)="getTableLayout()">
                <option [ngValue]="toNumber(4)">Original Admin (4)</option>
                <option *ngFor="let userRole of roles" [ngValue]="userRole.id">
                    {{ commonService.getTranslationValueFromArray(userRole) | texttransform }} ({{ userRole.id }})
                </option>               
            </select>    
        </div>
        <button class="everleadCustomButton" (click)="saveNavigationLayout()">{{ 'EntityTableDesigner.Label.Update' | texttransform}}</button>
    </div>

    <div class="navigationDesign__contentWrapper">
        <div class="navigationDesign__contentWrapper__toolbar">
            <!-- <label class="ellipsisText everleadCoral navigationDesignHeaderLabel">{{ 'NavigationMenu.Label.HeaderTemplates' | texttransform }}</label> -->
            <app-layout header="NavigationMenu.Label.HeaderTemplates" class="halfHeight">
                <div class="navigationDesignListItem everleadTableRow" *ngFor="let controlTemplate of controlHeaderTemplateList">                
                    <label class="uiContainerDesignItemAvailableLabel ellipsisText">{{ controlTemplate.defaultName | texttransform }}</label>
                    <app-jj-icons [type]="'plus-circle'" [width]="20" [height]="20" (click)="addControl(controlTemplate)"></app-jj-icons>
                    <!-- <svg-icon src="assets/images/svg/icon-plus+.svg" class="fingerCursor" (click)="addControl(controlTemplate)" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon> -->
                </div>
            </app-layout>
            <!-- <label class="ellipsisText everleadCoral navigationDesignHeaderLabel">{{ 'NavigationMenu.Label.Templates' | texttransform }}</label> -->
            <app-layout header="NavigationMenu.Label.Templates" class="halfHeight">
                <div class="navigationDesignListItem everleadTableRow" *ngFor="let controlTemplate of controlTemplateList" [ngClass]="{
                    hideInDom: !showJsonMenuItem(controlTemplate)
                }">
                    <label class="uiContainerDesignItemAvailableLabel ellipsisText">{{ controlTemplate.defaultName | texttransform }}</label>
                    <app-jj-icons [type]="'plus-circle'" [width]="20" [height]="20" (click)="addControl(controlTemplate)"></app-jj-icons>
                </div>
            </app-layout>
        </div>
    
        <app-layout header="NavigationMenu.Label.Content">
        <div cdkDropList [cdkDropListData]="navigationTable.fields" (cdkDropListDropped)="moveInList($event, '0')" 
            class="navigationDesign__content">
        <div cdkDrag [cdkDragData]="item" data-html="true" class="navigationDesign__item everleadTableRow" 
            [ngClass]="{
                navigationDesignSublevel0: field.isChild == false,
                navigationDesignSublevel1: field.isChild == true
            }" *ngFor="let field of navigationTable.fields">
            <div class="navigationDesign__item__header">
                <div class="blueBorder" *ngIf="!field.isChild"></div>
                <label>{{ field.defaultName | texttransform }}</label>                
                <div class="navigationDesign__item__extend">
                    <app-jj-icons *ngIf="!field.isChild" [type]="'arrow-right'" [width]="20" [height]="20" (click)="toggleSubelement(field, 'isChild')"></app-jj-icons>
                    <app-jj-icons *ngIf="field.isChild" [type]="'arrow-left'" [width]="20" [height]="20" (click)="toggleSubelement(field, 'isChild')"></app-jj-icons>
                </div>
                <div class="navigationDesign__item__devText" *ngIf="applicationInfoService.isDeveloper">
                    <input [(ngModel)]="field.defaultName" class="maxWidth">
                </div>                
                <div class="navigationDesign__item__extend">
                    <app-jj-icons *ngIf="!field.isVisible" [type]="'eye'" color="boxBorderGray" [width]="20" [height]="20" (click)="toggleSubelement(field, 'isVisible')"></app-jj-icons>
                    <app-jj-icons *ngIf="field.isVisible" [type]="'eye'" [width]="20" [height]="20" (click)="toggleSubelement(field, 'isVisible')"></app-jj-icons>
                </div>
                <div class="navigationDesign__item__extend">
                    <app-jj-icons [type]="'trash'" [width]="20" [height]="20" (click)="deleteField(field)"></app-jj-icons>
                </div>
                <div class="navigationDesign__item__extend fingerCursor" (click)="field.extended = !field.extended">
                    <app-jj-icons *ngIf="field.extended"  [type]="'caret-up'" [height]="12" [width]="12" [color]="'secondary'"></app-jj-icons>  
                    <app-jj-icons *ngIf="!field.extended" [type]="'caret-down'" [height]="12" [width]="12" [color]="'secondary'"></app-jj-icons>  
                </div>
                <div class="navigationDesign__item__extend">
                    <app-jj-icons [type]="'equals'" [width]="20" [height]="20"></app-jj-icons>
                </div>
            </div>            
            <div *ngIf="field.extended" class="navigationDesign__extended">
                <div class="navigationDesign__valueline">
                    <input [(ngModel)]="field.defaultName" class="maxWidth">
                </div>    
                <label class="navigationDesign__sectionHeader">{{ 'NavigationMenu.Label.OtherTranslations' | texttransform }}</label>            
                <div class="navigationDesign__valueline">
                    <label class="navigationDesign__valueline__lineLabel">   
                        {{ 'NavigationMenu.Label.German' | texttransform }}                     
                    </label>
                    <input [(ngModel)]="field.translationde" class="maxWidth">
                </div>
                <div class="navigationDesign__valueline">
                    <label class="navigationDesign__valueline__lineLabel">   
                        {{ 'NavigationMenu.Label.English' | texttransform }}                     
                    </label>
                    <input [(ngModel)]="field.translationen" class="maxWidth">
                </div>
                
                <div class="navigationDesign__valueline">
                    <input type="checkbox" [(ngModel)]="field.isActive"><label>{{ 'NavigationMenu.Label.IsActive' | texttransform }}</label>
                </div>
                <div class="navigationDesign__valueline">
                    <input type="checkbox" [(ngModel)]="field.isVisible"><label>{{ 'NavigationMenu.Label.IsVisible' | texttransform }}</label>
                </div>
                <div class="navigationDesign__valueline" *ngIf="field.isChild != true">
                    <input type="checkbox" [(ngModel)]="field.isExtendable"><label>{{ 'NavigationMenu.Label.IsExtendable' | texttransform }}</label>
                </div>
                <div class="navigationDesign__valueline">
                    <input type="checkbox" [(ngModel)]="field.isChild"><label>{{ 'NavigationMenu.Label.IsChild' | texttransform }}</label>
                </div>
                <label class="navigationDesign__sectionHeader everleadCoral">{{ 'NavigationMenu.Label.IconImage' | texttransform }}</label>
                <div class="navigationDesign__valuelineSelect">
                    <!-- Imgtype -->
                    <div class="navigationDesign__valueline">
                        <label class="navigationDesign__valueline__lineLabel">{{ 'NavigationMenu.Label.IconType' | texttransform }}</label>
                        <select [(ngModel)]="field.iconType" class="navigationDesign__valueline__selection">
                            <option [ngValue]=null></option>
                            <option ngValue='svg'>{{ 'NavigationMenu.Label.SVG' | texttransform }}</option>
                            <option ngValue='imgurl'>{{ 'NavigationMenu.Label.ImgURL' | texttransform }}</option>                        
                            <option ngValue='jjicon'>{{ 'NavigationMenu.Label.JJIcon' | texttransform }}</option>                        
                        </select>    
                    </div>

                    <!-- jjicon -->
                    <div class="navigationDesign__valueline" *ngIf="field.iconType == 'jjicon'">
                        <label class="navigationDesign__valueline__lineLabel">{{ 'NavigationMenu.Label.SelectJJIcon' | texttransform }}</label>
                        <input class="maxWidth" [(ngModel)]="field.icon">
                        <!-- <select [(ngModel)]="field.icon" class="navigationDesign__valueline__selection">
                            <option [ngValue]=null></option>
                            <option *ngFor="let jjIcon of jjIcons" [ngValue]="jjIcon">{{ jjIcon | texttransform }}</option>
                        </select> -->
                    </div>

                    <!-- SVG -->
                    <div class="navigationDesign__valueline" *ngIf="field.iconType == 'svg'">
                        <label class="navigationDesign__valueline__lineLabel">{{ 'NavigationMenu.Label.SelectSvg' | texttransform }}</label>
                        <select [(ngModel)]="field.icon" class="navigationDesign__valueline__selection">
                            <option [ngValue]=null></option>
                            <option *ngFor="let svgIcon of svgIcons" [ngValue]="svgIcon.iconPath">{{ svgIcon.iconName | texttransform }}</option>
                        </select>
                    </div>

                    <div class="navigationDesign__valueline" *ngIf="field.iconType == 'imgurl'">
                        <label class="navigationDesign__valueline__lineLabel">{{ 'NavigationMenu.Label.URL' | texttransform }}</label>
                        <input [(ngModel)]="field.icon" class="maxWidth">
                    </div>
                    
                    <div class="navigationDesign__valueline">
                        <label class="navigationDesign__valueline__lineLabel">{{ 'NavigationMenu.Label.Preview' | texttransform }}</label>
                        <img [src]="field.icon" class="navigationDesign__valueline__image" *ngIf="field.iconType == 'imgurl' && field.icon">
                        <div *ngIf="field.iconType == 'jjicon' && field.icon != null">
                            <app-jj-icons [type]="field.icon" [width]="20" [height]="20"></app-jj-icons>
                        </div>
                        <div *ngIf="field.iconType == 'svg' && field.icon != null">
                            <svg-icon [src]=getSvgPreview(field.icon) [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                        </div>    
                    </div>                    
                </div>

                <!-- Click -->
                <label class="navigationDesign__sectionHeader everleadCoral">{{ 'NavigationMenu.Label.ClickReaction' | texttransform }}</label>
                <div class="navigationDesign__valuelineSelect">
                    <select [(ngModel)]="field.clickType" class="navigationDesign__valueline__selection">
                        <option [ngValue]=null></option>
                        <option ngValue='navigateToSection'>{{ 'NavigationMenu.Label.NavigateToSection' | texttransform }}</option>
                        <option ngValue='showContainer'>{{ 'NavigationMenu.Label.ShowContainer' | texttransform }}</option>
                        <option ngValue='showPopup'>{{ 'NavigationMenu.Label.ShowPopup' | texttransform }}</option>
                        <option ngValue='loadNavigation'>{{ 'NavigationMenu.Label.LoadNavigation' | texttransform }}</option>                        
                    </select>
                    <select [(ngModel)]="field.target" class="navigationDesign__valueline__selection" 
                        *ngIf="field.clickType == 'navigateToSection' || field.clickType == 'showPopup'">
                        <option [ngValue]=null></option>
                        <option ngValue='dashboard'>{{ 'NavigationMenu.Label.SectionDashboard' | texttransform }}</option>
                        <option ngValue='accounts'>{{ 'NavigationMenu.Label.SectionAccounts' | texttransform }}</option>
                        <option ngValue='contacts'>{{ 'NavigationMenu.Label.SectionContacts' | texttransform }}</option>
                        <option ngValue='myleads'>{{ 'NavigationMenu.Label.SectionMyLeads' | texttransform }}</option>
                        <option ngValue='myfollowups'>{{ 'NavigationMenu.Label.SectionMyfollowups' | texttransform }}</option>
                        <option ngValue='reporting'>{{ 'NavigationMenu.Label.SectionReporting' | texttransform }}</option>
                        <option ngValue='newaccount'>{{ 'NavigationMenu.Label.SectionNewaccount' | texttransform }}</option>                        
                        <option ngValue='supervisor-main' *ngIf="applicationInfoService?.isDeveloper">{{ 'NavigationMenu.Label.SectionSupervisorMain' | texttransform }}</option>
                        <option ngValue='admin-main' *ngIf="applicationInfoService?.isDeveloper">{{ 'NavigationMenu.Label.SectionAdminMain' | texttransform }}</option>
                    </select>
                    <div class="navigationDesign__valueline" *ngIf="applicationInfoService?.isDeveloper">
                        <label class="navigationDesign__valueline__lineLabel">   
                            Ziel
                        </label>
                        <input [(ngModel)]="field.target" class="maxWidth">
                    </div>                        
                    
                    <div *ngIf="applicationInfoService?.isDeveloper && field.clickType=='showPopup'">
                        <!-- Popup -->
                        <div class="navigationDesign__valueline">
                            <label class="navigationDesign__valueline__lineLabel">   
                                Überschrift
                            </label>
                            <input [(ngModel)]="field.popupHeader" class="maxWidth" >
                        </div>                        
                        <div class="navigationDesign__valueline">
                            <label class="navigationDesign__valueline__lineLabel">   
                                Popup-Dimension
                            </label>
                            <input [(ngModel)]="field.popupDimension" class="maxWidth" >
                        </div>
                    </div>
                    
                </div>
                <label class="navigationDesign__sectionHeader everleadCoral" *ngIf="applicationInfoService?.isDeveloper">{{ 'NavigationMenu.Label.DeveloperOnly' | texttransform }}</label>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Gesperrte UserIds
                    </label>
                    <input [(ngModel)]="field.blockedUserIds" class="maxWidth">
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Benötgte Rechte
                    </label>
                    <input [(ngModel)]="field.requiredRights" class="maxWidth">
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Gesperrte ProjektIds
                    </label>
                    <input [(ngModel)]="field.blockedProjectIds" class="maxWidth">
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Benötigte ProjektIds
                    </label>
                    <input [(ngModel)]="field.requiredProjectIds" class="maxWidth">
                </div>

                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Gesperrte PoolIds
                    </label>
                    <input [(ngModel)]="field.blockedPoolIds" class="maxWidth">
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Benötigte PoolIds
                    </label>
                    <input [(ngModel)]="field.requiredPoolIds" class="maxWidth">
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Gesperrte Campaigns
                    </label>
                    <input [(ngModel)]="field.blockedCampaignIds" class="maxWidth">
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Adminbereich
                    </label>
                    <input [(ngModel)]="field.adminSection" class="maxWidth" type="number">
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <label class="navigationDesign__valueline__lineLabel">   
                        Benötigte Campaigns
                    </label>
                    <input [(ngModel)]="field.requiredCampaignIds" class="maxWidth">
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <input type="checkbox" [(ngModel)]="field.developerOnly"><label>Developer only</label>
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <input type="checkbox" [(ngModel)]="field.requiresAccount"><label>Account benötigt</label>
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <input type="checkbox" [(ngModel)]="field.requiresContact"><label>Contact benötigt</label>
                </div>
                <div class="navigationDesign__valueline " *ngIf="applicationInfoService?.isDeveloper">
                    <input type="checkbox" [(ngModel)]="field.stroke"><label>Icon: Stroke</label>
                </div>
            </div>
        </div>        
        </div>
    </app-layout>
    </div>
</div>