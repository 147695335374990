<span id="questionaireControlDesignSpan"></span>
<div class="projectQuestionnaireWrapper"
    [style.max-height.px]="getControlHeight('questionaireControlDesignSpan', 20)"
    [style.height.px]="getControlHeight('questionaireControlDesignSpan', 20)">
    <div class="questionaires" *ngIf="selectAvailable && questionaires?.length > 0">
        <div class="questionnaireTabMenu__wrapper">
            <div class="questionnaireTabMenu">
                <app-tab [svg]="getQuesionnaireIcon(questionaire)" [active]="selectedQuestionaire == questionaire" [label]="questionaire.defaultName"
                    *ngFor="let questionaire of questionaires" (onClick)="changeQuestionaire(questionaire)"></app-tab>
            </div>
            <div class="questionnaireTabDesigner" *ngIf="userService.hasPermission('CanCreateQuestionnaire')">
                <app-main-button type="borderSmall" (click)="openDesigner()" [label]="'QuestionaireDesigner.Label.Manage' | texttransform"></app-main-button>
            </div>
        </div>    
    </div>

    <div class="questionaireControl" *ngIf="questionsRequested && (
        (selectedQuestionaire.entityId == 1 && applicationInfoService.currentAccount != null) ||
        (selectedQuestionaire.entityId == 2 && applicationInfoService.currentTask != null) ||
        (selectedQuestionaire.entityId == 4 && applicationInfoService.currentContact != null) 
    )">
        <app-layout [header]="selectedQuestionaire.defaultName | texttransform">
            <div *ngFor="let question of questions" class="everleadTableRow selectedQuestionaireQuestion" [ngClass]="{ hideInDom: question.isDeleted }">
                <app-questionaire-userquestion [questionnaire]="selectedQuestionaire" [question]="question"></app-questionaire-userquestion>
            </div>
        </app-layout>
    </div>

    <div *ngIf="selectedQuestionaire">
        <div *ngIf="selectedQuestionaire.entityId == 4 && applicationInfoService.currentContact == null">
            {{ 'Questionaire.Label.PleaseSelectContact' | texttransform }}
        </div>
    </div>

    <div class="noQuestionaireAvailable" *ngIf="questionaires?.length == 0">
        <div>{{ 'Questionaire.Label.NoQuestionaireAvailable' | texttransform }}</div>
        <div *ngIf="userService.hasPermission('CanCreateQuestionnaire')">
            <app-main-button type="borderSmall" (click)="openDesigner()" [label]="'QuestionaireDesigner.Label.Manage' | texttransform"></app-main-button>
        </div>
    </div>
</div>