<div class="everleadNumberField" [class.disabled]="disabled" [class.error]="error">
    <label class="everleadNumberField__label" *ngIf="label" [class.disabled]="disabled" [class.error]="error">
        {{ label | texttransform }}
        <app-wizard-infobutton *ngIf="info" [displayText]="info" [width]="17" [height]="17"></app-wizard-infobutton>
    </label>
    <div class="everleadNumberField__value" [class.disabled]="disabled" [class.error]="error">
        <input 
        *ngIf="!fire"
        type="number" 
        [placeholder]="placeholder" 
        [(ngModel)]="value"
        (change)="handleInput($event.target.value)"
        [disabled]="disabled"
        [min]="min"
        [max]="max"
        [step]="step">

        <input 
        *ngIf="fire"
        type="number" 
        [placeholder]="placeholder" 
        [(ngModel)]="value"
        (ngModelChange)="handleInput(value)"
        [disabled]="disabled"
        [min]="min"
        [max]="max"
        [step]="step">
    </div>
</div>