<div class="mocoprojectassign">
    <div class="mocoprojectassign__header">
        <app-component-header [header]="applicationInfoService.breadCrumbText || 'Time Recording Projects'"></app-component-header>

        <div class="mocoprojectassign__nav">
            <app-main-button
                type="small"
                svg="plus"
                label="MocoDesign.Label.NewAssignment"
                (onClick)="createNewMocoAssignment()"></app-main-button>
        </div>
    </div>

    <div class="mocoprojectassign__inner">
        <div class="mocoprojectassign__content">
            <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
                <ng-container matColumnDef="task">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MocoDesign.Label.Project' | texttransform }}</th>
                    <td mat-cell *matCellDef="let element">  
                        <app-select-input [(ngModel)]="element.mocoProjectTaskId" (valueChange)="updateAssignment(element)"
                        bind="id" display="name" [options]="getTaskstOfSpecificProject(element.mocoProjectId)"></app-select-input>    
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="project">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MocoDesign.Label.Activity' | texttransform }}</th>
                    <td mat-cell *matCellDef="let element" class="mocoProject">            
                        <app-select-input [(ngModel)]="element.mocoProjectId" (valueChange)="changeProject(element)" bind="id"
                        display="name" [options]="mocoProjects"></app-select-input>    
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="lookup">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MocoDesign.Label.Lookup' | texttransform }}</th>
                    <td mat-cell *matCellDef="let element" class="poolIsActive">            
                        <app-select-input [(ngModel)]="element.typeLookupId" (valueChange)="updateAssignment(element)" bind="id" 
                            [options]="lookupValues" display="defaultName"></app-select-input>
                    </td>
                </ng-container>
            
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="poolIsActive">
                        <app-jj-icons  type="trash" (click)="deleteAssignment(element)" [width]="20" [height]="20"></app-jj-icons>            
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
    </div>
</div>
