import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ApplicationInfoService } from "app/core/application/application-info.service";
import { CommonService } from "app/jollyjupiter/service/common.service";
import { EventService } from "app/jollyjupiter/service/event.service";
import { PackagefeatureService } from "app/jollyjupiter/service/packagefeature.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-features",
  templateUrl: "./features.component.html",
  styleUrl: "./features.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class FeaturesComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();

  @Input() fixFeatureId = null;
  featureId = null;
  featureSlideId = null;
  slider: any[] = [];
  feature = null;
  loadingData = false;
  currentSlider = null;
  currentSliderPos = null;
  
  language
  
  constructor(
    private applicationInfoService: ApplicationInfoService,
    public packagefeatureService: PackagefeatureService,
    private eventService: EventService,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    this.showSlider();


    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'refreshPackageFeatureInfo') {
        this.showSlider();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  showSlider() {
    if (this.fixFeatureId == null) {
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['selectedPackageFeature'])) {
        this.featureId = 1;
      } else {
        this.featureId = this.applicationInfoService.miscSettings['selectedPackageFeature'];
        this.featureSlideId = this.applicationInfoService.miscSettings['selectedPackageFeatureSlide'];
      }  
    } else {
      this.featureId = this.fixFeatureId;
    }

    this.feature = this.packagefeatureService.getFeatureById(this.featureId);
    this.packagefeatureService.getFeatureSlides(this.featureId)
    .then(slides => {
      this.slider = slides;
      this.slider = slides.filter(item => this.languageSettings(item.settings))
      //console.log('slider', this.slider)
      if (this.slider.length > 0) {
        this.currentSlider = this.slider[0];
        this.currentSliderPos = 0;
      } else {
        this.currentSlider = null;
        this.currentSliderPos = 0;
      }
      if (!this.commonService.isNullOrUndefined(this.featureSlideId)) {
        this.currentSlider = this.slider.find(slide => slide.id == this.featureSlideId);
      }
    });
  }

  // navigateToFeatureRoute() {
  //   this.packagefeatureService.navigateToFeatureRoute(this.featureId);
  // }

  getFeatureSliderInnerHtml(currentSlider) {
    return this.packagefeatureService.getInnerHTMLOfFeatureSlider(currentSlider);
  }

  getFeatureSliderImage(currentSlider) {
    return this.packagefeatureService.getImageOfFeatureSlider(currentSlider);
  }

  changeSlide(slideForward) {
    if (slideForward) {
      this.currentSliderPos ++;
    } else {
      this.currentSliderPos --;
    }

    if (this.currentSliderPos > this.slider.length - 1) {
      this.currentSliderPos = 0;
    }
    if (this.currentSliderPos < 0) {
      this.currentSliderPos = this.slider.length - 1;
    }

    this.currentSlider = this.slider[this.currentSliderPos];
  }


  languageSettings(settings){
    const parse = JSON.parse(settings)
    const userLanguage = this.applicationInfoService.userSettings['LanguageIdentifier']
    const string = parse[`text_${userLanguage}`]

    if(string){
      return true
    } else {
      return false
    }

  }


}
