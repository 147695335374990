import { Component, OnInit } from '@angular/core';
import { DashboardcoreService } from '../../dashboardcore.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-dashdashboardcomponent-lastactivity',
  templateUrl: './dashdashboardcomponent-lastactivity.component.html',
  styleUrl: './dashdashboardcomponent-lastactivity.component.scss'
})
export class DashdashboardcomponentLastactivityComponent implements OnInit {
  page = 0;
  loading = false;
  activities = [];
  templateId = null;
  noData = false;

  // cid live 273527 dev 274367

  constructor(
    private methodService: MethodService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    public axivasTranslationService: AxivasTranslateService,
    public applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.templateId = Number(this.applicationInfoService.applicationSettings['dashboardMyLastActivityTemplateId']);
    this.getActivities();
  }

  getActivities( ){
    this.loading = true;
    let colfilter = [
      { "columnName" : "poolId", "value": this.applicationInfoService.poolId.toString() },
      { "columnName" : "resultUserId", "value": this.applicationInfoService.userID.toString() }
    ];
    const tableSettings = {
      definitionId: this.templateId,
      orderBy: { columnName : "resultDate", orderByOperator: 'DESC_NULLS_LAST' },
      filterId: null,
      columnFilter: colfilter,
      pageSize: 30,
      pageNumber: 1
    }
    this.noData = false;
    
    this.externaldatasourceService.executeExternalDataSource(978,[
      tableSettings.definitionId,
      tableSettings.pageSize,
      tableSettings.pageNumber,
      this.mapFilterArray(tableSettings.columnFilter),
      this.mapFilterArray(tableSettings.orderBy),
      '', tableSettings.filterId, false,false,false,false,false,false,false,false,false,false,false
    ]).then((visitorsResult)=> {      
      this.loading = false
      this.activities = [];
      visitorsResult.jsonData.forEach(item => { this.activities.push(JSON.parse(item)); })
      if (this.activities.length == 0) {
        this.noData = true;
      }
    }).catch((error) => {
      this.loading = false;
    });
  }

  mapFilterArray(filterArray){
    let filterArrayJson = JSON.stringify(filterArray);
    Object.keys(filterArray).forEach(() =>  {      
      filterArrayJson = filterArrayJson.replace('"'.concat('columnName', '"'), 'columnName');
      filterArrayJson = filterArrayJson.replace('"'.concat('value', '"'), 'value');
      filterArrayJson = filterArrayJson.replace('"'.concat('operator', '"'), 'operator');
      filterArrayJson = filterArrayJson.replace('"'.concat('orderByOperator', '"'), 'orderByOperator');
      filterArrayJson = filterArrayJson.replace('"'.concat('negate', '"'), 'negate');
      filterArrayJson = filterArrayJson.replace('"'.concat('DESC_NULLS_LAST', '"'), 'DESC_NULLS_LAST');
      filterArrayJson = filterArrayJson.replace('"'.concat('ASC_NULLS_LAST', '"'), 'ASC_NULLS_LAST');
    });

    //console.log('filterArrayJson', filterArrayJson);
    return filterArrayJson;
  }
}
