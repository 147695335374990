import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { WizardService } from '../wizard.service';
import { UserService } from 'app/core/authentication/services/user.service';

@Component({
  selector: 'app-wizard-leadstate',
  templateUrl: './wizard-leadstate.component.html',
  styleUrls: ['./wizard-leadstate.component.scss']
})
export class WizardLeadstateComponent implements OnInit {
  leadStateTypes = [];

  constructor(
    public wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.getLeadStateTypes()
  }

  getLeadStateTypes() {
    this.leadStateTypes = [];
    this.externaldatasourceService.executeExternalDataSource(387, ['70'])
    .then(getLeadStateTypesResult => {
      this.leadStateTypes = getLeadStateTypesResult;
    })
  }
}
