<span id="qualificationSpan"></span>
<div *ngIf="applicationInfoService?.currentAccount?.isDummyAccount == true" class="qualificationDummy">
    {{ 'QualifyState.Label.DummyAccount' | texttransform }}
</div>
<div class="qualificationWrapper" 
    *ngIf="applicationInfoService?.currentAccount?.isDummyAccount != true"
    [style.height]="getControlHeight('qualificationSpan', 0)">
    <div class="qualificationDropdown">
        <app-select-input label="QualifyState.Label.QualifyState" [(ngModel)]="applicationInfoService.currentAccount.qualifyEditingState" 
            [options]="qualificationStates" display="defaultName" bind="id" (valueChange)="updateAccount()"></app-select-input>
    </div>
    <div class="userCreditsLeft">
        {{ userCreditsLeft }} <app-jj-icons type="coins" [width]="32" [height]="32"></app-jj-icons>        
    </div>
    <app-layout header="QualifyState.Label.QualifyState" class="maxHeight">
        <div class="qualificationHeader">
            <app-tab svg="stack-plus" [active]="tab == 0" label="QualifyState.Label.DataEnrichment" (onClick)="tab = 0"></app-tab>            
            <app-tab svg="user" [active]="tab == 2" label="Contact.Label.Header" (onClick)="tab = 2"></app-tab>
            <app-tab svg="file" *ngIf="enrichementWasAlreadyMade" [active]="tab == 1" label="QualifyState.Label.LastEnrichmentResults" (onClick)="tab = 1"></app-tab>
        </div>

        <div class="qualificationContent" *ngIf="tab == 0">
            <div class="qualificationContent__description">{{ 'QualifyState.Label.QualifyAccountDescription' | texttransform }}</div>
            <div class="qualificationContent__description">{{ 'QualifyState.Label.QualifyAccountDescription2' | texttransform }}</div>
            <div class="qualificationContent__buttons">
                <app-main-button svg="stack-plus" type="borderSmall" (onClick)="enrichAccount()" 
                    label="QualifyState.Label.DataEnrichment"></app-main-button>
                <app-main-button svg="stack-plus" type="borderSmall" (onClick)="tab = 1" 
                    *ngIf="enrichementWasAlreadyMade" label="QualifyState.Label.LastEnrichmentResults"></app-main-button>
            </div>
        </div>

        <div class="qualificationContentAvailableData" *ngIf="tab == 1">
            <div class="qualificationContent__description qualificationContent__descriptionspacer">{{ 'QualifyState.Label.LastEnrichmentDataList' | texttransform }}</div>
            <div class="qualificationContentItemList">
                <div class="qualificationContentItem everleadTableRow" *ngFor="let enrichmentDataDetailItem of enrichmentDataDetails">
                    <div class="qualificationContentItem__key">{{enrichmentDataDetailItem.key }}</div>
                    <div class="qualificationContentItem__value">
                        <div *ngIf="enrichmentDataDetailItem.key == 'socialAccounts'">
                            <div *ngFor="let valueItem of enrichmentDataDetailItem.value" (click)="loadUrl(valueItem['url'])"
                                class="qualificationContentItem__socialAccount fingerCursor">
                                <div class="qualificationContentItem__socialAccount_icon">
                                    <app-jj-icons type="globe" [width]="20" [height]="20"></app-jj-icons>
                                </div>
                                <div class="qualificationContentItem__socialAccount__type">{{ valueItem['type'] }}</div>
                                <div class="qualificationContentItem__socialAccount__url">
                                    <!-- <a [href]="valueItem['url']" target="_blank">{{ valueItem['url'] }}</a> -->
                                     {{ valueItem['url'] }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="
                            enrichmentDataDetailItem.key != 'socialAccounts'
                        ">
                            {{ getValueContent(enrichmentDataDetailItem.value) }}
                        </div>                        
                    </div>
                </div>
            </div>
        </div>

        <div class="qualificationContentAvailableData" *ngIf="tab == 2">
            <!-- Contacts -->
            <div *ngIf="!enrichementWasAlreadyMade" class="enrichmentRequiredInfo">
                <app-jj-icons type="info" [width]="20" [height]="20"></app-jj-icons>
                {{ 'QualifyState.Label.PleaseEnrichAccountFirst' | texttransform }}
            </div>
            <div *ngIf="enrichementWasAlreadyMade" class="maxHeight">
                <app-echobot-interface [isHosted]="true" [resultEntity]="4" [accountBrokerId]="enrichmentData.brokerId"></app-echobot-interface>
            </div>
        </div>
    </app-layout>
</div>