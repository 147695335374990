import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-admin-aiprompts',
  templateUrl: './admin-aiprompts.component.html',
  styleUrls: ['./admin-aiprompts.component.scss']
})
export class AdminAipromptsComponent implements OnInit {
  promptLookups = null;
  responseLookups = null;
  modelLookups = null;
  toggledItems = [];
  placeholders = [];
  languages = [];
  filterString = '';
  aiPrompts = [];
  promptModels = [];
  prompts = [];
  defaultSettings = {
    Temperature: 0.6,
    TopP: 1.0,
    MaxTokens: 2048,
    PresencePenalty: 0.0,
    FrequencyPenalty: 0.0,
    UseTemperature: true
  }
  selectedPrompt = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    private axivasTranslateService: AxivasTranslateService,
    public userService: UserService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getAiPrompts();
    this.getPlaceHolders();
    this.getLanguages();
    this.getAiPromptModels();
    this.modelLookups = this.commonService.getProjectLookupTableValues(86, 'central');
    this.responseLookups = this.commonService.getProjectLookupTableValues(87, 'central');
    this.promptLookups = this.commonService.getProjectLookupTableValues(85, 'central');

    this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.modelLookups = this.commonService.getProjectLookupTableValues(86, 'central');
        this.responseLookups = this.commonService.getProjectLookupTableValues(87, 'central');
        this.promptLookups = this.commonService.getProjectLookupTableValues(85, 'central');
      }
    });
  }

  toggleItem(index) {
    if (this.toggledItems[index] == true)  {
      this.toggledItems[index] = false;
    } else {
      this.toggledItems[index] = true;
    }
  }

  getAiPromptModels() {
    this.externaldatasourceService.executeExternalDataSource(833, []).then(getAiPromptModelsResult => {
      this.promptModels = getAiPromptModelsResult;
    });    
  }

  getLanguages() {
    this.externaldatasourceService.executeExternalDataSource(89, []).then(getLanguagesResult => {
      this.languages = getLanguagesResult;
    });    
  }

  getAiPrompts() {
    this.externaldatasourceService.executeExternalDataSource(775, []).then(getAiPromptsResult => {
      getAiPromptsResult.forEach(result => {
        if (this.commonService.isNullOrUndefined(result.settings)) {
          result.settingsArray = this.defaultSettings;
        } else {
          result.settingsArray = JSON.parse(result.settings);
        }
      });
      this.aiPrompts = getAiPromptsResult;
    });
  }

  createAiPrompt() {
    const aiPrompt = {
      projectId: this.applicationInfoService.projectID, 
      defaultName: 'New AiPrompt', 
      promptLookupId: 6666, 
      prompt: '', 
      nameTranslationTokenId: null, 
      descriptionTranslationTokenId: null,
      isDefault: false, 
      isActive: true, 
      languageId: 2, 
      responseLookupId: 6670, 
      modelLookupId: 3,
      settings: JSON.stringify(this.defaultSettings),
      pictureData: null
    }
    this.externaldatasourceService.executeExternalDataSource(776, [
      this.commonService.getModifyArrayBody(aiPrompt, [])
    ]).then(createAiPromptResult => {
      aiPrompt['settingsArray'] = this.defaultSettings;
      aiPrompt['id'] = createAiPromptResult.id;
      this.aiPrompts.push(aiPrompt);
    });
  }


  saveAiPrompt(aiPrompt) {    
    aiPrompt.settings = JSON.stringify(aiPrompt.settingsArray);
    this.externaldatasourceService.executeExternalDataSource(777, [this.commonService.getModifyArrayBody(aiPrompt, ['settingsArray'])
    ]).then(() => {
      
    });
  }

  deleteAiPrompt(aiPrompt) {
    this.externaldatasourceService.executeExternalDataSource(778, [aiPrompt.id]).then(() => {
      this.commonService.removeItemFromArray(this.aiPrompts, aiPrompt);
    });
  }

  getPlaceHolders() {
    this.externaldatasourceService.executeExternalDataSource(446, [])
    .then(getPlaceHoldersResult => { this.placeholders = getPlaceHoldersResult; })
  }

  addMemberToPrompt(placeholder, aiPrompt) {
    aiPrompt.prompt = aiPrompt.prompt.concat(placeholder,' ');    
    this.saveAiPrompt(aiPrompt);
  }

  getEntity(placeholder) {
    let entityId = null;
    switch(this.getPlaceholderDetails(placeholder)[0]) {
      case 'account': entityId = 1; break;
      case 'lastActivity': entityId = 2; break;
      case 'contact': entityId = 4; break;      
    }
    if (entityId != null) {
      return this.axivasTranslateService.getTranslationTextForObject(this.applicationInfoService.entities.Item(entityId.toString()), 'nameTranslationToken')
    } else {
      return placeholder;
    }
  }

  getMember(placeholder) {
    let entityId = null;
    const details = this.getPlaceholderDetails(placeholder);
    switch(details[0]) {
      case 'account': entityId = 1; break;
      case 'lastActivity': entityId = 2; break;
      case 'contact': entityId = 4; break;      
    }
    if (entityId != null) {
      const member = this.applicationInfoService.entities.Item(entityId).entityMembers.find(member => member.defaultName == details[1]);      
      if (member) {
        return this.axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId, member.defaultName)
      } else {
        return '';
      }  
    } else {
      return '';
    }
  }

  getPlaceholderDetails(placeholder) {
    placeholder = placeholder.replace(/{/g, '');
    placeholder = placeholder.replace(/}/g, '');
    return placeholder.split('.');
  }

  getPlaceHolderInfo(placeholder) {
    return;
    let returnString = '';
    let entityId = null;
    placeholder = placeholder.replace(/{/g, '');
    placeholder = placeholder.replace(/}/g, '');
    const placeHolderDetails = placeholder.split('.');
    switch (placeHolderDetails[0]) {
      case 'account':
        entityId = 1;
        break;

      case 'contact':
        entityId = 4;
        break;

      case 'lastActivity':
        entityId = 2;
        break;  
    }
    if (entityId == null) {
      returnString = placeholder;
    } else {
      const member = this.applicationInfoService.entities.Item(entityId).entityMembers.find(member => member.defaultName == placeHolderDetails[1]);      
      if (member) {
        returnString = ''.concat(
          this.axivasTranslateService.getTranslationTextForObject(this.applicationInfoService.entities.Item(entityId), 'nameTranslationToken'),
          ' - ',
          this.axivasTranslateService.getTranslationTextForId(member.nameTranslationTokenId, member.defaultName)
        );
      } else {
        returnString = placeholder;
      }
    }
    return returnString;
  }

  saveSettings(aiPrompt) {
    aiPrompt.settings = JSON.stringify(aiPrompt.settingsArray);    
    // Object.keys(aiPrompt.settingsArray).forEach(key => { aiPrompt.settings = aiPrompt.settings.replace('"' + key + '"', key);  });
    this.saveAiPrompt(aiPrompt);
  }

  getMinValue(aiPrompt) {
    return '0.0';
  }

  getMaxValue(aiPrompt) {
    return '1.0';
  }

  isItemFiltered(prompt) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(prompt.defaultName, this.filterString)) {
      returnValue = false;
    }
    return returnValue;
  }

  uploadLoadingImage(event, aiPrompt) {
    this.createBase64String(event, aiPrompt);
  }

  createBase64String(fileInput: any, aiPrompt) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const imgBase64Path = e.target.result;
          aiPrompt.pictureData = imgBase64Path;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeLoadingImage(aiPrompt) {
    aiPrompt.pictureData = null;
  }
}
