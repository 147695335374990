import { Component, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-dashdashboardcomponent-lastactivity-item',
  templateUrl: './dashdashboardcomponent-lastactivity-item.component.html',
  styleUrl: './dashdashboardcomponent-lastactivity-item.component.scss'
})
export class DashdashboardcomponentLastactivityItemComponent {
  @Input() activity = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private methodService: MethodService
  ) {}

  getResultTranslation(resultId) {
    const result = this.applicationInfoService.results.toArray().find(result => result.id == resultId);
    if (result) {
      return result.defaultName;
    } else {
      return '';
    }
  }

  loadContact() {
    this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [this.activity.contactid]);
  }
}
