import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';

@Component({
  selector: 'app-toolbar-header',
  templateUrl: './toolbar-header.component.html',
  styleUrl: './toolbar-header.component.scss'
})
export class ToolbarHeaderComponent implements OnInit{

  constructor(
    public commonService:CommonService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    private packagefeatureService: PackagefeatureService
  ){}

  section = null;
  showSection=false


  ngOnInit(): void {

  }

  closePopup() {
    this.section = null
  }

  checkIfTippIsAvailable() {
    //console.log( 'checkif' ,this.commonService.getApplicationSectionContentTipp(window.location.href.toString()))

    return this.commonService.getApplicationSectionContentTipp(window.location.href.toString())
  }

  showHelpContent() {
    this.closePopup();
    this.eventService.customEvent.emit('showHelp')
  }


  callSection(section) { 
    this.showSection = !this.showSection
    if(this.showSection){
      this.section = section
    } else {
      this.section = null
    }
  }



  getNewUserMessages() {
    try {
      const unreadMessages = this.applicationInfoService.userMessages.filter(message => message.userMessages[0].readDateAt == null);
      if (this.commonService.isNullOrUndefined(unreadMessages)) {
        return 0;
      } else {
        return unreadMessages.length;
      }    
    }
    catch {
      console.error('getNewUserMessages error');
    }
  }

  showFirstSteps() {
    if (this.applicationInfoService.applicationSettings['showFirstStepsBanner'] == true) {
      this.applicationInfoService.showBanner = true;
    } else {
      this.packagefeatureService.showFeatureInfoPopup(
        Number(this.applicationInfoService.applicationSettings['showFirstStepsFeatureId'])
      );
    }
  }
}
