import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { TrackingService } from 'app/jollyjupiter/service/tracking.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-startpagedashboard',
  templateUrl: './startpagedashboard.component.html',
  styleUrls: ['./startpagedashboard.component.scss']
})
export class StartpagedashboardComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  hasUpdate = null;
  showDashboards = true;

  constructor(
    private trackingService: TrackingService,
    public applicationInfoService: ApplicationInfoService,
    public packagefeatureService: PackagefeatureService,
    private eventService: EventService    
  ) { }

  ngOnInit(): void {    
    this.trackingService.trackPageView();

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == "selectedPoolChanged") {
        this.showDashboards = false;
        setInterval(() => { this.showDashboards = true; }, 200);
      }
    });

    if (this.packagefeatureService.selectedPackageHasFeature(10) &&
        this.packagefeatureService.selectedPackageHasFeature(111) ) {
      this.hasUpdate = true;
    } else {
      this.hasUpdate = false;
    }
  } 

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }
}
