<div class="entityFilter">    
    <div class="saveFilterPopupBackground" *ngIf="showSaveFilterPopup">
        <div class="saveFilterPopup" *ngIf="showSaveFilterPopup">
            <div>{{ 'EntityFilter.Label.FilterName' | texttransform }}</div>
            <div>
                <input [(ngModel)]="newFilterName" class="maxWidth">
            </div>
            <div class="saveFilterPopup__buttons">
                <app-main-button type="small" (onClick)="saveFilter()" label="General._.Save"></app-main-button>    
                <app-main-button type="small" (onClick)="showSaveFilterPopup = false" label="General._.Back"></app-main-button>    
            </div>
        </div>    
    </div>
    <div class="favoriteFilters">        
        <div class="favoriteFilters__list">
            <!-- <div>{{ 'EntityFilter.Label.Favorites' | texttransform }}</div> -->
            <app-main-button type="small" (onClick)="loadSavedFilter(savedFilter)"
            [label]="savedFilter.name" *ngFor="let savedFilter of getFavoriteFilters()"></app-main-button>
        </div>
    </div>

    <div class="entityFilter__savedfilters" *ngIf="page==2">
        <div class="savedFilterHeader">
            <div>{{ 'EntityFilter.Label.SavedFilters' | texttransform }}</div>
            <app-main-button type="small" (onClick)="page=1" label="General.Label.Back"></app-main-button>    
        </div>
        <div *ngIf="savedFilters.length == 0" class="noSavedFilters">
            {{ 'EntityFilter.Label.NoSavedFilters' | texttransform }}
        </div>
        <div class="savedFilterItem everleadTableRow" *ngFor="let savedFilter of savedFilters">
            <div class="savedFilterItem__name"><input [(ngModel)]="savedFilter.name" (change)="saveUserFilter()"></div>
            <div class="savedFilterItem__buttons" (click)="$event.stopPropagation()">
                <app-jj-icons [tippy]="'EntityFilter.Label.LoadFilter' | texttransform" type="arrow-square-out" [width]="20" [height]="20" (click)="loadSavedFilter(savedFilter)"></app-jj-icons>
                <app-jj-icons [tippy]="'EntityFilter.Label.SetAsFavorite' | texttransform" type="star" [width]="20" [height]="20" (click)="toggleFavorite(savedFilter)" *ngIf="savedFilter.isFavorit == false"></app-jj-icons>
                <app-jj-icons [tippy]="'EntityFilter.Label.RemoveFavorite' | texttransform" color="red" type="star" [width]="20" [height]="20" (click)="toggleFavorite(savedFilter)" *ngIf="savedFilter.isFavorit == true"></app-jj-icons>
                <app-jj-icons [tippy]="'EntityFilter.Label.DeleteFilter' | texttransform" type="trash" [width]="20" [height]="20" (click)="deleteSavedFilter(savedFilter)"></app-jj-icons>
            </div>
        </div>
    </div>

    <div class="filterBlock" *ngIf="page==1">
        <div class="filterBlock__group" *ngFor="let group of groupFilter; let groupI = index">
            <div class="filterBlock__block">
                <app-filter-accordion [title]="'EntityFilter.Label.Or' | texttransform" [isOpen]="true"
                    [noDropdown]="group.filterGroupId === 1" backgroundColor="#FBFBFB">
                    <div class="filterBlock__inner">
                        <div class="filterBlock__header">
                            <label>
                                {{ 'EntityFilter.Label.CriteriaGroup' | texttransform }}
                                <span *ngIf="groupFilter.length < 9">0</span>{{ group.filterGroupId }}
                                <app-jj-icons *ngIf="groupFilter.length > 1" type="trash" [width]="20" height="20"
                                    (click)="deleteGroupFilter(group, index)"></app-jj-icons>
                            </label>
                            <app-main-button *ngIf="group.filterGroupId === 1" type="borderSmall"
                                svg="sliders-horizontal" label="EntityFilter.Label.AdvancedFilter"
                                (mouseenter)="selectedGroupId= group.filterGroupId"
                                (onClick)="advancedFilter= !advancedFilter"
                                [isActive]="advancedFilter"></app-main-button>
                        </div>

                        <div class="filterBlock__inputsContainer" *ngFor="let filter of group.filterList; let i = index;">
                            <app-filter-accordion [title]="'EntityFilter.Label.And' | texttransform" [isOpen]="true"
                                [noDropdown]="filter.filterId === 1">
                                <div
                                    [ngClass]="{filterBlock__inputsContainer__row: filter.filterId !== 1, filterBlock__inputsContainer__rowSingle: filter.filterId === 1 }">
                                    <app-select-input label="EntityFilter.Label.Entity" [options]="entityOptions"
                                        [(ngModel)]="group.filterList[i].entityId" display="entityName" bind="id"
                                        (valueChange)="changeEntity(group.filterList[i], groupI, i); checkValidFields()"></app-select-input>

                                    <app-select-input label="EntityFilter.Label.Member"
                                        [options]="checkMemberOptions(group.filterList[i].entityId)"
                                        [(ngModel)]="group.filterList[i].memberId" display="defaultName" bind="id"
                                        [disabled]="!group.filterList[i].entityId"
                                        (valueChange)="findMember(group.filterList[i], groupI, i); checkValidFields()"></app-select-input>

                                    <app-select-input label="EntityFilter.Label.Operator" [options]="getTypeOperator(group.filterList[i])"
                                        [(ngModel)]="group.filterList[i].operator" bind="operator" display="translation"
                                        [disabled]="!group.filterList[i].memberId"
                                        (valueChange)="changeOperator(group.filterList[i], groupI, i); checkValidFields()"></app-select-input>

                                    <!--        SECTION OF INPUT VALUE TO FILTER   -->
                                    <app-text-input
                                        *ngIf="group.filterList[i].member && group.filterList[i].member.memberType === 'TEXT'"
                                        [label]="group.filterList[i].member.translation"
                                        [(ngModel)]="group.filterList[i].value"
                                        [disabled]="!group.filterList[i].operator" (valueChange)="checkValidFields()"
                                        [fire]="true"></app-text-input>

                                    <app-number-input
                                        *ngIf=" group.filterList[i].member && 
                                                group.filterList[i].member.memberType === 'NUMERIC' &&
                                                group.filterList[i].member.lookupTableId == null &&
                                                group.filterList[i].member.alternativeLookupTable == null"
                                        [label]="group.filterList[i].member.translation"
                                        [(ngModel)]="group.filterList[i].value"
                                        [disabled]="!group.filterList[i].operator" (valueChange)="checkValidFields()"
                                        [fire]="true"></app-number-input>

                                    <app-select-input [label]="group.filterList[i].member.translation"
                                        *ngIf=" group.filterList[i].member && 
                                                group.filterList[i].member.memberType === 'NUMERIC' &&
                                                group.filterList[i].member.lookupTableId != null &&
                                                group.filterList[i].member.alternativeLookupTable == null"
                                        [(ngModel)]="group.filterList[i].value" [options]="getLookupTableOptions(group.filterList[i].member)"
                                        display="defaultName" bind="id"
                                        (valueChange)="checkValidFields()"></app-select-input>

                                    <app-select-input [label]="group.filterList[i].member.translation"
                                        *ngIf=" group.filterList[i].member && 
                                                group.filterList[i].member.memberType === 'NUMERIC' &&
                                                group.filterList[i].member.lookupTableId == null &&
                                                group.filterList[i].member.alternativeLookupTable != null"
                                        [(ngModel)]="group.filterList[i].value" [options]="getAlternateLookupTableOptions(group.filterList[i].member)"
                                        [display]="getAlternateDisplayName(group.filterList[i].member)" bind="id"
                                        (valueChange)="checkValidFields()"></app-select-input>

                                    <app-select-input [label]="group.filterList[i].member.translation"
                                        *ngIf="group.filterList[i].member && group.filterList[i].member.memberType === 'BOOL'"
                                        [(ngModel)]="group.filterList[i].value" [options]="['TRUE', 'FALSE']"
                                        [complex]="true" display="option" [disabled]="!group.filterList[i].operator"
                                        (valueChange)="checkValidFields()"></app-select-input>

                                    <app-date-input [label]="group.filterList[i].member.translation"
                                        [(ngModel)]="group.filterList[i].value"
                                        [disabled]="!group.filterList[i].operator"
                                        *ngIf="group.filterList[i].member && group.filterList[i].member.memberType === 'DATETIME'"
                                        (dateChange)="checkValidFields()"></app-date-input>


                                    <div class="filterBlock__inputsContainer__row"
                                        *ngIf="group.filterList[i].operator && group.filterList[i].operator === 'BETWEEN'">
                                        <app-jj-icons type="minus" [width]="20" height="20"></app-jj-icons>
                                        <app-text-input
                                            *ngIf="group.filterList[i].member && group.filterList[i].member.memberType === 'TEXT'"
                                            [label]="group.filterList[i].member.translation"
                                            [(ngModel)]="group.filterList[i].value2"
                                            [disabled]="!group.filterList[i].operator && !group.filterList[i].value"
                                            (valueChange)="checkValidFields()" [fire]="true"></app-text-input>

                                        <app-number-input
                                            *ngIf="group.filterList[i].member && group.filterList[i].member.memberType === 'NUMERIC'"
                                            [label]="group.filterList[i].member.translation"
                                            [(ngModel)]="group.filterList[i].value2"
                                            [disabled]="!group.filterList[i].operator && !group.filterList[i].value"
                                            (valueChange)="checkValidFields()" [fire]="true"></app-number-input>
                                        <app-select-input [label]="group.filterList[i].member.translation"
                                            *ngIf="group.filterList[i].member && group.filterList[i].member.memberType === 'BOOL'"
                                            [(ngModel)]="group.filterList[i].value2" [options]="['TRUE', 'FALSE']"
                                            [complex]="true" display="option"
                                            [disabled]="!group.filterList[i].operator && !group.filterList[i].value"
                                            (valueChange)="checkValidFields()"></app-select-input>

                                        <app-date-input [label]="group.filterList[i].member.translation"
                                            [(ngModel)]="group.filterList[i].value2"
                                            [disabled]="!group.filterList[i].operator && !group.filterList[i].value"
                                            *ngIf="group.filterList[i].member && group.filterList[i].member.memberType === 'DATETIME'"
                                            (dateChange)="checkValidFields()"></app-date-input>
                                    </div>
                                    <app-jj-icons *ngIf="group.filterList.length > 1" type="trash" [width]="20"
                                        height="20" (click)="deleteParameterRow(groupI, filter)"></app-jj-icons>
                                </div>

                            </app-filter-accordion>
                        </div>

                        <div class="filterBlock__parameterBox" *ngIf="advancedFilter">
                            <span class="filterBlock__parameterBox__button" (click)="addParameter(group, groupI)">
                                <app-jj-icons type="plus" [width]="14" [height]="14"></app-jj-icons>
                                {{ 'EntityFilter.Label.AddParameter' | texttransform }}
                            </span>
                        </div>
                    </div>
                </app-filter-accordion>
            </div>
        </div>

        <!-- OMMITED UNTIL API CAN HANDLE THIS  -->
        <div class="filterBlock__buttonsContainer">
            <app-main-button svg="plus" type="small" label="EntityFilter.Label.AddCriteriaGroup"
                (click)="addGroup(groupI)" *ngIf="advancedFilter"></app-main-button>
        </div>
    </div>
    <div class="entityFilter__header">

        <div class="entityFilter__header__left">
            <app-main-button label="EntityFilter.Label.ResetFilter" type="borderSmall" svg="recycle"
                (onClick)="resetFilter()"></app-main-button>
            <app-main-button [label]="savedFiltersAmount()" type="borderSmall" svg="funnel"
                (onClick)="loadFilter()"></app-main-button>
        </div>

        <div class="entityFilter__header__right">
            <app-main-button [disabled]="!isValid" type="small" svg="magnifying-glass" (onClick)="performSearch()"
                label="EntityTableFilter.Label.Search"></app-main-button>
            <app-main-button [disabled]="!isValid" type="borderSmall" svg="floppy-disk" (onClick)="showSaveFilterPopup = true"
                label="EntityFilter.Label.SaveFilter"></app-main-button>
        </div>
    </div>
</div>
