import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter-accordion',
  templateUrl: './filter-accordion.component.html',
  styleUrl: './filter-accordion.component.scss'
})
export class FilterAccordionComponent {
  @Input() title: string | null = null;
  @Input() isOpen: boolean = false;
  @Input() backgroundColor: string = '#FFFFFF';
  @Input() noDropdown:boolean = false;


  togglePanel(){
    if(this.noDropdown){
      return
    } else {
      this.isOpen= !this.isOpen;
    }
 
  }

}
