import { Component, OnInit, OnDestroy } from '@angular/core';
import { ExternaldatasourceService } from '../../jollyjupiter/service/externaldatasource.service';
import { Router } from '@angular/router';
import { EventService } from '../../jollyjupiter/service/event.service';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { Subscription } from 'rxjs';
import { LoaderService } from '../service/loader-service';
import { LanguageService } from '../translation/language.service';
import { TranslationLanguage } from '../translation/translationLanguage';
import { UserService } from '../../core/authentication/services/user.service';
import { SettingsService, Setting } from '../service/settings.service';
import { ProjectService } from '../../jollyjupiter/service/project.service';
import { ExternalContentService } from 'app/jollyjupiter/service/external-content.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { ApplicationinitService } from 'app/jollyjupiter/service/applicationinit.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit, OnDestroy {
  applicationInitializedEventSubscription: Subscription = new Subscription();
  initRetries = 0;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private applicationinitService: ApplicationinitService,
    private methodService: MethodService
  ) {
  }

  InitApplication() {
    localStorage.removeItem('awstokentokenurl');
    localStorage.removeItem('awstokenclientid');

    // this.applicationinitService.InitApplication();
  }

  ngOnInit() {
    this.InitApplication();    
    this.externaldatasourceService.initializeExternalDataSources();
  }

  ngOnDestroy() {
    if (this.applicationInitializedEventSubscription) { this.applicationInitializedEventSubscription.unsubscribe(); }
  }

}
