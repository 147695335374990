<div *ngIf="mode == 2">
    <div class="dataImportView2">
        <div class="dataImportView2__header">
            <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>

            <div class="dataImportView2__header__nav">
                <div class="dataImportView2__header__buttonsContainer">
                    <app-main-button type="small" label='Import.Label.ImportData' svg="export" (onClick)="showWizard()"></app-main-button>
                    <app-main-button type="small" svg="download-simple" label="Import.Label.Template" (onClick)="exportTemplate()" ></app-main-button>
                </div>

                <app-filter (keyUp)="filterString = $event"></app-filter>
            </div>
        </div>

        <div class="dataImportView2__inner">
            <div class="dataImportView2__content">
                <div class="dataImportView2__content__header">{{ 'Import.Label.ImportedData' | texttransform }}</div>
                <span id="importMainDesign"></span>
                <div class="dataImportView2__content__table">        
                    <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
                        <ng-container matColumnDef="defaultName">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Import.Label.ImportFileName' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element" class="mode2__name">  
                                <label class="ellipsisText" [tippy]="element.defaultName">{{ element.defaultName }}</label>                                            
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Import.Label.ImportDate' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element" class="mode2__date">  
                                <label >{{ element.importDate | date:'medium' }}</label>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="accountDelivered">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Import.Label.AccountDelivered' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">  
                                <div class=""><label >{{ element.deliveredRecords[1]?.recordsDelivered }}</label></div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="accountImported">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Import.Label.AccountImported' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">  
                                <div class=""><label >{{ element.deliveredRecords[1]?.recordsImported }}</label></div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="contactDelivered">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Import.Label.ContactDelivered' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">  
                                <div class=""><label >{{ element.deliveredRecords[0]?.recordsDelivered }}</label></div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="contactImported">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Import.Label.ContactImported' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">  
                                <div class=""><label >{{ element.deliveredRecords[0]?.recordsImported }}</label></div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="notimported">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Import.Label.NotImported' | texttransform }}</th>
                            <td mat-cell *matCellDef="let element">  
                                <div class="">
                                    {{ getNotImportedCount(element) }}
                                    <app-jj-icons
                                    type="export"
                                    *ngIf="getNotImportedCount(element) > 0"
                                    (onClick)="exportData(element)"
                                    [tippy]="'Import.Label.NotImportedDownload' | texttransform"></app-jj-icons>
                <!--                     <i class="fas fa-download fa-lg fingerCursor" *ngIf="getNotImportedCount(element) > 0" [tippy]="'Import.Label.NotImportedDownload' | texttransform" 
                                        (click)="exportData(element)"></i> -->
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseenter)="highlightRow(row.id)" [ngClass]="{             
                            hideInDom: itemIsFiltered(row) == true,
                            'dataImportView2__content__row': row.id === highlightRowID

                        }"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>



<div *ngIf="mode == 1">
    <div style="padding-bottom: 10px;" class="controlInlineBlock">
        <div class="jjControlDefaultColor leadStateHeaderDiv">
            <label style="padding-left: 3px;" class="normalWhiteSpace" *ngIf="importStep==1"
                [tippy]="'Import.Label.ImportStep1Description' | texttransform">{{ 'Import.Label.ImportStep1' |
                texttransform }}</label>
            <label style="padding-left: 3px;" class="normalWhiteSpace" *ngIf="importStep==2"
                [tippy]="'Import.Label.ImportStep2Description' | texttransform">{{ 'Import.Label.ImportStep2' |
                texttransform }}</label>
            <label style="padding-left: 3px;" class="normalWhiteSpace" *ngIf="importStep==3"
                [tippy]="'Import.Label.ImportStep1Description' | texttransform">{{ 'Import.Label.ImportResult' |
                texttransform }}</label>
        </div>
        <div class="jjControlSubmenuColor" style="margin-bottom: 3px">
            <button (click)="eventService.importShowPageEvent.emit(1)" class="jjControlSubmenuButton"
                style="white-space: normal;" *ngIf="importStep != 1"
                [tippy]="'Import.Label.BackToUpload' | texttransform"><i class="fas fa-caret-square-left"></i></button>
            <button class="jjControlSubmenuButton floatRight" [disabled]="importHelperService.importDisabled"
                (click)="importSheet()" *ngIf="importStep != 1 && importHelperService.sheetSelected != false"
                [tippy]="'Import.Label.ImportThisSheet' | texttransform">
                <i class="fas fa-caret-square-right"></i>
            </button>
            <button class="jjControlSubmenuButton floatRight" (click)="importSheet(true)"
                *ngIf="importStep != 1 && importHelperService.sheetSelected != false && applicationInfoService.isDeveloper"
                title="Eine temporäre Tabelle aus dem selektierten Datenblatt erzeugen">
                <i class="fas fa-caret-square-right" style="margin-right: 10px; color: red;"></i>
            </button>
            <button class="jjControlSubmenuButton" (click)="suggestValues()"
                *ngIf="importStep != 1 && importHelperService.sheetSelected != false"
                [tippy]="'Import.Label.SuggestValues' | texttransform">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>

    <div *ngIf="importStep==1">
        <label class="importMainDescriptionLabel">{{ 'Import.Label.ImportStep1Description' | texttransform }}</label>
        <app-import-uploadfile></app-import-uploadfile>
    </div>

    <div *ngIf="importStep==2">
        <app-import-assignement [fileSheets]="fileSheets"></app-import-assignement>
    </div>

    <div *ngIf="importStep==3">
        <div *ngIf="importSuccess">
            <label class="importMainInfoLabel">{{ 'Import.Label.ImportetAccounts' | texttransform }}</label>{{
            importHelperService.importResult['importedAccounts'] }}
            <br>
            <label class="importMainInfoLabel">{{ 'Import.Label.ImportetContacts' | texttransform }}</label>{{
            importHelperService.importResult['importedContacts'] }}
            <br>
            <span *ngIf="importHelperService.importResult['resultCode'] !== 0">
                <label class="importMainInfoLabel">{{ 'Import.Label.ResultCode' | texttransform }}</label>{{
                importHelperService.importResult['resultCode'] }}
                <br>
                <label class="importMainInfoLabel">{{ 'Import.Label.Results' | texttransform }}</label>{{
                importHelperService.importResult['results'] }}
            </span>
        </div>

        <div *ngIf="importSuccess">
            <app-import-details [importGuid]="importHelperService.importResult['guid']"></app-import-details>
        </div>
    </div>
</div>