<div [ngClass]="{ isSubQuestion: isSubQuestion }">
    <div *ngIf="question.isMultiselect" class="questionAnswer">
        <label class="multiSelectWrapper__label">{{ question.defaultName }}</label>
        <div class="multiSelectWrapper">
            <div *ngFor="let answer of answers" [ngClass]="{ hideInDom: answer.isDeleted }">
                <div *ngIf="answer.isDeleted == false">

                    <div class="questionAnswer__checkbox"(click)="toggleValue(questionAnswers[question.id][answer.id])" class="fingerCursor">
                        <app-jj-icons type="check-square" [width]="20" [height]="20" *ngIf="questionAnswers[question.id][answer.id].isSelected == true"></app-jj-icons>
                        <app-jj-icons type="square" [width]="20" [height]="20" *ngIf="questionAnswers[question.id][answer.id].isSelected != true"></app-jj-icons>
                        {{ answer.defaultName }}
                    </div>

                    <div *ngIf="questionAnswers[question.id][answer.id].isSelected == true && questionAnswers[question.id][answer.id].typeLookupId == 1907"
                        class="questionAnswerTextbox">
                        <app-jj-icons type="info" [width]="20" [height]="20"></app-jj-icons>
                        <div>{{ 'QuestionaireDesigner.Label.FreeText' | texttransform }}</div>            
                        <input [(ngModel)]="questionAnswers[question.id][answer.id].answerText" class="maxWidth"
                            (change)="questionaireControlService.updateQuestionAnswer(questionAnswers[question.id][answer.id])">
                    </div>

                    <div *ngIf="questionAnswers[question.id][answer.id].isSelected == true && questionAnswers[question.id][answer.id].typeLookupId == 1908"
                        class="questionAnswerTextbox">
                        <app-jj-icons type="info" [width]="20" [height]="20"></app-jj-icons>
                        <div>{{ 'QuestionaireDesigner.Label.FreeNumber' | texttransform }}</div>            
                        <input [(ngModel)]="questionAnswers[question.id][answer.id].answerText" type="number" class="maxWidth"
                            (change)="questionaireControlService.updateQuestionAnswer(questionAnswers[question.id][answer.id])">
                    </div>
                </div>
            </div>    
        </div>
    </div>

    <div *ngIf="!question.isMultiselect && dropdownAnswers[question.id]" class="questionAnswer questionAnswer__select" [ngClass]="{
        hideInDom: !checkDropdownVisibility()
    }">
        <app-select-input  [label]="question.defaultName | texttransform"  [(ngModel)]="dropdownAnswers[question.id].answerId" 
            [options]="answers" display="defaultName" bind="id" (valueChange)="changeDropdownAnswerChanged(dropdownAnswers[question.id])"
        ></app-select-input>

        <div *ngIf="getAnswerSubQuestions(dropdownAnswers[question.id].answerId).length > 0">
            <div *ngFor="let subquestion of getAnswerSubQuestions(dropdownAnswers[question.id].answerId)">
                <app-questionaire-userquestion [questionnaire]="questionnaire" [question]="subquestion" [isSubQuestion]="true"></app-questionaire-userquestion>
            </div>

        </div>
    </div>

    <div *ngIf="!checkDropdownVisibility()">
        <app-text-input [label]="question.defaultName" [(ngModel)]="dropdownAnswers[question.id].answerText" 
            (valueChange)="changeFreeText()"></app-text-input>
    </div>
    <div *ngIf="dropdownAnswers[question.id] && checkDropdownVisibility()">
        <div *ngIf="!question.isMultiselect && isTextControl(dropdownAnswers[question.id].answerId)" class="questionAnswerTextbox">
            <app-jj-icons type="info" [width]="20" [height]="20"></app-jj-icons>
            <div>{{ 'QuestionaireDesigner.Label.FreeText' | texttransform }}</div>
            <input [(ngModel)]="dropdownAnswers[question.id].answerText" class="maxWidth"
                (change)="changeDropdownAnswerChanged(dropdownAnswers[question.id])">
        </div>    
    </div>
</div>
