import { NgxEchartsModule } from 'ngx-echarts';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { jollyJupiterRoutes } from './jollyjupiter.routing';
import { UsersettingsComponent } from './usersettings/usersettings.component';
import { CustomcontainerComponent } from './controls/custom/custom-container/custom-container.component';
import { CustomUniversalComponent } from './controls/custom/custom-universal/custom-universal.component';
import { DesignerComponent } from './controls/designer/designer.component';
import { FormsModule } from '@angular/forms';
import { ErrormessageComponent } from './controls/static/errormessage/errormessage.component';
import { SharedModule } from '../shared/shared.module';
import { CustomDragdropComponent } from './controls/custom/custom-dragdrop/custom-dragdrop.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProjectusersComponent } from './controls/static/projectusers/projectusers.component';
import { CustomMenuItemComponent } from './controls/custom/custom-menu-item/custom-menu-item.component';
import { CustomDropdownComponent } from './controls/custom/custom-dropdown/custom-dropdown.component';
import { CustomRadioComponent } from './controls/custom/custom-radio/custom-radio.component';
import { CustomButtonComponent } from './controls/custom/custom-button/custom-button.component';
import { CustomCheckboxComponent } from './controls/custom/custom-checkbox/custom-checkbox.component';
import { CustomTextboxComponent } from './controls/custom/custom-textbox/custom-textbox.component';
import { CustomDatepickerComponent } from './controls/custom/custom-datepicker/custom-datepicker.component';
import { CustomInformationpanelComponent } from './controls/custom/custom-informationpanel/custom-informationpanel.component';
import { CustomPopupComponent } from './controls/custom/custom-popup/custom-popup.component';
import { JollyjupiterComponent } from './jollyjupiter.component';
import { CustomListviewComponent } from './controls/custom/custom-listview/custom-listview.component';
import { CustomLabelComponent } from './controls/custom/custom-label/custom-label.component';
import { ProjectselectionComponent } from './controls/static/projectselection/projectselection.component';
import { DebugpanelComponent } from './controls/static/debugpanel/debugpanel.component';
import { WebphoneComponent } from './webphone/webphone/webphone.component';
import { ActivityComponent } from './webphone/activity/activity.component';
import { LargeMenuItemComponent } from './controls/custom/large-menu-item/large-menu-item.component';
import { AccountComponent } from './controls/static/account/account.component';
import { CustomDialComponent } from './controls/custom/custom-dial/custom-dial.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { QRCodeModule } from 'angularx-qrcode';


// PrimeNG
// import { ToastModule } from 'primeng/toast';
import { MenuModule} from 'primeng/menu';
import { TabViewModule} from 'primeng/tabview';
import { CheckboxModule} from 'primeng/checkbox';
import { DialogModule} from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { CarouselModule } from 'primeng/carousel';

// Material
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';

// Owl
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

// Pipes
import { ObjectDeserializeService } from './pipe/object-deserialize.service';
import { TextTransformService } from './pipe/text-transform.service';

import { CustomTextareaComponent } from './controls/custom/custom-textarea/custom-textarea.component';
import { CustomRegisterComponent } from './controls/custom/custom-register/custom-register.component';
import { CustomWebsiteComponent } from './controls/custom/custom-website/custom-website.component';
import { CustomResearchComponent } from './controls/custom/custom-research/custom-research.component';
import { CustomPowerbuttonComponent } from './controls/custom/custom-powerbutton/custom-powerbutton.component';
import { CustomCompactlistComponent } from './controls/custom/custom-compactlist/custom-compactlist.component';
import { BrakepopupComponent } from './controls/static/brakepopup/brakepopup.component';
import { CustomerManagementComponent } from './controls/static/customer-management/customer-management.component';
import { CustomSpacerComponent } from './controls/custom/custom-spacer/custom-spacer.component';
import { CustomResultpreviewComponent } from './controls/custom/custom-resultpreview/custom-resultpreview.component';
import { CustomWebphonePopupComponent } from './controls/custom/custom-webphone-popup/custom-webphone-popup.component';
import { AutomaticRecordAssignmentComponent } from './controls/static/automatic-record-assignment/automatic-record-assignment.component';
import { CustomHorizontalRuleComponent } from './controls/custom/custom-horizontal-rule/custom-horizontal-rule.component';
import { ProjectManagementComponent } from './controls/static/developer/project-management/project-management.component';
import { DatafilterMainpanelComponent } from './controls/static/datafilter/datafilter-mainpanel/datafilter-mainpanel.component';
import { DatafilterGroupitemComponent } from './controls/static/datafilter/datafilter-groupitem/datafilter-groupitem.component';
import { DatafilterGroupitemMemberComponent } from './controls/static/datafilter/datafilter-groupitem-member/datafilter-groupitem-member.component';
import { ExportFormComponent } from './controls/static/export-form/export-form.component';
import { CustomQuestionnaireComponent } from './controls/custom/custom-questionnaire/custom-questionnaire.component';
import { MyleadsComponent } from './controls/static/myleads/myleads.component';
import { LeadreportComponent } from './controls/static/leadreport/leadreport.component';
import { CustomSectionheaderComponent } from './controls/custom/custom-sectionheader/custom-sectionheader.component';
import { CustomMenubarComponent } from './controls/custom/custom-menubar/custom-menubar.component';
import { NoTaskAvailableComponent } from './controls/static/no-task-available/no-task-available.component';
import { CustomSubresultComponent } from './controls/custom/custom-subresult/custom-subresult.component';
import { CustomSubresultItemComponent } from './controls/custom/custom-subresult/custom-subresult-item/custom-subresult-item.component';
import { CreateTaskMainComponent } from './controls/static/task-creation/create-task-main/create-task-main.component';
import { CreateTaskFinalizeComponent } from './controls/static/task-creation/create-task-finalize/create-task-finalize.component';
import { ExtendedButtonComponent } from './controls/extended/extended-button/extended-button.component';
import { ExtendedSpinnerComponent } from './controls/extended/extended-spinner/extended-spinner.component';
import { ExtendedDropdownComponent } from './controls/extended/extended-dropdown/extended-dropdown.component';
import { OptinoptoutComponent } from './controls/static/optinoptout/optinoptout.component';
import { AppointmentViewComponent } from './controls/static/appointment-view/appointment-view.component';
import { DatafilterGroupitemMemberDetailsComponent } from './controls/static/datafilter/datafilter-groupitem-member-details/datafilter-groupitem-member-details.component';
import { CustomCommunicationGuidelineComponent } from './controls/custom/custom-communication-guideline/custom-communication-guideline.component';
import { SupervisorMainComponent } from './controls/static/supervisor/supervisor-main/supervisor-main.component';
import { ReportingComponent } from './controls/static/reporting/reporting.component';
import { RedirectComponent } from './redirect/redirect.component';
import { UsergroupsComponent } from './controls/static/supervisor/usergroups/usergroups.component';
import { QuicksearchComponent } from './controls/static/quicksearch/quicksearch.component';
import { DesignTranslationComponent } from './controls/designer/design-translation/design-translation.component';
import { DeveloperMainpanelComponent } from './controls/static/developer/developer-mainpanel/developer-mainpanel.component';
import { LeadstateComponent } from './controls/static/supervisor/leadstate/leadstate.component';
import { JjPopupComponent } from './controls/static/jj-popup/jj-popup.component';
import { JjPopupItemComponent } from './controls/static/jj-popup/jj-popup-item/jj-popup-item.component';
import { ProcessComponent } from './controls/static/supervisor/process/process.component';
import { AccountPopupComponent } from './controls/static/account-popup/account-popup.component';
import { TranslateTokenPopupComponent } from './controls/static/supervisor/translate-token-popup/translate-token-popup.component';
import { CampaignComponent } from './controls/static/supervisor/campaign/campaign.component';
import { CustomProjectoverviewComponent } from './controls/custom/custom-projectoverview/custom-projectoverview.component';
import { AdminMainComponent } from './controls/admin/admin-main/admin-main.component';
import { AdminCustomerComponent } from './controls/admin/admin-customer/admin-customer.component';
import { QuestionanswersComponent } from './controls/static/supervisor/questionanswers/questionanswers.component';
import { QuestionanswersItemComponent } from './controls/static/supervisor/questionanswers/questionanswers-item/questionanswers-item.component';
import { UicontrolDesignComponent } from './controls/designer/controls/uicontrol-design/uicontrol-design.component';
import { AdminEntityComponent } from './controls/admin/admin-entity/admin-entity.component';
import { DecimalToTimePipe } from 'app/shared/pipes/decimal-to-time.pipe';
import { UserRightsComponent } from './controls/admin/user-rights/user-rights.component';
import { ControlDesignAdminComponent } from './controls/designer/controls/control-design-admin/control-design-admin.component';
import { NonDatabaseProjectDashboardComponent } from './controls/static/non-database-project-dashboard/non-database-project-dashboard.component';
import { ImportMainComponent } from './controls/static/supervisor/import/import-main/import-main.component';
import { ImportUploadfileComponent } from './controls/static/supervisor/import/import-uploadfile/import-uploadfile.component';
import { ImportAssignementComponent } from './controls/static/supervisor/import/import-assignement/import-assignement.component';
import { ImportHelptableViewComponent } from './controls/static/supervisor/import/import-helptable-view/import-helptable-view.component';
import { NewLogicalEntityComponent } from './controls/static/myleads/new-logical-entity/new-logical-entity.component';
import { AdminJobsComponent } from './controls/admin/admin-jobs/admin-jobs.component';
import { AdminReportsComponent } from './controls/admin/admin-reports/admin-reports.component';
import { NewaccountComponent } from './controls/static/newaccount/newaccount.component';
import { UicontainerDesignComponent } from './controls/designer/controls/uicontainer-design/uicontainer-design.component';
import { TranslationButtonComponent } from './controls/static/translation-button/translation-button.component';
import { ControlsDesignMainComponent } from './controls/designer/controls/controls-design-main/controls-design-main.component';
import { ControlsDesignLookupTableComponent } from './controls/designer/controls/controls-design-lookup-table/controls-design-lookup-table.component';
import { ImportDetailsComponent } from './controls/static/supervisor/import/import-details/import-details.component';
import { UistyleComponent } from './controls/static/supervisor/uistyle/uistyle.component';
import { DatadeliveryComponent } from './controls/static/supervisor/datadelivery/datadelivery.component';
import { AdminJobsScheduleComponent } from './controls/admin/admin-jobs-schedule/admin-jobs-schedule.component';
import { AdminQueryBuilderComponent } from './controls/admin/admin-reports/admin-query-builder/admin-query-builder.component';
import { AdminReportParameterComponent } from './controls/admin/admin-reports/admin-report-parameter/admin-report-parameter.component';
import { UicontrolDesignAttributeGroupsComponent } from './controls/designer/controls/uicontrol-design-attribute-groups/uicontrol-design-attribute-groups.component';
import { AttributeModifierOverviewComponent } from './controls/static/supervisor/attribute-modifier-overview/attribute-modifier-overview.component';
import { UiControlDesignSecurityPermissionComponent } from './controls/designer/ui-control-design-security-permission/ui-control-design-security-permission.component';
import { LeadreportsettingsComponent } from './controls/static/supervisor/leadreportsettings/leadreportsettings.component';
import { AdminFtpComponent } from './controls/admin/admin-ftp/admin-ftp.component';
import { NewtaskComponent } from './controls/static/newtask/newtask.component';
import { CustomCopytoclipboardButtonComponent } from './controls/custom/custom-copytoclipboard-button/custom-copytoclipboard-button.component';
import { MaterialModule } from 'app/material/material.module';
import { AccountFooterComponent } from './controls/static/account-footer/account-footer.component';
import { ProspectComponent } from './controls/static/prospect/prospect.component';
import { ConversionComponent } from './controls/static/conversion/conversion.component';
// import { SoftphoneComponent } from './controls/static/softphone/softphone.component';
import { CustomThermometerComponent } from './controls/custom/custom-thermometer/custom-thermometer.component';
// import { MatIconModule, MatSortModule } from '@angular/material';
import { ProspectDetailComponent } from './controls/static/prospect/prospect-detail.component';
import { CustomMattableComponent } from './controls/custom/custom-mattable/custom-mattable.component';
import { AccountsComponent } from './controls/static/accounts/accounts.component';
import { ContactsComponent } from './controls/static/contacts/contacts.component';
import { UicontrolDesignMethodsComponent } from './controls/designer/controls/uicontrol-design-methods/uicontrol-design-methods.component';
import { MocoAssignmentComponent } from './controls/static/moco-assignment/moco-assignment.component';
import { SingleactionComponent } from './controls/static/singleaction/singleaction.component';

import { ConversionListComponent } from './controls/static/conversion-list/conversion-list.component';
import { CustomLeadscoreComponent } from './controls/custom/custom-leadscore/custom-leadscore.component';
import { MyFollowupsComponent } from './controls/static/my-followups/my-followups.component';
import { AdminExternalDatasourcesComponent } from './controls/admin/admin-external-datasources/admin-external-datasources.component';
import { OpportunityComponent } from './controls/static/opportunity/opportunity.component';
import { DashboardmainComponent } from './controls/static/dashboardmain/dashboardmain.component';
import { LeadscoringDetailComponent } from './controls/static/leadscoring-detail/leadscoring-detail.component';
import { AuditComponent } from './controls/static/supervisor/audit/audit.component';
import { DocumentationInfoComponent } from './controls/static/documentation-info/documentation-info.component';
import { UicontrolDesignAttributemodifierComponent } from './controls/designer/controls/uicontrol-design-attributemodifier/uicontrol-design-attributemodifier.component';
import { CognitocallbackComponent } from './cognito/cognitocallback/cognitocallback.component';
import { IconLeadscoreComponent } from './controls/icons/icon-leadscore/icon-leadscore.component';
import { CustomOptinoptoutComponent } from './controls/custom/custom-optinoptout/custom-optinoptout.component';
import { ContainerViewComponent } from './controls/static/container-view/container-view.component';

import { CustomDiagramComponent } from './controls/custom/custom-diagram/custom-diagram.component';
import { BoldbiDashboardComponent } from './boldbi/boldbi-dashboard/boldbi-dashboard.component';
import { SplitCamelcasePipe } from './pipe/split-camelcase.pipe';
import { NgChartsModule } from 'ng2-charts';
import { HeaderInformationComponent } from './controls/static/header-information/header-information.component';
import { RingcentralPopupComponent } from './controls/static/ringcentral-popup/ringcentral-popup.component';
import { NewactivityComponent } from './controls/static/newactivity/newactivity.component';
import { CustomActivityListComponent } from './controls/custom/custom-activity-list/custom-activity-list.component';
import { CustomTimelineComponent }  from './controls/custom/custom-timeline/custom-timeline.component';
import { CustomPaletteComponent } from './controls/custom/custom-palette/custom-palette.component';
import { LeadscoringDetailPotentialComponent } from './controls/static/leadscoring-detail/leadscoring-detail-potential/leadscoring-detail-potential.component';
import { CustomMicroCampaignsComponent } from './controls/custom/custom-micro-campaigns/custom-micro-campaigns.component';
import { BoldbiDashboardlistComponent } from './boldbi/boldbi-dashboardlist/boldbi-dashboardlist.component';
import { RingcentralRedirectComponent } from './controls/static/ringcentral-redirect/ringcentral-redirect.component';
import { GeneralsettingsComponent } from './controls/static/supervisor/generalsettings/generalsettings.component';
import { BlacklistComponent } from './controls/static/blacklist/blacklist.component';
import { EmailtemplatesComponent } from './controls/static/supervisor/emailtemplates/emailtemplates.component';
import { EmailcontentComponent } from './controls/static/supervisor/emailcontent/emailcontent.component';
import { MailacceptComponent } from './controls/noproject/mailaccept/mailaccept.component';
import { CustomEmaileditorComponent } from './controls/custom/custom-emaileditor/custom-emaileditor.component';
import { MocoprojectassignmentComponent } from './controls/static/mocoprojectassignment/mocoprojectassignment.component';
import { EmailtemplateService } from './service/emailtemplate.service';
import { ExternalusersComponent } from './controls/static/supervisor/externalusers/externalusers.component';
import { BookingComponent } from './controls/static/booking/booking.component';
import { AssignToListComponent } from './controls/static/assign-to-list/assign-to-list.component';
import { BannerComponent } from './controls/static/banner/banner.component';
import { VideoplayerComponent } from './controls/static/videoplayer/videoplayer.component';
import { CompactTasklistComponent } from './controls/static/compact-tasklist/compact-tasklist.component';
import { EventtriggerComponent } from './controls/static/supervisor/eventtrigger/eventtrigger.component';
import { SendmailitemsComponent } from './controls/static/supervisor/sendmailitems/sendmailitems.component';
import { TrialextensionComponent } from './controls/noproject/trialextension/trialextension.component';
import { CustomEmailComponent } from './controls/custom/custom-email/custom-email.component';
import { CustomContactheaderComponent } from './controls/custom/custom-contactheader/custom-contactheader.component';
import { AdminEntityValidityComponent } from './controls/admin/admin-entity/admin-entity-validity/admin-entity-validity.component';
import { WizardComponent } from './controls/static/wizard/wizard.component';
import { WizardCampaignComponent } from './controls/static/wizard/wizard-campaign/wizard-campaign.component';
import { WizardLeadreportsettingsComponent } from './controls/static/wizard/wizard-leadreportsettings/wizard-leadreportsettings.component';
import { ConversioneditorComponent } from './controls/static/conversioneditor/conversioneditor.component';
import { CustomEventinfoComponent } from './controls/custom/custom-eventinfo/custom-eventinfo.component';
import { WizardLeadstateComponent } from './controls/static/wizard/wizard-leadstate/wizard-leadstate.component';
import { WizardTranslationsComponent } from './controls/static/wizard/wizard-translations/wizard-translations.component';
import { UsermanagementComponent } from './controls/static/usermanagement/usermanagement.component';
import { WizardWorkflowComponent } from './controls/static/wizard/wizard-workflow/wizard-workflow.component';
import { BannerBorderitemComponent } from './controls/static/banner/banner-borderitem/banner-borderitem.component';
import { ConversiondetailComponent } from './controls/static/conversioneditor/conversiondetail/conversiondetail.component';
import { NewcontactComponent } from './controls/static/newcontact/newcontact.component';
import { EmailreviewComponent } from './controls/static/emailreview/emailreview.component';
import { VipComponent } from './controls/static/vip/vip.component';
import { CustomMultilistComponent } from './controls/custom/custom-multilist/custom-multilist.component';
import { DocumentationLinkComponent } from './controls/static/documentation-link/documentation-link.component';
import { UicontrolValueitemComponent } from './controls/designer/controls/uicontrol-valueitem/uicontrol-valueitem.component';
import { UicontrolSubcontrolComponent } from './controls/designer/controls/uicontrol-subcontrol/uicontrol-subcontrol.component';
import { CustomMynextfollowupComponent } from './controls/custom/custom-mynextfollowup/custom-mynextfollowup.component';
import { IncomingcallComponent } from './controls/static/incomingcall/incomingcall.component';
import { BookingPreviewComponent } from './controls/static/booking-preview/booking-preview.component';
import { WizardStepresultComponent } from './controls/static/wizard/wizard-stepresult/wizard-stepresult.component';
import { WizardStepComponent } from './controls/static/wizard/wizard-step/wizard-step.component';
import { DatarightsComponent } from './controls/static/datarights/datarights.component';
import { DatarightsItemComponent } from './controls/static/datarights/datarights-item/datarights-item.component';
import { PoolsComponent } from './controls/static/supervisor/pools/pools.component';
import { WizardDatarightsgroupComponent } from './controls/static/wizard/wizard-datarightsgroup/wizard-datarightsgroup.component';
import { WizardInfobuttonComponent } from './controls/static/wizard/wizard-infobutton/wizard-infobutton.component';
import { ControlDesignExternaldatasourceComponent } from './controls/designer/control-design-externaldatasource/control-design-externaldatasource.component';
import { WizardTranslationPopupComponent } from './controls/static/wizard/wizard-translation-popup/wizard-translation-popup.component';
import { DatarightsInfoComponent } from './controls/static/datarights/datarights-info/datarights-info.component';
import { LogicalEntitiesComponent } from './controls/admin/logical-entities/logical-entities.component';
import { WizardEmailcontentComponent } from './controls/static/wizard/wizard-emailcontent/wizard-emailcontent.component';
import { WizardEmailtemplateComponent } from './controls/static/wizard/wizard-emailtemplate/wizard-emailtemplate.component';
import { WizardEntitymemberComponent } from './controls/static/wizard/wizard-entitymember/wizard-entitymember.component';
import { WizardDataimportComponent } from './controls/static/wizard/wizard-dataimport/wizard-dataimport.component';
import { WizardMocoComponent } from './controls/static/wizard/wizard-moco/wizard-moco.component';
import { AjtrackerComponent } from './controls/static/supervisor/ajtracker/ajtracker.component';
import { EventlistComponent } from './controls/static/supervisor/eventlist/eventlist.component';
import { AdminScoringparameterComponent } from './controls/admin/admin-scoringparameter/admin-scoringparameter.component';
import { AdminScoringcontenttypeComponent } from './controls/admin/admin-scoringcontenttype/admin-scoringcontenttype.component';
import { AdminUicontrolmethodparameterinformationComponent } from './controls/admin/admin-uicontrolmethodparameterinformation/admin-uicontrolmethodparameterinformation.component';
import { WizardEventassignmentComponent } from './controls/static/wizard/wizard-eventassignment/wizard-eventassignment.component';
import { SesComponent } from './controls/static/supervisor/ses/ses.component';
import { WizardDataimportDatalistComponent } from './controls/static/wizard/wizard-dataimport/wizard-dataimport-datalist/wizard-dataimport-datalist.component';
import { AdminFilterComponent } from './controls/admin/admin-filter/admin-filter.component';
import { AdminManagerightsComponent } from './controls/admin/admin-managerights/admin-managerights.component';
import { MatTableSupervisorComponent } from './controls/custom/custom-mattable/mat-table-supervisor/mat-table-supervisor.component';
import { EmailbatchdetailsComponent } from './controls/static/emailbatchdetails/emailbatchdetails.component';
import { CustomerapiComponent } from './controls/static/supervisor/customerapi/customerapi.component';
import { SmtpsettingsComponent } from './controls/static/supervisor/smtpsettings/smtpsettings.component';
import { EmaileditorSyncComponent } from './controls/static/emaileditor-sync/emaileditor-sync.component';
import { WizardEmailsettingsComponent } from './controls/static/wizard/wizard-emailsettings/wizard-emailsettings.component';
import { WizardMailshotComponent } from './controls/static/wizard/wizard-mailshot/wizard-mailshot.component';
import { SettingRequiredComponent } from './controls/static/setting-required/setting-required.component';
import { MailshotsComponent } from './controls/static/mailshots/mailshots.component';
import { AdminAwsidentitiesComponent } from './controls/admin/admin-awsidentities/admin-awsidentities.component';
import { HtmleditorComponent } from './controls/static/htmleditor/htmleditor.component';
import { UsermailboxComponent } from './controls/static/usermailbox/usermailbox.component';
import { WizardMailtocontactComponent } from './controls/static/wizard/wizard-mailtocontact/wizard-mailtocontact.component';
import { WizardEventtriggerComponent } from './controls/static/wizard/wizard-eventtrigger/wizard-eventtrigger.component';
import { WizardMailshotFinalComponent } from './controls/static/wizard/wizard-mailshot-final/wizard-mailshot-final.component';
import { ConsentManagementComponent } from './controls/static/supervisor/consent-management/consent-management.component';
import { WizardEmailSmtpcontentComponent } from './controls/static/wizard/wizard-email-smtpcontent/wizard-email-smtpcontent.component';
import { EmaildistributorComponent } from './controls/static/supervisor/emaildistributor/emaildistributor.component';
import { WizardBlacklistimportComponent } from './controls/static/wizard/wizard-blacklistimport/wizard-blacklistimport.component';
import { ProjectstartpageComponent } from './controls/static/projectstartpage/projectstartpage.component';
import { BreadcrumbandmenuComponent } from './controls/static/breadcrumbandmenu/breadcrumbandmenu.component';
import { ContentinfoComponent } from './controls/static/support/contentinfo/contentinfo.component';
import { CustomAccordeonComponent } from './controls/custom/custom-accordeon/custom-accordeon.component';
import { UserdashboardComponent } from './controls/static/userdashboard/userdashboard.component';
import { ScrollableDirective } from './controls/custom/custom-register/scrollable.directive';
import { AdminDashboardsComponent } from './controls/admin/admin-dashboards/admin-dashboards.component';
import { TelekomcreatetakeComponent } from './controls/projectspecific/telekomcreatetake/telekomcreatetake.component';
import { ProcessvisualComponent } from './controls/static/processvisual/processvisual.component';
import { ProcessvisualitemComponent } from './controls/static/processvisualitem/processvisualitem.component';
import { EntitytableComponent } from './controls/custom/entitytable/entitytable.component';
import { EntitytableDesignerComponent } from './controls/custom/entitytable/entitytable-designer/entitytable-designer.component';
import { EntitytableValuecontrolComponent } from './controls/custom/entitytable/entitytable-valuecontrol/entitytable-valuecontrol.component';
import { CustomDashoardcontrolComponent } from './controls/custom/custom-dashoardcontrol/custom-dashoardcontrol.component';
import { CustomDashoardcontrolMicrocampaignsComponent } from './controls/custom/custom-dashoardcontrol/custom-dashoardcontrol-microcampaigns/custom-dashoardcontrol-microcampaigns.component';
import { CustomDasboardcontrolDashboardComponent } from './controls/custom/custom-dashoardcontrol/custom-dashboardcontrol-dashboard/custom-dashboardcontrol-dashboard.component';
import { CustomDashboardcontrolUserviplistComponent } from './controls/custom/custom-dashoardcontrol/custom-dashboardcontrol-userviplist/custom-dashboardcontrol-userviplist.component';
import { CustomDashboardcontrolBestnextcontactComponent } from './controls/custom/custom-dashoardcontrol/custom-dashboardcontrol-bestnextcontact/custom-dashboardcontrol-bestnextcontact.component';
import { AdminAipromptsComponent } from './controls/admin/admin-aiprompts/admin-aiprompts.component';
import { CustomDashboardcontrolOptinoptoutComponent } from './controls/custom/custom-dashoardcontrol/custom-dashboardcontrol-optinoptout/custom-dashboardcontrol-optinoptout.component';
import { CustomAipromptresultComponent } from './controls/custom/custom-aipromptresult/custom-aipromptresult.component';
import { RingcentralWebrtcRedirectComponent } from './controls/static/ringcentral-webrtc-redirect/ringcentral-webrtc-redirect.component';
import { AccountGroupComponent } from './controls/static/account-group/account-group.component';
import { CallednumberhistoryComponent } from './controls/static/callednumberhistory/callednumberhistory.component';
import { VoicescriptcontrolComponent } from './controls/static/voicescriptcontrol/voicescriptcontrol.component';
import { WizardVoicetemplatesComponent } from './controls/static/wizard/wizard-voicetemplates/wizard-voicetemplates.component';
import { CustomAccountownerComponent } from './controls/custom/custom-accountowner/custom-accountowner.component';
import { NavigationdesignComponent } from './controls/static/navigationdesign/navigationdesign.component';
import { CampaigndashboardComponent } from './controls/dashboards/campaigndashboard/campaigndashboard.component';
import { LeadmanagementdashboardComponent } from './controls/dashboards/leadmanagementdashboard/leadmanagementdashboard.component';
import { WebsitedashboardComponent } from './controls/dashboards/websitedashboard/websitedashboard.component';
import { ConsentmanagementdashboardComponent } from './controls/dashboards/consentmanagementdashboard/consentmanagementdashboard.component';
import { ConsentmanagementdashboardItemComponent } from './controls/dashboards/consentmanagementdashboard/consentmanagementdashboard-item/consentmanagementdashboard-item.component';
import { PieGraphComponent } from './controls/dashboards/pie-graph/pie-graph.component';
import { GeneralsettingsprojecttargetComponent } from './controls/static/supervisor/generalsettings/generalsettingsprojecttarget/generalsettingsprojecttarget.component';
import { ContentlibraryComponent } from './controls/static/contentlibrary/contentlibrary.component';
import { ContentlibraryItemComponent } from './controls/static/contentlibrary/contentlibrary-item/contentlibrary-item.component';
import { VipactivityComponent } from './controls/static/vipactivity/vipactivity.component';
import { AileadscoringComponent } from './controls/static/aileadscoring/aileadscoring.component';
import { ToolbarComponent } from './controls/static/toolbar/toolbar.component';
import { StartpagedashboardComponent } from './controls/dashboards/startpagedashboard/startpagedashboard.component';
import { DashbdashboardcomponentMydayComponent } from './controls/dashboards/dashboardcomponents/dashbdashboardcomponent-myday/dashbdashboardcomponent-myday.component';
import { DashbdashboardcomponentKpisComponent } from './controls/dashboards/dashboardcomponents/dashbdashboardcomponent-kpis/dashbdashboardcomponent-kpis.component';
import { DashbdashboardcomponentMytopcontactsComponent } from './controls/dashboards/dashboardcomponents/dashbdashboardcomponent-mytopcontacts/dashbdashboardcomponent-mytopcontacts.component';
import { DashbdashboardcomponentMyvisitorsComponent } from './controls/dashboards/dashboardcomponents/dashbdashboardcomponent-myvisitors/dashbdashboardcomponent-myvisitors.component';
import { DashbdashboardcomponentMytoppageviewersComponent } from './controls/dashboards/dashboardcomponents/dashbdashboardcomponent-mytoppageviewers/dashbdashboardcomponent-mytoppageviewers.component';
import { DashboardcomponentMyconversionsComponent } from './controls/dashboards/dashboardcomponents/dashboardcomponent-myconversions/dashboardcomponent-myconversions.component';
import { QualitymanagementComponent } from './controls/static/qualitymanagement/qualitymanagement.component';
import { QmQuestionanswersComponent } from './controls/static/qualitymanagement/qm-questionanswers/qm-questionanswers.component';
import { ExternalfiledragcontrolComponent } from './controls/static/externalfiledragcontrol/externalfiledragcontrol.component';
import { MydayappointmentComponent } from './controls/dashboards/dashboardcomponents/dashbdashboardcomponent-myday/mydayappointment/mydayappointment.component';
import { ToolbarPopupComponent } from './controls/static/toolbar/toolbar-popup/toolbar-popup.component';
import { ToolbarPhoneComponent } from './controls/static/toolbar/toolbar-phone/toolbar-phone.component';

import { PieChartComponent } from './controls/dashboards/charts/pie-chart/pie-chart.component';
import { chartModule } from './controls/dashboards/charts/chartModule';
import { BookingImageComponent } from './controls/static/booking/booking-image/booking-image.component';
import { CustomMicrocampaignCancelComponent } from './controls/custom/custom-micro-campaigns/custom-microcampaign-cancel/custom-microcampaign-cancel.component';
import { BarChartComponent } from './controls/dashboards/charts/bar-chart/bar-chart.component';
import { CreatedemoaccountComponent } from './controls/static/createdemoaccount/createdemoaccount.component';
import { DashboardcomponentLeadstatesComponent } from './controls/dashboards/dashboardcomponents/dashboardcomponent-leadstates/dashboardcomponent-leadstates.component';
import { EchartsComponent } from './controls/dashboards/charts/echarts/echarts.component';
import { CustomCalculateleadstateComponent } from './controls/custom/custom-calculateleadstate/custom-calculateleadstate.component';
import { FlowchartComponent } from './controls/dashboards/charts/flowchart/flowchart.component';
import { CloudconnectionsComponent } from './controls/static/cloudconnections/cloudconnections.component';
import { CacheresetComponent } from './controls/static/cachereset/cachereset.component';
/* import { TooltipComponent } from './controls/static/tooltip/tooltip.component'; */
import { TooltipDirective } from './controls/static/tooltip/tooltip.directive';
import { CloudconnectionLoginComponent } from './controls/static/cloudconnections/cloudconnection-login/cloudconnection-login.component';
import { MicrocampaignbatchstarterComponent } from './controls/static/microcampaignbatchstarter/microcampaignbatchstarter.component';
import { EventbookingbatchComponent } from './controls/static/eventbookingbatch/eventbookingbatch.component';
import { ProcessvisualPopupComponent } from './controls/static/processvisual-popup/processvisual-popup.component';
import { WizardMicrocampaignComponent } from './controls/static/wizard/wizard-microcampaign/wizard-microcampaign.component';
import { OptinoptouteditorComponent } from './controls/static/optinoptouteditor/optinoptouteditor.component';
import { QuestionaireControlComponent } from './controls/static/questionaire-control/questionaire-control.component';
import { QuestionaireDesignerControlComponent } from './controls/static/questionaire-control/questionaire-designer-control/questionaire-designer-control.component';
import { QuestionaireDesignerQuestioncontrolComponent } from './controls/static/questionaire-control/questionaire-designer-questioncontrol/questionaire-designer-questioncontrol.component';
import { QuestionaireUserquestionComponent } from './controls/static/questionaire-control/questionaire-userquestion/questionaire-userquestion.component';
import { LookuptablesComponent } from './controls/static/lookuptables/lookuptables.component';
import { CustomOptinoverviewComponent } from './controls/custom/custom-optinoverview/custom-optinoverview.component';
import { ExpandflowchartComponent } from './controls/dashboards/charts/flowchart/expandflowchart/expandflowchart.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { TippyTooltipComponent } from './controls/static/tippy-tooltip/tippy-tooltip.component';
import { TippyDirective } from './controls/static/tippy-tooltip/tippy.directive';

import { ContolsDesignSettingsComponent } from './controls/designer/controls/contols-design-settings/contols-design-settings.component';
import { AdminJobCheckResultComponent } from './controls/admin/admin-jobs/admin-job-check-result/admin-job-check-result.component';
import { NewversionavailableComponent } from './controls/static/newversionavailable/newversionavailable.component';
import { CustomPaletteEditorComponent } from './controls/custom/custom-palette/custom-palette-editor/custom-palette-editor.component';
import { OnlynumberDirective } from './directive/onlynumber.directive';
import { CallprojectbookingComponent } from './controls/static/callprojectbooking/callprojectbooking.component';
import { AwsloginComponent } from './controls/static/awslogin/awslogin.component';
import { CustomMultiSelectComponent } from './controls/custom/custom-multi-select/custom-multi-select.component';
import { CustomTableFilterComponent } from './controls/custom/custom-multi-select/custom-table-filter/custom-table-filter.component';
import { ControleditLightComponent } from './controls/designer/controledit-light/controledit-light.component';
import { CustomContactleadstateoverviewComponent } from './controls/custom/custom-contactleadstateoverview/custom-contactleadstateoverview.component';
import { CustomContactleadstateoverviewDetailsComponent } from './controls/custom/custom-contactleadstateoverview/custom-contactleadstateoverview-details/custom-contactleadstateoverview-details.component';
import { CustomEntityfileuploadComponent } from './controls/custom/custom-entityfileupload/custom-entityfileupload.component';
import { LeadScoringchartComponent } from './controls/static/leadscoring-detail/leadScoringChart/lead-scoringchart.component';
import { LeadScoringlinechartComponent } from './controls/dashboards/charts/leadscoring/leadscoring-detail/lead-scoring-line-chart.component';
import { FollowupsComponent } from './controls/static/followups/followups.component';
import { FollowUpTaskChartComponent } from './controls/dashboards/charts/follow-up-task-chart/follow-up-task-chart.component';
import { FollowuplistComponent } from './controls/static/followups/followuplist/followuplist.component';
import { FeaturesComponent } from './controls/static/features/features.component';
import { FeaturessettingsComponent } from './controls/static/featuressettings/featuressettings.component';
import { FeaturessettingsSliderComponent } from './controls/static/featuressettings/featuressettings-slider/featuressettings-slider.component';

// icons for file
import { IconFileComponent } from './controls/icons/icon-file-types/icon-file.component';
import { FeaturespackagessettingsComponent } from './controls/static/featurespackagessettings/featurespackagessettings.component';
import { MainscreenComponent } from './controls/static/mainscreen/mainscreen.component';
import { ProjectpackagesComponent } from './controls/static/projectpackages/projectpackages.component';

import { ContactProtectionComponent } from './controls/static/contact-protection/contact-protection.component';
import { ContactProtectionPopupComponent } from './controls/static/contact-protection/contact-protection-popup/contact-protection-popup.component';
import { CustomAccountOverviewComponent } from './controls/custom/custom-account-overview/custom-account-overview.component';
import { CardComponent } from './controls/static/card/card.component';
import { RatingbarComponent } from './controls/static/ratingbar/ratingbar.component';
import { JjIconsComponent } from './controls/jj-icons/jj-icons.component';
import { BlueMarkerComponent } from './controls/shared/blue-marker/blue-marker.component';
import { ComponentHeaderComponent } from './controls/shared/component-header/component-header.component';
import { ToolbarNavComponent } from './controls/static/toolbar/toolbar-nav/toolbar-nav.component';
import { TextInputComponent } from './controls/shared/fields/text-input/text-input.component';
import { BrowserModule } from '@angular/platform-browser';
import { SelectInputComponent } from './controls/shared/fields/select-input/select-input.component';
import { CheckboxComponent } from './controls/shared/fields/checkbox/checkbox.component';
import { RadioButtonComponent } from './controls/shared/fields/radio-button/radio-button.component';
import { MainButtonComponent } from './controls/shared/main-button/main-button.component';
import { TabComponent } from './controls/shared/tab/tab.component';
import { StatusComponent } from './controls/shared/status/status.component';
import { LayoutComponent } from './controls/shared/layout/layout.component';
import { BarLeadscoreComponent } from './controls/shared/bar-leadscore/bar-leadscore.component';
import { MytopcontactComponent } from "./controls/dashboards/dashboardcomponents/dashbdashboardcomponent-mytopcontacts/mytopcontact/mytopcontact.component";
import { DashdashboardcomponentUpdatesComponent } from './controls/dashboards/dashboardcomponents/dashdashboardcomponent-updates/dashdashboardcomponent-updates.component';
import { TextAreaInputComponent } from './controls/shared/fields/text-area-input/text-area-input.component';
import { NumberInputComponent } from './controls/shared/fields/number-input/number-input.component';
import { UserAvatarComponent } from './controls/shared/user-avatar/user-avatar.component';
import { DateInputComponent } from './controls/shared/fields/date-input/date-input.component';
import { NavTabComponent } from "./controls/shared/nav-tab/nav-tab.component";
import { CustomTimelineItemComponent } from './controls/custom/custom-timeline/custom-timeline-item/custom-timeline-item.component';
import { WebleadsListComponent } from './controls/dashboards/dashboardcomponents/dashdashboardcomponent-updates/webleads-list/webleads-list.component';
import { AccordionComponent } from './controls/shared/accordion/accordion.component';

import { EntitytableAdditionaltoolbaritemComponent } from './controls/custom/entitytable/entitytable-additionaltoolbaritem/entitytable-additionaltoolbaritem.component';
import { VisitorsListComponent } from './controls/dashboards/dashboardcomponents/dashdashboardcomponent-updates/visitors-list/visitors-list.component';
import { QuicksearchitemComponent } from './controls/static/quicksearch/quicksearchitem/quicksearchitem.component';
import { FlexDashboardComponent } from './controls/dashboards/flex-dashboard/flex-dashboard.component';
import { FilterComponent } from './controls/shared/filter/filter.component';
import { DashboardmaincontainerComponent } from './controls/dashboards/defaultdashboards/dashboardmaincontainer/dashboardmaincontainer.component';
import { DefaultdashboardProjectoverviewAccountComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-projectoverview-account/defaultdashboard-projectoverview-account.component';
import { ColorInputComponent } from './controls/shared/fields/color-input/color-input.component';
import { StackedBarComponent } from './controls/dashboards/charts/stacked-bar/stacked-bar.component';
import { DashboardListComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-projectoverview-account/dashboard-list/dashboard-list.component';
import { DefaultdashboardOpportunityComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-opportunity/defaultdashboard-opportunity.component';
import { DefaultdashboardActivityComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-activity/defaultdashboard-activity.component';
import { DefaultdashboardLeadstatedevelopmentComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-leadstatedevelopment/defaultdashboard-leadstatedevelopment.component';
import { DefaultdashboardQuestionaireComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-questionaire/defaultdashboard-questionaire.component';
import { DefaultdashboardConsentComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-consent/defaultdashboard-consent.component';
import { DefaultdashboardAgentwvlComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-agentwvl/defaultdashboard-agentwvl.component';
import { DashboardListDropdownComponent } from './controls/dashboards/defaultdashboards/defaultdashboardsLists/dashboard-list-dropdown/dashboard-list-dropdown.component';
import { RowMarkerComponent } from './controls/shared/markers/row-marker/row-marker.component';
import { ProgressBarComponent } from './controls/shared/progress-bar/progress-bar.component';
import { CampaigndashboardlistComponent } from './controls/dashboards/campaigndashboard/campaigndashboardlist/campaigndashboardlist.component';
import { CampaigndashboardchartComponent } from './controls/dashboards/campaigndashboard/campaigndashboardchart/campaigndashboardchart.component';
import { AdminNewentitymemberComponent } from './controls/admin/admin-entity/admin-newentitymember/admin-newentitymember.component';
import { UsermanagementuserdetailsComponent } from './controls/static/usermanagement/usermanagementuserdetails/usermanagementuserdetails.component';
import { DropdownComponent } from './controls/shared/dropdown/dropdown.component';
import { CounterInputComponent } from './controls/shared/fields/counter-input/counter-input.component';
import { ActionButtonComponent } from './controls/shared/action-button/action-button.component';
import { LicensingUserlistComponent } from './controls/static/licensing/licensing-userlist/licensing-userlist.component';
import { LicensingAdduserComponent } from './controls/static/licensing/licensing-adduser/licensing-adduser.component';
import { LicensingPackageUpgradeComponent } from './controls/static/licensing/licensing-package-upgrade/licensing-package-upgrade.component';
import { LicensingPackageUpgradeitemComponent } from './controls/static/licensing/licensing-package-upgradeitem/licensing-package-upgradeitem.component';
import { CardAccordionComponent } from './controls/shared/card-accordion/card-accordion.component';
import { LicensingDeactivateUserComponent } from './controls/static/licensing/licensing-deactivate-user/licensing-deactivate-user.component';
import { EntitytableFilterComponent } from './controls/custom/entitytable/entitytable-filter/entitytable-filter.component';
import { CloneroleComponent } from './controls/static/clonerole/clonerole.component';
import { DefaultdashboardMailshotdetailComponent } from './controls/dashboards/defaultdashboards/defaultdashboard-mailshotdetail/defaultdashboard-mailshotdetail.component';
import { EchobotInterfaceComponent } from './controls/static/echobot-interface/echobot-interface.component';
import { QualificationComponent } from './controls/static/qualification/qualification.component';
import { DashdashboardcomponentLastactivityComponent } from './controls/dashboards/dashboardcomponents/dashdashboardcomponent-lastactivity/dashdashboardcomponent-lastactivity.component';
import { DashdashboardcomponentLastactivityItemComponent } from './controls/dashboards/dashboardcomponents/dashdashboardcomponent-lastactivity-item/dashdashboardcomponent-lastactivity-item.component';
import { FilterAccordionComponent } from './controls/shared/filter-accordion/filter-accordion.component';
import { MovetasksComponent } from './controls/static/movetasks/movetasks.component';


@NgModule({
    providers: [
        TextTransformService,
        ObjectDeserializeService,
        ConfirmationService,
        SplitCamelcasePipe,
    ],
    imports: [
    NgChartsModule,
    MaterialModule,
    AngularSvgIconModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    jollyJupiterRoutes,
    CalendarModule,
    TableModule,
    DropdownModule,
    PanelModule,
    CarouselModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    SharedModule,
    DragDropModule,
    MatTableModule,
    MatSlideToggleModule,
    MatInputModule,
    CheckboxModule,
    MatPaginatorModule,
    DialogModule,
    MenuModule,
    TabViewModule,
    NgxEchartsModule.forRoot({ echarts: chartModule }),
    NgxTippyModule,
    BrowserModule,
    QRCodeModule
],
        
    declarations: [
        UsersettingsComponent,
        CustomcontainerComponent,
        CustomUniversalComponent,
        DesignerComponent,
        ProjectManagementComponent,
        ErrormessageComponent,
        CustomDragdropComponent,
        ProjectusersComponent,
        CustomMenuItemComponent,
        CustomDropdownComponent,
        CustomRadioComponent,
        CustomButtonComponent,
        CustomCheckboxComponent,
        CustomTextboxComponent,
        CustomDatepickerComponent,
        CustomInformationpanelComponent,
        CustomPopupComponent,
        JollyjupiterComponent,
        CustomListviewComponent,
        TextTransformService,
        ObjectDeserializeService,
        CustomLabelComponent,
        ProjectselectionComponent,
        DebugpanelComponent,
        WebphoneComponent,
        ActivityComponent,
        LargeMenuItemComponent,
        AccountComponent,
        CustomDialComponent,
        CustomTextareaComponent,
        CustomRegisterComponent,
        CustomWebsiteComponent,
        CustomResearchComponent,
        CustomPowerbuttonComponent,
        CustomCompactlistComponent,
        BrakepopupComponent,
        CustomerManagementComponent,
        CustomSpacerComponent,
        CustomResultpreviewComponent,
        CustomWebphonePopupComponent,
        AutomaticRecordAssignmentComponent,
        CustomHorizontalRuleComponent,
        DatafilterMainpanelComponent,
        DatafilterGroupitemComponent,
        DatafilterGroupitemMemberComponent,
        ExportFormComponent,
        CustomQuestionnaireComponent,
        MyleadsComponent,
        LeadreportComponent,
        CustomSectionheaderComponent,
        CustomMenubarComponent,
        NoTaskAvailableComponent,
        CustomSubresultComponent,
        CustomSubresultItemComponent,
        CreateTaskMainComponent,
        CreateTaskFinalizeComponent,
        ExtendedButtonComponent,
        ExtendedSpinnerComponent,
        ExtendedDropdownComponent,
        OptinoptoutComponent,
        AppointmentViewComponent,
        DatafilterGroupitemMemberDetailsComponent,
        CustomCommunicationGuidelineComponent,
        SupervisorMainComponent,
        ReportingComponent,
        RedirectComponent,
        UsergroupsComponent,
        QuicksearchComponent,
        QuicksearchitemComponent,
        DesignTranslationComponent,
        DeveloperMainpanelComponent,
        LeadstateComponent,
        JjPopupComponent,
        JjPopupItemComponent,
        ProcessComponent,
        AccountPopupComponent,
        TranslateTokenPopupComponent,
        CampaignComponent,
        CustomProjectoverviewComponent,
        AdminMainComponent,
        AdminCustomerComponent,
        QuestionanswersComponent,
        QuestionanswersItemComponent,
        UicontrolDesignComponent,
        AdminEntityComponent,
        UserRightsComponent,
        ControlDesignAdminComponent,
        NonDatabaseProjectDashboardComponent,
        ImportMainComponent,
        ImportUploadfileComponent,
        ImportAssignementComponent,
        ImportHelptableViewComponent,
        NewLogicalEntityComponent,
        AdminJobsComponent,
        AdminReportsComponent,
        NewaccountComponent,
        UicontainerDesignComponent,
        TranslationButtonComponent,
        ControlsDesignMainComponent,
        ControlsDesignLookupTableComponent,
        ImportDetailsComponent,
        UistyleComponent,
        DatadeliveryComponent,
        AdminJobsScheduleComponent,
        AdminQueryBuilderComponent,
        AdminReportParameterComponent,
        UicontrolDesignAttributeGroupsComponent,
        AttributeModifierOverviewComponent,
        UiControlDesignSecurityPermissionComponent,
        LeadreportsettingsComponent,
        AdminFtpComponent,
        NewtaskComponent,
        CustomCopytoclipboardButtonComponent,
        AccountFooterComponent,
        ProspectComponent,
        ConversionComponent,
        TelekomcreatetakeComponent,
        // SoftphoneComponent,
        CustomThermometerComponent,
        ProspectDetailComponent,
        CustomMattableComponent,
        AccountsComponent,
        ContactsComponent,
        UicontrolDesignMethodsComponent,
        MocoAssignmentComponent,
        SingleactionComponent,
        FollowupsComponent,
        ConversionListComponent,
        CustomLeadscoreComponent,
        MyFollowupsComponent,
        AdminExternalDatasourcesComponent,
        OpportunityComponent,
        DashboardmainComponent,
        LeadscoringDetailComponent,
        AuditComponent,
        DocumentationInfoComponent,
        UicontrolDesignAttributemodifierComponent,
        CognitocallbackComponent,
        IconLeadscoreComponent,
        CustomOptinoptoutComponent,
        ContainerViewComponent,
        CustomDiagramComponent,
        LeadScoringchartComponent,
        BoldbiDashboardComponent,
        SplitCamelcasePipe,
        HeaderInformationComponent,
        RingcentralPopupComponent,
        NewactivityComponent,
        CustomActivityListComponent,
        CustomTimelineComponent,
        CustomTimelineItemComponent,
        CustomPaletteComponent,
        LeadscoringDetailPotentialComponent,
        CustomMicroCampaignsComponent,
        BoldbiDashboardlistComponent,
        RingcentralRedirectComponent,
        GeneralsettingsComponent,
        BlacklistComponent,
        EmailtemplatesComponent,
        EmailcontentComponent,
        MailacceptComponent,
        AssignToListComponent,
        CustomEmaileditorComponent,
        MocoprojectassignmentComponent,
        ExternalusersComponent,
        BookingComponent,
        BannerComponent,
        VideoplayerComponent,
        CompactTasklistComponent,
        EventtriggerComponent,
        SendmailitemsComponent,
        TrialextensionComponent,
        CustomEmailComponent,
        CustomContactheaderComponent,
        AdminEntityValidityComponent,
        WizardComponent,
        WizardCampaignComponent,
        WizardLeadreportsettingsComponent,
        ConversioneditorComponent,
        CustomEventinfoComponent,
        WizardLeadstateComponent,
        MatTableSupervisorComponent,
        WizardTranslationsComponent, UsermanagementComponent,
        WizardWorkflowComponent, BannerBorderitemComponent, ConversiondetailComponent,
        NewcontactComponent, EmailreviewComponent, VipComponent,
        CustomMultilistComponent, DocumentationLinkComponent, UicontrolValueitemComponent,
        UicontrolSubcontrolComponent, CustomMynextfollowupComponent, IncomingcallComponent,
        BookingPreviewComponent, WizardStepresultComponent, WizardStepComponent,
        DatarightsComponent, DatarightsItemComponent, PoolsComponent,
        WizardDatarightsgroupComponent, WizardInfobuttonComponent, ControlDesignExternaldatasourceComponent,
        WizardTranslationPopupComponent, DatarightsInfoComponent, LogicalEntitiesComponent,
        WizardEmailcontentComponent, WizardEmailtemplateComponent, WizardEntitymemberComponent,
        WizardDataimportComponent, WizardMocoComponent, AjtrackerComponent, EventlistComponent,
        AdminScoringparameterComponent, AdminScoringcontenttypeComponent, AdminUicontrolmethodparameterinformationComponent,
        WizardEventassignmentComponent, SesComponent, WizardDataimportDatalistComponent, AdminFilterComponent,
        AdminManagerightsComponent, EmailbatchdetailsComponent, CustomerapiComponent, SmtpsettingsComponent,
        EmaileditorSyncComponent, WizardEmailsettingsComponent, WizardMailshotComponent, SettingRequiredComponent,
        MailshotsComponent, AdminAwsidentitiesComponent, HtmleditorComponent, UsermailboxComponent, WizardMailtocontactComponent,
        WizardEventtriggerComponent, WizardMailshotFinalComponent, ConsentManagementComponent, WizardEmailSmtpcontentComponent,
        EmaildistributorComponent, WizardBlacklistimportComponent, ProjectstartpageComponent, BreadcrumbandmenuComponent,
        ContentinfoComponent, CustomAccordeonComponent, UserdashboardComponent, AdminDashboardsComponent,
        ProcessvisualComponent, ProcessvisualitemComponent, EntitytableComponent, EntitytableDesignerComponent, EntitytableAdditionaltoolbaritemComponent,
        EntitytableValuecontrolComponent, CustomDashoardcontrolComponent, CustomDasboardcontrolDashboardComponent,
        CustomDashoardcontrolMicrocampaignsComponent, CustomDashboardcontrolUserviplistComponent,
        CustomDashboardcontrolBestnextcontactComponent, AdminAipromptsComponent, CustomDashboardcontrolOptinoptoutComponent,
        CustomAipromptresultComponent, RingcentralWebrtcRedirectComponent, AccountGroupComponent, CallednumberhistoryComponent,
        VoicescriptcontrolComponent, WizardVoicetemplatesComponent, CustomAccountownerComponent, NavigationdesignComponent,
        LeadmanagementdashboardComponent, WebsitedashboardComponent, ConsentmanagementdashboardComponent,
        ConsentmanagementdashboardItemComponent, PieGraphComponent, GeneralsettingsprojecttargetComponent,
        ContentlibraryComponent, ContentlibraryItemComponent, VipactivityComponent, AileadscoringComponent, ToolbarComponent,
        StartpagedashboardComponent, DashbdashboardcomponentMydayComponent, DashbdashboardcomponentKpisComponent,
        DashbdashboardcomponentMytopcontactsComponent, DashbdashboardcomponentMyvisitorsComponent,
        DashbdashboardcomponentMytoppageviewersComponent, DashboardcomponentMyconversionsComponent, QualitymanagementComponent,
        QmQuestionanswersComponent, ExternalfiledragcontrolComponent, MydayappointmentComponent, ToolbarPopupComponent,
        ToolbarPhoneComponent, PieChartComponent, BookingImageComponent, CustomMicrocampaignCancelComponent, BarChartComponent,
        FlowchartComponent, EchartsComponent, CreatedemoaccountComponent, DashboardcomponentLeadstatesComponent,
        CustomCalculateleadstateComponent, CloudconnectionsComponent, CacheresetComponent, /* TooltipComponent, */ TooltipDirective,
        CloudconnectionLoginComponent, MicrocampaignbatchstarterComponent, EventbookingbatchComponent, WizardMicrocampaignComponent,
        OptinoptouteditorComponent, QuestionaireControlComponent, QuestionaireDesignerControlComponent,
        QuestionaireDesignerQuestioncontrolComponent, QuestionaireUserquestionComponent, LookuptablesComponent, CustomOptinoverviewComponent,
        ScrollableDirective, ProcessvisualPopupComponent, ExpandflowchartComponent, TippyTooltipComponent, TippyDirective, ContolsDesignSettingsComponent, AdminJobCheckResultComponent, NewversionavailableComponent, CustomPaletteEditorComponent, OnlynumberDirective, CallprojectbookingComponent, AwsloginComponent, CustomMultiSelectComponent, CustomTableFilterComponent, ControleditLightComponent, CustomContactleadstateoverviewComponent, CustomContactleadstateoverviewDetailsComponent, CustomEntityfileuploadComponent,
        IconFileComponent, CardAccordionComponent,
        LeadScoringlinechartComponent, FollowUpTaskChartComponent, FollowuplistComponent,
        FeaturesComponent, FeaturessettingsComponent, FeaturessettingsSliderComponent, FeaturespackagessettingsComponent, ProjectpackagesComponent, ContactProtectionComponent, ContactProtectionPopupComponent,CustomAccountOverviewComponent, CardComponent, RatingbarComponent, JjIconsComponent, BlueMarkerComponent, ComponentHeaderComponent, ToolbarNavComponent, TextInputComponent, SelectInputComponent, CheckboxComponent, RadioButtonComponent, MainButtonComponent, TabComponent, StatusComponent, LayoutComponent, BarLeadscoreComponent,
        MytopcontactComponent, DashdashboardcomponentUpdatesComponent, TextAreaInputComponent, NumberInputComponent,UserAvatarComponent, DateInputComponent, NavTabComponent, WebleadsListComponent, AccordionComponent, VisitorsListComponent, FlexDashboardComponent, FilterComponent, DashboardmaincontainerComponent, DefaultdashboardProjectoverviewAccountComponent, ColorInputComponent, StackedBarComponent,DashboardListComponent, DefaultdashboardOpportunityComponent, DefaultdashboardActivityComponent, DefaultdashboardLeadstatedevelopmentComponent, DefaultdashboardQuestionaireComponent, DefaultdashboardConsentComponent, DefaultdashboardAgentwvlComponent,
        DashboardListDropdownComponent , RowMarkerComponent, ProgressBarComponent, CampaigndashboardComponent,CampaigndashboardlistComponent, CampaigndashboardchartComponent, AdminNewentitymemberComponent, UsermanagementuserdetailsComponent, DropdownComponent, CounterInputComponent, ActionButtonComponent, LicensingUserlistComponent, LicensingAdduserComponent, LicensingPackageUpgradeComponent, LicensingPackageUpgradeitemComponent, LicensingDeactivateUserComponent, EntitytableFilterComponent, CloneroleComponent, DefaultdashboardMailshotdetailComponent, EchobotInterfaceComponent, QualificationComponent, DashdashboardcomponentLastactivityComponent, DashdashboardcomponentLastactivityItemComponent,
        FilterAccordionComponent,
        MovetasksComponent
    ],
    exports: [
        ContentinfoComponent,
        NewversionavailableComponent,
        CustomcontainerComponent,
        CustomUniversalComponent,
        IncomingcallComponent,
        CustomMynextfollowupComponent,
        ErrormessageComponent,
        CustomDragdropComponent,
        ProjectusersComponent,
        CustomMenuItemComponent,
        CustomDropdownComponent,
        CustomRadioComponent,
        CustomCheckboxComponent,
        CustomPopupComponent,
        CustomWebphonePopupComponent,
        TextTransformService,
        ObjectDeserializeService,
        ProjectselectionComponent,
        // ToastModule,
        DebugpanelComponent,
        WebphoneComponent,
        ActivityComponent,
        MenuModule,
        LargeMenuItemComponent,
        //MatMenuModule,
        MaterialModule,
        JjPopupComponent,
        HeaderInformationComponent,
        ToolbarComponent,
        ToolbarPopupComponent,
        CustomPaletteComponent,
        BannerComponent,
        VideoplayerComponent,
        CompactTasklistComponent,
        WizardComponent,
        WizardTranslationsComponent,
        SettingRequiredComponent,
        BreadcrumbandmenuComponent,
        AwsloginComponent,
        TippyDirective,
        MainButtonComponent,
        JjIconsComponent,
        UserAvatarComponent,
        ContentinfoComponent
    ]
})
export class JollyJupiterModule { }
