import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-dashboardmaincontainer',
  templateUrl: './dashboardmaincontainer.component.html',
  styleUrl: './dashboardmaincontainer.component.scss'
})
export class DashboardmaincontainerComponent implements OnInit {
  reports = null;
  selectedReport = null;
  dashboards = [];
  
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService
  ) {}

  ngOnInit(): void {
    this.getReports();
    this.dashboards = JSON.parse(this.applicationInfoService.applicationSettings['defaultDashboards'])
  }
  
  getReports() {
    this.externaldatasourceService.executeExternalDataSource(956).then(getReportsResult => {
      this.reports = getReportsResult;      
    });
  }

  getDashboardDefaultName() {
    const dashboard = this.dashboards.find(dashboard => dashboard.id == this.selectedReport);
    if (dashboard) {
      return dashboard.defaultName;
    } else {
      return '';
    }
  }
}
